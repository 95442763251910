import { FormControl, HStack, VStack } from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';
import { SpecFormLabel } from './SpecFormLabel';
import { SpecInput } from './SpecInput';

export const CostSpecForm = ({
  index,
}: {
  index: number;
}) => {
  const form = useFormContext<EstimationFormType>();
  const { errors } = form.formState;

  return (
    <VStack>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>補足</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cost.note`)} />
            <ErrorMessage name={`details.${index}.spec.cost.note`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
    </VStack>
  );
};
