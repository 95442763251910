import { Box, HStack, IconButton, Image } from '@chakra-ui/react';
import { Delete } from '@icon-park/react';
import { DemandItemFormImage } from 'features/demandItem/form';

export type DemandItemImagePreviewProps = {
  images: DemandItemFormImage[];
  isMutationInFlight: boolean;
  onDelete: (index: number) => void;
  onClick: (objectName: string) => void;
};

export const DemandItemImagePreview = ({
  images,
  isMutationInFlight,
  onDelete,
  onClick,
}: DemandItemImagePreviewProps) => {
  if (images.length === 0) {
    return <></>;
  }

  return (
    <HStack flexWrap="wrap" gap={2} mb={2} maxWidth="572px">
      {images
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((image, index) => (
          <Box
            key={image.objectName}
            w="108px"
            h="108px"
            position="relative"
            bgColor="gray.50"
            onClick={() => onClick(image.objectName)}
          >
            <IconButton
              aria-label="削除"
              icon={<Delete />}
              rounded="full"
              size="xs"
              position="absolute"
              top={1}
              right={1}
              bgColor="gray.100"
              onClick={() => onDelete(index)}
              isDisabled={isMutationInFlight}
            />
            <Image
              src={image.url}
              alt="商品写真"
              objectFit="contain"
              w="100%"
              h="100%"
              rounded="md"
            />
          </Box>
        ))}
    </HStack>
  );
};
