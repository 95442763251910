import { Box, FormControl, FormLabel, HStack, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Info } from '@icon-park/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { Loading } from 'components/Loading';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { SalesOrdersReorderForm } from 'features/salesOrdersV2/components/SalesOrdersReorderForm';
import {
  SalesOrdersReorderFormValuesType,
  getSalesOrdersReorderInitialValues,
  salesOrdersReorderFormSchema,
} from 'features/salesOrdersV2/form/reorder';
import { useQueryParams } from 'features/salesOrdersV2/hooks/useSalesOrdersQueryPrams';

import { Suspense } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { paths } from 'utils/paths';

export const SalesOrdersReorderPage = () => {
  const { queryParams } = useQueryParams();

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit,
  } = useForm<SalesOrdersReorderFormValuesType>({
    resolver: zodResolver(salesOrdersReorderFormSchema),
    defaultValues: getSalesOrdersReorderInitialValues(),
  });

  const demandId = watch('demand').id;

  const onSubmit = (data: SalesOrdersReorderFormValuesType) => {
    console.log(data);
  };

  const onClearDemandInput = () => {
    setValue('demand', { id: '', name: '' });
  };

  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <Link
          to={paths.salesOrders.url({
            assignee: queryParams.assignee,
            demand: queryParams.demand,
            transactionId: queryParams.transactionId,
            supplier: queryParams.supplier,
          })}
        >
          <PageBack />
        </Link>
      </Box>

      <Box mt={6}>
        <SubHeading label="発注請書の作成：リピート発注" />
      </Box>

      <Box w="768px" mt={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6} alignItems="flex-start">
            <FormControl isInvalid={!!errors.demand} isRequired>
              <VStack gap={2} align="stretch">
                <Box>
                  <FormLabel>デマンド</FormLabel>
                  <Controller
                    name="demand"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <CompanyComboBox
                        onChangeSelected={(value) => {
                          onChange(value);
                        }}
                        defaultSelectedItem={getValues('demand')}
                        onClearInput={onClearDemandInput}
                      />
                    )}
                  />
                  <ErrorMessage name="company" errors={errors} />
                </Box>
                <DemandMemo demandId={demandId} />
              </VStack>
            </FormControl>

            {!demandId ? (
              <Stack>
                <HStack
                  width="fit-content"
                  px={4}
                  py={3}
                  borderWidth={2}
                  borderColor="gray.500"
                  borderRadius="0.375rem"
                  alignItems="center"
                  gap={1}
                >
                  <Icon as={Info} w={5} h={5} size={20} />
                  <Text fontSize="sm" fontWeight="bold">
                    デマンドの選択後に明細を入力できます
                  </Text>
                </HStack>
              </Stack>
            ) : (
              <SalesOrdersReorderForm
                control={control}
                register={register}
                getValues={getValues}
                errors={errors}
              />
            )}
          </VStack>
        </form>
      </Box>
    </Suspense>
  );
};
