import {
  OrderDetailInput,
  useOrderFormMutation,
} from 'gql/__generated__/useOrderFormMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { ORDER_SUBMIT_TYPE, OrderSubmitType, RECIPIENT_TYPE } from '../type';
import { defaultMailBodyMessage } from '../util';
import { OrderSendingFormData, ZodOrderFormData } from '../zod';
import { useDownloadOrderPdf } from './useDownloadOrderPdf';

export const useOrderForm = ({
  state,
  salesOrderId,
  currentUser,
}: {
  state: ZodOrderFormData;
  salesOrderId: string;
  currentUser: { readonly lastName: string };
}) => {
  const [_, setSearchParams] = useSearchParams();
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);
  const { downloadOrderPdf } = useDownloadOrderPdf();

  const navigate = useNavigate();
  const [showPreview, setPreview] = useState(false);
  const [submitType, setSubmitType] = useState<OrderSubmitType | null>(null);
  const [data, setData] = useState(state);
  const [sendingData, setSendingData] = useState<OrderSendingFormData>({
    mailBodyMessage: defaultMailBodyMessage({ lastName: currentUser.lastName }),
    supplierContactsTo: [],
    supplierContactsCc: [],
  });
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useOrderFormMutation>(
    graphql`
      mutation useOrderFormMutation(
        $input: OrderInput!
      ) {
        createOrder(input: $input) {
          id
        }
      }
    `,
  );

  const onClickToConfirm = (data: ZodOrderFormData) => {
    setData({ ...data });
    setPreview(true);

    const params = {} as { [key: string]: string };
    if (salesOrderId) params.salesOrderId = salesOrderId;
    if (submitType === ORDER_SUBMIT_TYPE.sending) params.type = ORDER_SUBMIT_TYPE.sending;
    if (submitType === ORDER_SUBMIT_TYPE.download) params.type = ORDER_SUBMIT_TYPE.download;
    setSearchParams(params);
  };

  const onClickToNewForm = (sendingFormData?: OrderSendingFormData) => {
    if (sendingFormData) setSendingData(sendingFormData);
    setSubmitType(null);
    setPreview(false);
  };

  const updateSubmitType = (type: OrderSubmitType) => {
    setSubmitType(type);
  };

  const onSubmit = (sendingFormData?: OrderSendingFormData) => {
    const to = (sendingFormData?.supplierContactsTo || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.to,
      };
    });
    const cc = (sendingFormData?.supplierContactsCc || []).map((contactId) => {
      return {
        ID: contactId,
        recipientType: RECIPIENT_TYPE.cc,
      };
    });

    commitMutation({
      variables: {
        input: {
          title: data.title,
          detailMessage: data.detailMessage,
          deliveryInfo: data.deliveryInfo,
          mailBodyMessage: sendingFormData?.mailBodyMessage || '',
          companyID: data.company.id,
          supplierID: data.supplier.id,
          supplierContacts: [...to, ...cc],
          salesOrderID: salesOrderId,
          items: data.details.map(
            (detail, i) =>
              ({
                name: detail.name,
                unitPrice: detail.unitPrice,
                quantity: detail.quantity,
                taxCategoryID: detail.tax.id,
                salesOrderDetailID: detail.salesOrderDetailId || null,
                orderNumber: i + 1,
              }) as OrderDetailInput,
          ),
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
        },
      },
      onCompleted: (res) => {
        if (submitType === ORDER_SUBMIT_TYPE.download) {
          downloadOrderPdf(res?.createOrder?.id);
        }

        toast({
          title: '発注書を作成しました',
          status: 'success',
        });
        navigate(paths.order.url());
      },
    });
  };

  return {
    data,
    showPreview,
    onClickToConfirm,
    onClickToNewForm,
    onSubmit,
    updateSubmitType,
    isMutationInFlight,
    sendingData,
  };
};
