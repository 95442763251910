import { Box, HStack, VStack } from '@chakra-ui/react';
import { SalesOrdersReorderFormValuesType } from 'features/salesOrdersV2/form/reorder';
import { Control, useWatch } from 'react-hook-form';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalGrossProfitAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const SalesOrdersReorderFormPrices = ({
  control,
}: {
  control: Control<SalesOrdersReorderFormValuesType>;
}) => {
  const details = useWatch({ name: 'details', control });

  const detailsForCalculation = details.map((detail) => ({
    quantity: detail.quantity || 0,
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  return (
    <Box w="240px">
      <VStack alignItems="flex-start" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontSize="sm">小計</Box>
          <Box fontSize="sm">{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</Box>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Box
            color="gray.600"
            fontSize="xs"
            borderColor="gray.200"
            borderStyle="solid"
            borderLeftWidth="2px"
            pl={2}
          >
            粗利
          </Box>
          <Box color="gray.600" fontSize="xs">
            {calculateTotalGrossProfitAmount(detailsForCalculation).toLocaleString()}
            (&#037;) {/* &#037;は % */}
          </Box>
        </HStack>
      </VStack>

      <VStack mt={2} alignItems="flex-start" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontSize="sm">消費税</Box>
          <Box fontSize="sm">{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</Box>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Box
            w="100%"
            color="gray.600"
            borderColor="gray.200"
            borderStyle="solid"
            borderLeftWidth="2px"
            pl={2}
          >
            <VStack alignItems="flex-start" spacing={2}>
              <HStack w="100%" justifyContent="space-between">
                <Box fontSize="xs">10%対象(0)</Box>
                <Box fontSize="xs">0</Box>
              </HStack>
              <HStack w="100%" justifyContent="space-between">
                <Box fontSize="xs">軽減8%対象(0)</Box>
                <Box fontSize="xs">0</Box>
              </HStack>
              <HStack w="100%" justifyContent="space-between">
                <Box fontSize="xs">対象外(0)</Box>
                <Box fontSize="xs">0</Box>
              </HStack>
            </VStack>
          </Box>
        </HStack>
      </VStack>

      <VStack mt={2} justify="space-between" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontWeight="bold">合計金額</Box>
          <Box fontWeight="bold">
            {calculateTotalAmount(detailsForCalculation).toLocaleString()}
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
