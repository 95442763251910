/**
 * @generated SignedSource<<bc1e37ab06d123fdbcb8c70ce5326842>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationDetailType = "cost" | "item" | "text" | "%future added value";
export type EstimationRequestDetailType = "item" | "%future added value";
export type EstimationResponseDetailType = "item" | "%future added value";
export type InvoiceDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
export type OrderAssigneeRecipientType = "cc" | "to" | "%future added value";
export type OrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type SalesOrderOrderField = "CREATED_AT" | "ID" | "UPDATED_AT" | "%future added value";
export type SalesOrderWhereInput = {
  and?: ReadonlyArray<SalesOrderWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandID?: string | null;
  demandIDContains?: string | null;
  demandIDContainsFold?: string | null;
  demandIDEqualFold?: string | null;
  demandIDGT?: string | null;
  demandIDGTE?: string | null;
  demandIDHasPrefix?: string | null;
  demandIDHasSuffix?: string | null;
  demandIDIn?: ReadonlyArray<string> | null;
  demandIDLT?: string | null;
  demandIDLTE?: string | null;
  demandIDNEQ?: string | null;
  demandIDNotIn?: ReadonlyArray<string> | null;
  detailMessage?: string | null;
  detailMessageContains?: string | null;
  detailMessageContainsFold?: string | null;
  detailMessageEqualFold?: string | null;
  detailMessageGT?: string | null;
  detailMessageGTE?: string | null;
  detailMessageHasPrefix?: string | null;
  detailMessageHasSuffix?: string | null;
  detailMessageIn?: ReadonlyArray<string> | null;
  detailMessageIsNil?: boolean | null;
  detailMessageLT?: string | null;
  detailMessageLTE?: string | null;
  detailMessageNEQ?: string | null;
  detailMessageNotIn?: ReadonlyArray<string> | null;
  detailMessageNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDemand?: boolean | null;
  hasDemandWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasEstimations?: boolean | null;
  hasEstimationsWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasInternalAssignees?: boolean | null;
  hasInternalAssigneesWith?: ReadonlyArray<SalesOrderInternalAssigneeWhereInput> | null;
  hasInternalMemos?: boolean | null;
  hasInternalMemosWith?: ReadonlyArray<SalesOrderInternalMemoWhereInput> | null;
  hasInvoices?: boolean | null;
  hasInvoicesWith?: ReadonlyArray<InvoiceWhereInput> | null;
  hasOrders?: boolean | null;
  hasOrdersWith?: ReadonlyArray<OrderWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderWhereInput | null;
  or?: ReadonlyArray<SalesOrderWhereInput> | null;
  receivedDate?: any | null;
  receivedDateGT?: any | null;
  receivedDateGTE?: any | null;
  receivedDateIn?: ReadonlyArray<any> | null;
  receivedDateLT?: any | null;
  receivedDateLTE?: any | null;
  receivedDateNEQ?: any | null;
  receivedDateNotIn?: ReadonlyArray<any> | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  title?: string | null;
  titleContains?: string | null;
  titleContainsFold?: string | null;
  titleEqualFold?: string | null;
  titleGT?: string | null;
  titleGTE?: string | null;
  titleHasPrefix?: string | null;
  titleHasSuffix?: string | null;
  titleIn?: ReadonlyArray<string> | null;
  titleLT?: string | null;
  titleLTE?: string | null;
  titleNEQ?: string | null;
  titleNotIn?: ReadonlyArray<string> | null;
  transactionID?: any | null;
  transactionIDGT?: any | null;
  transactionIDGTE?: any | null;
  transactionIDIn?: ReadonlyArray<any> | null;
  transactionIDLT?: any | null;
  transactionIDLTE?: any | null;
  transactionIDNEQ?: any | null;
  transactionIDNotIn?: ReadonlyArray<any> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type UserWhereInput = {
  and?: ReadonlyArray<UserWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  fbAuthUUID?: string | null;
  fbAuthUUIDContains?: string | null;
  fbAuthUUIDContainsFold?: string | null;
  fbAuthUUIDEqualFold?: string | null;
  fbAuthUUIDGT?: string | null;
  fbAuthUUIDGTE?: string | null;
  fbAuthUUIDHasPrefix?: string | null;
  fbAuthUUIDHasSuffix?: string | null;
  fbAuthUUIDIn?: ReadonlyArray<string> | null;
  fbAuthUUIDIsNil?: boolean | null;
  fbAuthUUIDLT?: string | null;
  fbAuthUUIDLTE?: string | null;
  fbAuthUUIDNEQ?: string | null;
  fbAuthUUIDNotIn?: ReadonlyArray<string> | null;
  fbAuthUUIDNotNil?: boolean | null;
  hasProfile?: boolean | null;
  hasProfileWith?: ReadonlyArray<UserProfileWhereInput> | null;
  hasRoles?: boolean | null;
  hasRolesWith?: ReadonlyArray<RoleWhereInput> | null;
  hasUserRoles?: boolean | null;
  hasUserRolesWith?: ReadonlyArray<UserRoleWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: UserWhereInput | null;
  or?: ReadonlyArray<UserWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type UserProfileWhereInput = {
  and?: ReadonlyArray<UserProfileWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  not?: UserProfileWhereInput | null;
  or?: ReadonlyArray<UserProfileWhereInput> | null;
  phoneNumber?: string | null;
  phoneNumberContains?: string | null;
  phoneNumberContainsFold?: string | null;
  phoneNumberEqualFold?: string | null;
  phoneNumberGT?: string | null;
  phoneNumberGTE?: string | null;
  phoneNumberHasPrefix?: string | null;
  phoneNumberHasSuffix?: string | null;
  phoneNumberIn?: ReadonlyArray<string> | null;
  phoneNumberIsNil?: boolean | null;
  phoneNumberLT?: string | null;
  phoneNumberLTE?: string | null;
  phoneNumberNEQ?: string | null;
  phoneNumberNotIn?: ReadonlyArray<string> | null;
  phoneNumberNotNil?: boolean | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type RoleWhereInput = {
  and?: ReadonlyArray<RoleWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  description?: string | null;
  descriptionContains?: string | null;
  descriptionContainsFold?: string | null;
  descriptionEqualFold?: string | null;
  descriptionGT?: string | null;
  descriptionGTE?: string | null;
  descriptionHasPrefix?: string | null;
  descriptionHasSuffix?: string | null;
  descriptionIn?: ReadonlyArray<string> | null;
  descriptionIsNil?: boolean | null;
  descriptionLT?: string | null;
  descriptionLTE?: string | null;
  descriptionNEQ?: string | null;
  descriptionNotIn?: ReadonlyArray<string> | null;
  descriptionNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasPermissions?: boolean | null;
  hasPermissionsWith?: ReadonlyArray<PermissionWhereInput> | null;
  hasRolePermissions?: boolean | null;
  hasRolePermissionsWith?: ReadonlyArray<RolePermissionWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasUserRoles?: boolean | null;
  hasUserRolesWith?: ReadonlyArray<UserRoleWhereInput> | null;
  hasUsers?: boolean | null;
  hasUsersWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: RoleWhereInput | null;
  or?: ReadonlyArray<RoleWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type PermissionWhereInput = {
  and?: ReadonlyArray<PermissionWhereInput> | null;
  hasRolePermissions?: boolean | null;
  hasRolePermissionsWith?: ReadonlyArray<RolePermissionWhereInput> | null;
  hasRoles?: boolean | null;
  hasRolesWith?: ReadonlyArray<RoleWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  key?: string | null;
  keyContains?: string | null;
  keyContainsFold?: string | null;
  keyEqualFold?: string | null;
  keyGT?: string | null;
  keyGTE?: string | null;
  keyHasPrefix?: string | null;
  keyHasSuffix?: string | null;
  keyIn?: ReadonlyArray<string> | null;
  keyLT?: string | null;
  keyLTE?: string | null;
  keyNEQ?: string | null;
  keyNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: PermissionWhereInput | null;
  or?: ReadonlyArray<PermissionWhereInput> | null;
};
export type RolePermissionWhereInput = {
  and?: ReadonlyArray<RolePermissionWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: RolePermissionWhereInput | null;
  or?: ReadonlyArray<RolePermissionWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type UserRoleWhereInput = {
  and?: ReadonlyArray<UserRoleWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: UserRoleWhereInput | null;
  or?: ReadonlyArray<UserRoleWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type CompanyWhereInput = {
  and?: ReadonlyArray<CompanyWhereInput> | null;
  code?: string | null;
  codeContains?: string | null;
  codeContainsFold?: string | null;
  codeEqualFold?: string | null;
  codeGT?: string | null;
  codeGTE?: string | null;
  codeHasPrefix?: string | null;
  codeHasSuffix?: string | null;
  codeIn?: ReadonlyArray<string> | null;
  codeLT?: string | null;
  codeLTE?: string | null;
  codeNEQ?: string | null;
  codeNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasDemandItems?: boolean | null;
  hasDemandItemsWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasDemandRecurringCosts?: boolean | null;
  hasDemandRecurringCostsWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasEstimationRequests?: boolean | null;
  hasEstimationRequestsWith?: ReadonlyArray<EstimationRequestWhereInput> | null;
  hasEstimations?: boolean | null;
  hasEstimationsWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasInternalMemos?: boolean | null;
  hasInternalMemosWith?: ReadonlyArray<DemandInternalMemoWhereInput> | null;
  hasInvoices?: boolean | null;
  hasInvoicesWith?: ReadonlyArray<InvoiceWhereInput> | null;
  hasOrders?: boolean | null;
  hasOrdersWith?: ReadonlyArray<OrderWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: CompanyWhereInput | null;
  or?: ReadonlyArray<CompanyWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemWhereInput = {
  and?: ReadonlyArray<DemandItemWhereInput> | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandID?: string | null;
  demandIDContains?: string | null;
  demandIDContainsFold?: string | null;
  demandIDEqualFold?: string | null;
  demandIDGT?: string | null;
  demandIDGTE?: string | null;
  demandIDHasPrefix?: string | null;
  demandIDHasSuffix?: string | null;
  demandIDIn?: ReadonlyArray<string> | null;
  demandIDLT?: string | null;
  demandIDLTE?: string | null;
  demandIDNEQ?: string | null;
  demandIDNotIn?: ReadonlyArray<string> | null;
  endedAt?: any | null;
  endedAtGT?: any | null;
  endedAtGTE?: any | null;
  endedAtIn?: ReadonlyArray<any> | null;
  endedAtIsNil?: boolean | null;
  endedAtLT?: any | null;
  endedAtLTE?: any | null;
  endedAtNEQ?: any | null;
  endedAtNotIn?: ReadonlyArray<any> | null;
  endedAtNotNil?: boolean | null;
  hasAttachments?: boolean | null;
  hasAttachmentsWith?: ReadonlyArray<DemandItemAttachmentWhereInput> | null;
  hasCardboard?: boolean | null;
  hasCardboardWith?: ReadonlyArray<DemandItemCardboardWhereInput> | null;
  hasChildren?: boolean | null;
  hasChildrenWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDemand?: boolean | null;
  hasDemandWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasEstimationItems?: boolean | null;
  hasEstimationItemsWith?: ReadonlyArray<EstimationItemWhereInput> | null;
  hasFlexiblePackage?: boolean | null;
  hasFlexiblePackageWith?: ReadonlyArray<DemandItemFlexiblePackageWhereInput> | null;
  hasGiftBox?: boolean | null;
  hasGiftBoxWith?: ReadonlyArray<DemandItemGiftBoxWhereInput> | null;
  hasImages?: boolean | null;
  hasImagesWith?: ReadonlyArray<DemandItemImageWhereInput> | null;
  hasInternalMemos?: boolean | null;
  hasInternalMemosWith?: ReadonlyArray<DemandItemInternalMemoWhereInput> | null;
  hasInvoiceItems?: boolean | null;
  hasInvoiceItemsWith?: ReadonlyArray<InvoiceItemWhereInput> | null;
  hasOrderItems?: boolean | null;
  hasOrderItemsWith?: ReadonlyArray<OrderItemWhereInput> | null;
  hasOriginal?: boolean | null;
  hasOriginalWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasOther?: boolean | null;
  hasOtherWith?: ReadonlyArray<DemandItemOtherWhereInput> | null;
  hasPaperBag?: boolean | null;
  hasPaperBagWith?: ReadonlyArray<DemandItemPaperBagWhereInput> | null;
  hasPrices?: boolean | null;
  hasPricesWith?: ReadonlyArray<DemandItemPriceWhereInput> | null;
  hasSalesOrderItems?: boolean | null;
  hasSalesOrderItemsWith?: ReadonlyArray<SalesOrderItemWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  isCurrent?: boolean | null;
  isCurrentNEQ?: boolean | null;
  itemNumber?: any | null;
  itemNumberGT?: any | null;
  itemNumberGTE?: any | null;
  itemNumberIn?: ReadonlyArray<any> | null;
  itemNumberLT?: any | null;
  itemNumberLTE?: any | null;
  itemNumberNEQ?: any | null;
  itemNumberNotIn?: ReadonlyArray<any> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemWhereInput | null;
  or?: ReadonlyArray<DemandItemWhereInput> | null;
  startedAt?: any | null;
  startedAtGT?: any | null;
  startedAtGTE?: any | null;
  startedAtIn?: ReadonlyArray<any> | null;
  startedAtIsNil?: boolean | null;
  startedAtLT?: any | null;
  startedAtLTE?: any | null;
  startedAtNEQ?: any | null;
  startedAtNotIn?: ReadonlyArray<any> | null;
  startedAtNotNil?: boolean | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
  version?: any | null;
  versionGT?: any | null;
  versionGTE?: any | null;
  versionIn?: ReadonlyArray<any> | null;
  versionLT?: any | null;
  versionLTE?: any | null;
  versionNEQ?: any | null;
  versionNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemAttachmentWhereInput = {
  and?: ReadonlyArray<DemandItemAttachmentWhereInput> | null;
  byteSize?: any | null;
  byteSizeGT?: any | null;
  byteSizeGTE?: any | null;
  byteSizeIn?: ReadonlyArray<any> | null;
  byteSizeLT?: any | null;
  byteSizeLTE?: any | null;
  byteSizeNEQ?: any | null;
  byteSizeNotIn?: ReadonlyArray<any> | null;
  contentType?: string | null;
  contentTypeContains?: string | null;
  contentTypeContainsFold?: string | null;
  contentTypeEqualFold?: string | null;
  contentTypeGT?: string | null;
  contentTypeGTE?: string | null;
  contentTypeHasPrefix?: string | null;
  contentTypeHasSuffix?: string | null;
  contentTypeIn?: ReadonlyArray<string> | null;
  contentTypeLT?: string | null;
  contentTypeLTE?: string | null;
  contentTypeNEQ?: string | null;
  contentTypeNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  fileName?: string | null;
  fileNameContains?: string | null;
  fileNameContainsFold?: string | null;
  fileNameEqualFold?: string | null;
  fileNameGT?: string | null;
  fileNameGTE?: string | null;
  fileNameHasPrefix?: string | null;
  fileNameHasSuffix?: string | null;
  fileNameIn?: ReadonlyArray<string> | null;
  fileNameLT?: string | null;
  fileNameLTE?: string | null;
  fileNameNEQ?: string | null;
  fileNameNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemAttachmentWhereInput | null;
  objectName?: string | null;
  objectNameContains?: string | null;
  objectNameContainsFold?: string | null;
  objectNameEqualFold?: string | null;
  objectNameGT?: string | null;
  objectNameGTE?: string | null;
  objectNameHasPrefix?: string | null;
  objectNameHasSuffix?: string | null;
  objectNameIn?: ReadonlyArray<string> | null;
  objectNameLT?: string | null;
  objectNameLTE?: string | null;
  objectNameNEQ?: string | null;
  objectNameNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<DemandItemAttachmentWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemCardboardWhereInput = {
  and?: ReadonlyArray<DemandItemCardboardWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  material?: string | null;
  materialContains?: string | null;
  materialContainsFold?: string | null;
  materialEqualFold?: string | null;
  materialGT?: string | null;
  materialGTE?: string | null;
  materialHasPrefix?: string | null;
  materialHasSuffix?: string | null;
  materialIn?: ReadonlyArray<string> | null;
  materialLT?: string | null;
  materialLTE?: string | null;
  materialNEQ?: string | null;
  materialNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemCardboardWhereInput | null;
  or?: ReadonlyArray<DemandItemCardboardWhereInput> | null;
  other?: string | null;
  otherContains?: string | null;
  otherContainsFold?: string | null;
  otherEqualFold?: string | null;
  otherGT?: string | null;
  otherGTE?: string | null;
  otherHasPrefix?: string | null;
  otherHasSuffix?: string | null;
  otherIn?: ReadonlyArray<string> | null;
  otherLT?: string | null;
  otherLTE?: string | null;
  otherNEQ?: string | null;
  otherNotIn?: ReadonlyArray<string> | null;
  printingColor?: string | null;
  printingColorContains?: string | null;
  printingColorContainsFold?: string | null;
  printingColorEqualFold?: string | null;
  printingColorGT?: string | null;
  printingColorGTE?: string | null;
  printingColorHasPrefix?: string | null;
  printingColorHasSuffix?: string | null;
  printingColorIn?: ReadonlyArray<string> | null;
  printingColorLT?: string | null;
  printingColorLTE?: string | null;
  printingColorNEQ?: string | null;
  printingColorNotIn?: ReadonlyArray<string> | null;
  processing?: string | null;
  processingContains?: string | null;
  processingContainsFold?: string | null;
  processingEqualFold?: string | null;
  processingGT?: string | null;
  processingGTE?: string | null;
  processingHasPrefix?: string | null;
  processingHasSuffix?: string | null;
  processingIn?: ReadonlyArray<string> | null;
  processingLT?: string | null;
  processingLTE?: string | null;
  processingNEQ?: string | null;
  processingNotIn?: ReadonlyArray<string> | null;
  size?: string | null;
  sizeContains?: string | null;
  sizeContainsFold?: string | null;
  sizeEqualFold?: string | null;
  sizeGT?: string | null;
  sizeGTE?: string | null;
  sizeHasPrefix?: string | null;
  sizeHasSuffix?: string | null;
  sizeIn?: ReadonlyArray<string> | null;
  sizeLT?: string | null;
  sizeLTE?: string | null;
  sizeNEQ?: string | null;
  sizeNotIn?: ReadonlyArray<string> | null;
  thickness?: string | null;
  thicknessContains?: string | null;
  thicknessContainsFold?: string | null;
  thicknessEqualFold?: string | null;
  thicknessGT?: string | null;
  thicknessGTE?: string | null;
  thicknessHasPrefix?: string | null;
  thicknessHasSuffix?: string | null;
  thicknessIn?: ReadonlyArray<string> | null;
  thicknessLT?: string | null;
  thicknessLTE?: string | null;
  thicknessNEQ?: string | null;
  thicknessNotIn?: ReadonlyArray<string> | null;
  type?: string | null;
  typeContains?: string | null;
  typeContainsFold?: string | null;
  typeEqualFold?: string | null;
  typeGT?: string | null;
  typeGTE?: string | null;
  typeHasPrefix?: string | null;
  typeHasSuffix?: string | null;
  typeIn?: ReadonlyArray<string> | null;
  typeLT?: string | null;
  typeLTE?: string | null;
  typeNEQ?: string | null;
  typeNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationItemWhereInput = {
  and?: ReadonlyArray<EstimationItemWhereInput> | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDIsNil?: boolean | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  demandItemIDNotNil?: boolean | null;
  estimationDetailID?: string | null;
  estimationDetailIDContains?: string | null;
  estimationDetailIDContainsFold?: string | null;
  estimationDetailIDEqualFold?: string | null;
  estimationDetailIDGT?: string | null;
  estimationDetailIDGTE?: string | null;
  estimationDetailIDHasPrefix?: string | null;
  estimationDetailIDHasSuffix?: string | null;
  estimationDetailIDIn?: ReadonlyArray<string> | null;
  estimationDetailIDLT?: string | null;
  estimationDetailIDLTE?: string | null;
  estimationDetailIDNEQ?: string | null;
  estimationDetailIDNotIn?: ReadonlyArray<string> | null;
  hasDemandItem?: boolean | null;
  hasDemandItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasEstimationDetail?: boolean | null;
  hasEstimationDetailWith?: ReadonlyArray<EstimationDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: EstimationItemWhereInput | null;
  or?: ReadonlyArray<EstimationItemWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationDetailWhereInput = {
  and?: ReadonlyArray<EstimationDetailWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  documentDisplayStatusIn?: ReadonlyArray<EstimationDetailDocumentDisplayStatus> | null;
  documentDisplayStatusNEQ?: EstimationDetailDocumentDisplayStatus | null;
  documentDisplayStatusNotIn?: ReadonlyArray<EstimationDetailDocumentDisplayStatus> | null;
  estimationID?: string | null;
  estimationIDContains?: string | null;
  estimationIDContainsFold?: string | null;
  estimationIDEqualFold?: string | null;
  estimationIDGT?: string | null;
  estimationIDGTE?: string | null;
  estimationIDHasPrefix?: string | null;
  estimationIDHasSuffix?: string | null;
  estimationIDIn?: ReadonlyArray<string> | null;
  estimationIDLT?: string | null;
  estimationIDLTE?: string | null;
  estimationIDNEQ?: string | null;
  estimationIDNotIn?: ReadonlyArray<string> | null;
  hasCost?: boolean | null;
  hasCostWith?: ReadonlyArray<EstimationCostWhereInput> | null;
  hasEstimation?: boolean | null;
  hasEstimationWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<EstimationItemWhereInput> | null;
  hasText?: boolean | null;
  hasTextWith?: ReadonlyArray<EstimationTextWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationDetailWhereInput | null;
  or?: ReadonlyArray<EstimationDetailWhereInput> | null;
  orderNumber?: any | null;
  orderNumberGT?: any | null;
  orderNumberGTE?: any | null;
  orderNumberIn?: ReadonlyArray<any> | null;
  orderNumberLT?: any | null;
  orderNumberLTE?: any | null;
  orderNumberNEQ?: any | null;
  orderNumberNotIn?: ReadonlyArray<any> | null;
  type?: EstimationDetailType | null;
  typeIn?: ReadonlyArray<EstimationDetailType> | null;
  typeNEQ?: EstimationDetailType | null;
  typeNotIn?: ReadonlyArray<EstimationDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationCostWhereInput = {
  and?: ReadonlyArray<EstimationCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDIsNil?: boolean | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDNotNil?: boolean | null;
  estimationDetailID?: string | null;
  estimationDetailIDContains?: string | null;
  estimationDetailIDContainsFold?: string | null;
  estimationDetailIDEqualFold?: string | null;
  estimationDetailIDGT?: string | null;
  estimationDetailIDGTE?: string | null;
  estimationDetailIDHasPrefix?: string | null;
  estimationDetailIDHasSuffix?: string | null;
  estimationDetailIDIn?: ReadonlyArray<string> | null;
  estimationDetailIDLT?: string | null;
  estimationDetailIDLTE?: string | null;
  estimationDetailIDNEQ?: string | null;
  estimationDetailIDNotIn?: ReadonlyArray<string> | null;
  hasDemandRecurringCost?: boolean | null;
  hasDemandRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasEstimationDetail?: boolean | null;
  hasEstimationDetailWith?: ReadonlyArray<EstimationDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: EstimationCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<EstimationCostWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandRecurringCostWhereInput = {
  and?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  costNumber?: any | null;
  costNumberGT?: any | null;
  costNumberGTE?: any | null;
  costNumberIn?: ReadonlyArray<any> | null;
  costNumberLT?: any | null;
  costNumberLTE?: any | null;
  costNumberNEQ?: any | null;
  costNumberNotIn?: ReadonlyArray<any> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandID?: string | null;
  demandIDContains?: string | null;
  demandIDContainsFold?: string | null;
  demandIDEqualFold?: string | null;
  demandIDGT?: string | null;
  demandIDGTE?: string | null;
  demandIDHasPrefix?: string | null;
  demandIDHasSuffix?: string | null;
  demandIDIn?: ReadonlyArray<string> | null;
  demandIDLT?: string | null;
  demandIDLTE?: string | null;
  demandIDNEQ?: string | null;
  demandIDNotIn?: ReadonlyArray<string> | null;
  endedAt?: any | null;
  endedAtGT?: any | null;
  endedAtGTE?: any | null;
  endedAtIn?: ReadonlyArray<any> | null;
  endedAtIsNil?: boolean | null;
  endedAtLT?: any | null;
  endedAtLTE?: any | null;
  endedAtNEQ?: any | null;
  endedAtNotIn?: ReadonlyArray<any> | null;
  endedAtNotNil?: boolean | null;
  hasChildren?: boolean | null;
  hasChildrenWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDemand?: boolean | null;
  hasDemandWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasEstimationCosts?: boolean | null;
  hasEstimationCostsWith?: ReadonlyArray<EstimationCostWhereInput> | null;
  hasInternalMemos?: boolean | null;
  hasInternalMemosWith?: ReadonlyArray<DemandRecurringCostInternalMemoWhereInput> | null;
  hasInvoiceRecurringCosts?: boolean | null;
  hasInvoiceRecurringCostsWith?: ReadonlyArray<InvoiceRecurringCostWhereInput> | null;
  hasOrderRecurringCosts?: boolean | null;
  hasOrderRecurringCostsWith?: ReadonlyArray<OrderRecurringCostWhereInput> | null;
  hasOriginal?: boolean | null;
  hasOriginalWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasPrices?: boolean | null;
  hasPricesWith?: ReadonlyArray<DemandRecurringCostPriceWhereInput> | null;
  hasSalesOrderRecurringCosts?: boolean | null;
  hasSalesOrderRecurringCostsWith?: ReadonlyArray<SalesOrderRecurringCostWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  isCurrent?: boolean | null;
  isCurrentNEQ?: boolean | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: DemandRecurringCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  startedAt?: any | null;
  startedAtGT?: any | null;
  startedAtGTE?: any | null;
  startedAtIn?: ReadonlyArray<any> | null;
  startedAtIsNil?: boolean | null;
  startedAtLT?: any | null;
  startedAtLTE?: any | null;
  startedAtNEQ?: any | null;
  startedAtNotIn?: ReadonlyArray<any> | null;
  startedAtNotNil?: boolean | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDIsNil?: boolean | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  supplierIDNotNil?: boolean | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
  version?: any | null;
  versionGT?: any | null;
  versionGTE?: any | null;
  versionIn?: ReadonlyArray<any> | null;
  versionLT?: any | null;
  versionLTE?: any | null;
  versionNEQ?: any | null;
  versionNotIn?: ReadonlyArray<any> | null;
};
export type DemandRecurringCostInternalMemoWhereInput = {
  and?: ReadonlyArray<DemandRecurringCostInternalMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasRecurringCost?: boolean | null;
  hasRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandRecurringCostInternalMemoWhereInput | null;
  or?: ReadonlyArray<DemandRecurringCostInternalMemoWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type InvoiceRecurringCostWhereInput = {
  and?: ReadonlyArray<InvoiceRecurringCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDIsNil?: boolean | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDNotNil?: boolean | null;
  hasDemandRecurringCost?: boolean | null;
  hasDemandRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasInvoiceDetail?: boolean | null;
  hasInvoiceDetailWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceDetailID?: string | null;
  invoiceDetailIDContains?: string | null;
  invoiceDetailIDContainsFold?: string | null;
  invoiceDetailIDEqualFold?: string | null;
  invoiceDetailIDGT?: string | null;
  invoiceDetailIDGTE?: string | null;
  invoiceDetailIDHasPrefix?: string | null;
  invoiceDetailIDHasSuffix?: string | null;
  invoiceDetailIDIn?: ReadonlyArray<string> | null;
  invoiceDetailIDLT?: string | null;
  invoiceDetailIDLTE?: string | null;
  invoiceDetailIDNEQ?: string | null;
  invoiceDetailIDNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceRecurringCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<InvoiceRecurringCostWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type InvoiceDetailWhereInput = {
  and?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasInvoice?: boolean | null;
  hasInvoiceDetailsSales?: boolean | null;
  hasInvoiceDetailsSalesWith?: ReadonlyArray<InvoiceDetailSaleWhereInput> | null;
  hasInvoiceWith?: ReadonlyArray<InvoiceWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<InvoiceItemWhereInput> | null;
  hasOnetimeCost?: boolean | null;
  hasOnetimeCostWith?: ReadonlyArray<InvoiceOnetimeCostWhereInput> | null;
  hasRecurringCost?: boolean | null;
  hasRecurringCostWith?: ReadonlyArray<InvoiceRecurringCostWhereInput> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceID?: string | null;
  invoiceIDContains?: string | null;
  invoiceIDContainsFold?: string | null;
  invoiceIDEqualFold?: string | null;
  invoiceIDGT?: string | null;
  invoiceIDGTE?: string | null;
  invoiceIDHasPrefix?: string | null;
  invoiceIDHasSuffix?: string | null;
  invoiceIDIn?: ReadonlyArray<string> | null;
  invoiceIDLT?: string | null;
  invoiceIDLTE?: string | null;
  invoiceIDNEQ?: string | null;
  invoiceIDNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceDetailWhereInput | null;
  or?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  orderNumber?: any | null;
  orderNumberGT?: any | null;
  orderNumberGTE?: any | null;
  orderNumberIn?: ReadonlyArray<any> | null;
  orderNumberLT?: any | null;
  orderNumberLTE?: any | null;
  orderNumberNEQ?: any | null;
  orderNumberNotIn?: ReadonlyArray<any> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDIsNil?: boolean | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDNotNil?: boolean | null;
  type?: InvoiceDetailType | null;
  typeIn?: ReadonlyArray<InvoiceDetailType> | null;
  typeNEQ?: InvoiceDetailType | null;
  typeNotIn?: ReadonlyArray<InvoiceDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type InvoiceDetailSaleWhereInput = {
  and?: ReadonlyArray<InvoiceDetailSaleWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasInvoiceDetail?: boolean | null;
  hasInvoiceDetailWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceDetailID?: string | null;
  invoiceDetailIDContains?: string | null;
  invoiceDetailIDContainsFold?: string | null;
  invoiceDetailIDEqualFold?: string | null;
  invoiceDetailIDGT?: string | null;
  invoiceDetailIDGTE?: string | null;
  invoiceDetailIDHasPrefix?: string | null;
  invoiceDetailIDHasSuffix?: string | null;
  invoiceDetailIDIn?: ReadonlyArray<string> | null;
  invoiceDetailIDLT?: string | null;
  invoiceDetailIDLTE?: string | null;
  invoiceDetailIDNEQ?: string | null;
  invoiceDetailIDNotIn?: ReadonlyArray<string> | null;
  isValid?: boolean | null;
  isValidNEQ?: boolean | null;
  not?: InvoiceDetailSaleWhereInput | null;
  or?: ReadonlyArray<InvoiceDetailSaleWhereInput> | null;
  salesDate?: any | null;
  salesDateGT?: any | null;
  salesDateGTE?: any | null;
  salesDateIn?: ReadonlyArray<any> | null;
  salesDateLT?: any | null;
  salesDateLTE?: any | null;
  salesDateNEQ?: any | null;
  salesDateNotIn?: ReadonlyArray<any> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type InvoiceWhereInput = {
  and?: ReadonlyArray<InvoiceWhereInput> | null;
  bookedAt?: any | null;
  bookedAtGT?: any | null;
  bookedAtGTE?: any | null;
  bookedAtIn?: ReadonlyArray<any> | null;
  bookedAtLT?: any | null;
  bookedAtLTE?: any | null;
  bookedAtNEQ?: any | null;
  bookedAtNotIn?: ReadonlyArray<any> | null;
  companyID?: string | null;
  companyIDContains?: string | null;
  companyIDContainsFold?: string | null;
  companyIDEqualFold?: string | null;
  companyIDGT?: string | null;
  companyIDGTE?: string | null;
  companyIDHasPrefix?: string | null;
  companyIDHasSuffix?: string | null;
  companyIDIn?: ReadonlyArray<string> | null;
  companyIDLT?: string | null;
  companyIDLTE?: string | null;
  companyIDNEQ?: string | null;
  companyIDNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  detailMessage?: string | null;
  detailMessageContains?: string | null;
  detailMessageContainsFold?: string | null;
  detailMessageEqualFold?: string | null;
  detailMessageGT?: string | null;
  detailMessageGTE?: string | null;
  detailMessageHasPrefix?: string | null;
  detailMessageHasSuffix?: string | null;
  detailMessageIn?: ReadonlyArray<string> | null;
  detailMessageIsNil?: boolean | null;
  detailMessageLT?: string | null;
  detailMessageLTE?: string | null;
  detailMessageNEQ?: string | null;
  detailMessageNotIn?: ReadonlyArray<string> | null;
  detailMessageNotNil?: boolean | null;
  hasCompany?: boolean | null;
  hasCompanyWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasEstimations?: boolean | null;
  hasEstimationsWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasFreeeInvoice?: boolean | null;
  hasFreeeInvoiceWith?: ReadonlyArray<FreeeInvoiceWhereInput> | null;
  hasInternalMemos?: boolean | null;
  hasInternalMemosWith?: ReadonlyArray<InvoiceInternalMemoWhereInput> | null;
  hasSalesOrders?: boolean | null;
  hasSalesOrdersWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceWhereInput | null;
  or?: ReadonlyArray<InvoiceWhereInput> | null;
  paymentDueAt?: any | null;
  paymentDueAtGT?: any | null;
  paymentDueAtGTE?: any | null;
  paymentDueAtIn?: ReadonlyArray<any> | null;
  paymentDueAtLT?: any | null;
  paymentDueAtLTE?: any | null;
  paymentDueAtNEQ?: any | null;
  paymentDueAtNotIn?: ReadonlyArray<any> | null;
  title?: string | null;
  titleContains?: string | null;
  titleContainsFold?: string | null;
  titleEqualFold?: string | null;
  titleGT?: string | null;
  titleGTE?: string | null;
  titleHasPrefix?: string | null;
  titleHasSuffix?: string | null;
  titleIn?: ReadonlyArray<string> | null;
  titleLT?: string | null;
  titleLTE?: string | null;
  titleNEQ?: string | null;
  titleNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationWhereInput = {
  and?: ReadonlyArray<EstimationWhereInput> | null;
  companyID?: string | null;
  companyIDContains?: string | null;
  companyIDContainsFold?: string | null;
  companyIDEqualFold?: string | null;
  companyIDGT?: string | null;
  companyIDGTE?: string | null;
  companyIDHasPrefix?: string | null;
  companyIDHasSuffix?: string | null;
  companyIDIn?: ReadonlyArray<string> | null;
  companyIDLT?: string | null;
  companyIDLTE?: string | null;
  companyIDNEQ?: string | null;
  companyIDNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  detailMessage?: string | null;
  detailMessageContains?: string | null;
  detailMessageContainsFold?: string | null;
  detailMessageEqualFold?: string | null;
  detailMessageGT?: string | null;
  detailMessageGTE?: string | null;
  detailMessageHasPrefix?: string | null;
  detailMessageHasSuffix?: string | null;
  detailMessageIn?: ReadonlyArray<string> | null;
  detailMessageIsNil?: boolean | null;
  detailMessageLT?: string | null;
  detailMessageLTE?: string | null;
  detailMessageNEQ?: string | null;
  detailMessageNotIn?: ReadonlyArray<string> | null;
  detailMessageNotNil?: boolean | null;
  estimationResponseID?: string | null;
  estimationResponseIDContains?: string | null;
  estimationResponseIDContainsFold?: string | null;
  estimationResponseIDEqualFold?: string | null;
  estimationResponseIDGT?: string | null;
  estimationResponseIDGTE?: string | null;
  estimationResponseIDHasPrefix?: string | null;
  estimationResponseIDHasSuffix?: string | null;
  estimationResponseIDIn?: ReadonlyArray<string> | null;
  estimationResponseIDIsNil?: boolean | null;
  estimationResponseIDLT?: string | null;
  estimationResponseIDLTE?: string | null;
  estimationResponseIDNEQ?: string | null;
  estimationResponseIDNotIn?: ReadonlyArray<string> | null;
  estimationResponseIDNotNil?: boolean | null;
  expirationPeriod?: string | null;
  expirationPeriodContains?: string | null;
  expirationPeriodContainsFold?: string | null;
  expirationPeriodEqualFold?: string | null;
  expirationPeriodGT?: string | null;
  expirationPeriodGTE?: string | null;
  expirationPeriodHasPrefix?: string | null;
  expirationPeriodHasSuffix?: string | null;
  expirationPeriodIn?: ReadonlyArray<string> | null;
  expirationPeriodLT?: string | null;
  expirationPeriodLTE?: string | null;
  expirationPeriodNEQ?: string | null;
  expirationPeriodNotIn?: ReadonlyArray<string> | null;
  hasCompany?: boolean | null;
  hasCompanyWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<EstimationDetailWhereInput> | null;
  hasEstimationResponse?: boolean | null;
  hasEstimationResponseWith?: ReadonlyArray<EstimationResponseWhereInput> | null;
  hasInternalAssignees?: boolean | null;
  hasInternalAssigneesWith?: ReadonlyArray<EstimationInternalAssigneeWhereInput> | null;
  hasOrders?: boolean | null;
  hasOrdersWith?: ReadonlyArray<OrderWhereInput> | null;
  hasSalesOrders?: boolean | null;
  hasSalesOrdersWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationWhereInput | null;
  or?: ReadonlyArray<EstimationWhereInput> | null;
  showAmountSummary?: boolean | null;
  showAmountSummaryNEQ?: boolean | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  title?: string | null;
  titleContains?: string | null;
  titleContainsFold?: string | null;
  titleEqualFold?: string | null;
  titleGT?: string | null;
  titleGTE?: string | null;
  titleHasPrefix?: string | null;
  titleHasSuffix?: string | null;
  titleIn?: ReadonlyArray<string> | null;
  titleLT?: string | null;
  titleLTE?: string | null;
  titleNEQ?: string | null;
  titleNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationResponseWhereInput = {
  and?: ReadonlyArray<EstimationResponseWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  estimationRequestAssigneeID?: string | null;
  estimationRequestAssigneeIDContains?: string | null;
  estimationRequestAssigneeIDContainsFold?: string | null;
  estimationRequestAssigneeIDEqualFold?: string | null;
  estimationRequestAssigneeIDGT?: string | null;
  estimationRequestAssigneeIDGTE?: string | null;
  estimationRequestAssigneeIDHasPrefix?: string | null;
  estimationRequestAssigneeIDHasSuffix?: string | null;
  estimationRequestAssigneeIDIn?: ReadonlyArray<string> | null;
  estimationRequestAssigneeIDLT?: string | null;
  estimationRequestAssigneeIDLTE?: string | null;
  estimationRequestAssigneeIDNEQ?: string | null;
  estimationRequestAssigneeIDNotIn?: ReadonlyArray<string> | null;
  expirationPeriod?: string | null;
  expirationPeriodContains?: string | null;
  expirationPeriodContainsFold?: string | null;
  expirationPeriodEqualFold?: string | null;
  expirationPeriodGT?: string | null;
  expirationPeriodGTE?: string | null;
  expirationPeriodHasPrefix?: string | null;
  expirationPeriodHasSuffix?: string | null;
  expirationPeriodIn?: ReadonlyArray<string> | null;
  expirationPeriodLT?: string | null;
  expirationPeriodLTE?: string | null;
  expirationPeriodNEQ?: string | null;
  expirationPeriodNotIn?: ReadonlyArray<string> | null;
  hasAssignee?: boolean | null;
  hasAssigneeWith?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<EstimationResponseDetailWhereInput> | null;
  hasEstimations?: boolean | null;
  hasEstimationsWith?: ReadonlyArray<EstimationWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  importantNotes?: string | null;
  importantNotesContains?: string | null;
  importantNotesContainsFold?: string | null;
  importantNotesEqualFold?: string | null;
  importantNotesGT?: string | null;
  importantNotesGTE?: string | null;
  importantNotesHasPrefix?: string | null;
  importantNotesHasSuffix?: string | null;
  importantNotesIn?: ReadonlyArray<string> | null;
  importantNotesLT?: string | null;
  importantNotesLTE?: string | null;
  importantNotesNEQ?: string | null;
  importantNotesNotIn?: ReadonlyArray<string> | null;
  not?: EstimationResponseWhereInput | null;
  or?: ReadonlyArray<EstimationResponseWhereInput> | null;
  responseDetailMessage?: string | null;
  responseDetailMessageContains?: string | null;
  responseDetailMessageContainsFold?: string | null;
  responseDetailMessageEqualFold?: string | null;
  responseDetailMessageGT?: string | null;
  responseDetailMessageGTE?: string | null;
  responseDetailMessageHasPrefix?: string | null;
  responseDetailMessageHasSuffix?: string | null;
  responseDetailMessageIn?: ReadonlyArray<string> | null;
  responseDetailMessageIsNil?: boolean | null;
  responseDetailMessageLT?: string | null;
  responseDetailMessageLTE?: string | null;
  responseDetailMessageNEQ?: string | null;
  responseDetailMessageNotIn?: ReadonlyArray<string> | null;
  responseDetailMessageNotNil?: boolean | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationRequestAssigneeWhereInput = {
  and?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  estimationRequestID?: string | null;
  estimationRequestIDContains?: string | null;
  estimationRequestIDContainsFold?: string | null;
  estimationRequestIDEqualFold?: string | null;
  estimationRequestIDGT?: string | null;
  estimationRequestIDGTE?: string | null;
  estimationRequestIDHasPrefix?: string | null;
  estimationRequestIDHasSuffix?: string | null;
  estimationRequestIDIn?: ReadonlyArray<string> | null;
  estimationRequestIDLT?: string | null;
  estimationRequestIDLTE?: string | null;
  estimationRequestIDNEQ?: string | null;
  estimationRequestIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasEstimationRequest?: boolean | null;
  hasEstimationRequestAssigneeContacts?: boolean | null;
  hasEstimationRequestAssigneeContactsWith?: ReadonlyArray<EstimationRequestAssigneeContactWhereInput> | null;
  hasEstimationRequestWith?: ReadonlyArray<EstimationRequestWhereInput> | null;
  hasResponses?: boolean | null;
  hasResponsesWith?: ReadonlyArray<EstimationResponseWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierContacts?: boolean | null;
  hasSupplierContactsWith?: ReadonlyArray<SupplierContactWhereInput> | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  mailSubject?: string | null;
  mailSubjectContains?: string | null;
  mailSubjectContainsFold?: string | null;
  mailSubjectEqualFold?: string | null;
  mailSubjectGT?: string | null;
  mailSubjectGTE?: string | null;
  mailSubjectHasPrefix?: string | null;
  mailSubjectHasSuffix?: string | null;
  mailSubjectIn?: ReadonlyArray<string> | null;
  mailSubjectLT?: string | null;
  mailSubjectLTE?: string | null;
  mailSubjectNEQ?: string | null;
  mailSubjectNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestAssigneeWhereInput | null;
  or?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  textMailBody?: string | null;
  textMailBodyContains?: string | null;
  textMailBodyContainsFold?: string | null;
  textMailBodyEqualFold?: string | null;
  textMailBodyGT?: string | null;
  textMailBodyGTE?: string | null;
  textMailBodyHasPrefix?: string | null;
  textMailBodyHasSuffix?: string | null;
  textMailBodyIn?: ReadonlyArray<string> | null;
  textMailBodyLT?: string | null;
  textMailBodyLTE?: string | null;
  textMailBodyNEQ?: string | null;
  textMailBodyNotIn?: ReadonlyArray<string> | null;
  token?: string | null;
  tokenContains?: string | null;
  tokenContainsFold?: string | null;
  tokenEqualFold?: string | null;
  tokenGT?: string | null;
  tokenGTE?: string | null;
  tokenHasPrefix?: string | null;
  tokenHasSuffix?: string | null;
  tokenIn?: ReadonlyArray<string> | null;
  tokenLT?: string | null;
  tokenLTE?: string | null;
  tokenNEQ?: string | null;
  tokenNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationRequestAssigneeContactWhereInput = {
  and?: ReadonlyArray<EstimationRequestAssigneeContactWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestAssigneeContactWhereInput | null;
  or?: ReadonlyArray<EstimationRequestAssigneeContactWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationRequestWhereInput = {
  and?: ReadonlyArray<EstimationRequestWhereInput> | null;
  companyID?: string | null;
  companyIDContains?: string | null;
  companyIDContainsFold?: string | null;
  companyIDEqualFold?: string | null;
  companyIDGT?: string | null;
  companyIDGTE?: string | null;
  companyIDHasPrefix?: string | null;
  companyIDHasSuffix?: string | null;
  companyIDIn?: ReadonlyArray<string> | null;
  companyIDLT?: string | null;
  companyIDLTE?: string | null;
  companyIDNEQ?: string | null;
  companyIDNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCompany?: boolean | null;
  hasCompanyWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<EstimationRequestDetailWhereInput> | null;
  hasInternalAssignees?: boolean | null;
  hasInternalAssigneesWith?: ReadonlyArray<EstimationRequestInternalAssigneeWhereInput> | null;
  hasRequestAssignees?: boolean | null;
  hasRequestAssigneesWith?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  hasRequestAttachments?: boolean | null;
  hasRequestAttachmentsWith?: ReadonlyArray<EstimationRequestAttachmentWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestWhereInput | null;
  or?: ReadonlyArray<EstimationRequestWhereInput> | null;
  requestTitle?: string | null;
  requestTitleContains?: string | null;
  requestTitleContainsFold?: string | null;
  requestTitleEqualFold?: string | null;
  requestTitleGT?: string | null;
  requestTitleGTE?: string | null;
  requestTitleHasPrefix?: string | null;
  requestTitleHasSuffix?: string | null;
  requestTitleIn?: ReadonlyArray<string> | null;
  requestTitleLT?: string | null;
  requestTitleLTE?: string | null;
  requestTitleNEQ?: string | null;
  requestTitleNotIn?: ReadonlyArray<string> | null;
  specText?: string | null;
  specTextContains?: string | null;
  specTextContainsFold?: string | null;
  specTextEqualFold?: string | null;
  specTextGT?: string | null;
  specTextGTE?: string | null;
  specTextHasPrefix?: string | null;
  specTextHasSuffix?: string | null;
  specTextIn?: ReadonlyArray<string> | null;
  specTextLT?: string | null;
  specTextLTE?: string | null;
  specTextNEQ?: string | null;
  specTextNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationRequestDetailWhereInput = {
  and?: ReadonlyArray<EstimationRequestDetailWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationRequestID?: string | null;
  estimationRequestIDContains?: string | null;
  estimationRequestIDContainsFold?: string | null;
  estimationRequestIDEqualFold?: string | null;
  estimationRequestIDGT?: string | null;
  estimationRequestIDGTE?: string | null;
  estimationRequestIDHasPrefix?: string | null;
  estimationRequestIDHasSuffix?: string | null;
  estimationRequestIDIn?: ReadonlyArray<string> | null;
  estimationRequestIDLT?: string | null;
  estimationRequestIDLTE?: string | null;
  estimationRequestIDNEQ?: string | null;
  estimationRequestIDNotIn?: ReadonlyArray<string> | null;
  hasEstimationRequest?: boolean | null;
  hasEstimationRequestWith?: ReadonlyArray<EstimationRequestWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<EstimationRequestItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestDetailWhereInput | null;
  or?: ReadonlyArray<EstimationRequestDetailWhereInput> | null;
  orderNumber?: any | null;
  orderNumberGT?: any | null;
  orderNumberGTE?: any | null;
  orderNumberIn?: ReadonlyArray<any> | null;
  orderNumberLT?: any | null;
  orderNumberLTE?: any | null;
  orderNumberNEQ?: any | null;
  orderNumberNotIn?: ReadonlyArray<any> | null;
  type?: EstimationRequestDetailType | null;
  typeIn?: ReadonlyArray<EstimationRequestDetailType> | null;
  typeNEQ?: EstimationRequestDetailType | null;
  typeNotIn?: ReadonlyArray<EstimationRequestDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationRequestItemWhereInput = {
  and?: ReadonlyArray<EstimationRequestItemWhereInput> | null;
  annualQuantity?: string | null;
  annualQuantityContains?: string | null;
  annualQuantityContainsFold?: string | null;
  annualQuantityEqualFold?: string | null;
  annualQuantityGT?: string | null;
  annualQuantityGTE?: string | null;
  annualQuantityHasPrefix?: string | null;
  annualQuantityHasSuffix?: string | null;
  annualQuantityIn?: ReadonlyArray<string> | null;
  annualQuantityIsNil?: boolean | null;
  annualQuantityLT?: string | null;
  annualQuantityLTE?: string | null;
  annualQuantityNEQ?: string | null;
  annualQuantityNotIn?: ReadonlyArray<string> | null;
  annualQuantityNotNil?: boolean | null;
  askingUnitPrice?: string | null;
  askingUnitPriceContains?: string | null;
  askingUnitPriceContainsFold?: string | null;
  askingUnitPriceEqualFold?: string | null;
  askingUnitPriceGT?: string | null;
  askingUnitPriceGTE?: string | null;
  askingUnitPriceHasPrefix?: string | null;
  askingUnitPriceHasSuffix?: string | null;
  askingUnitPriceIn?: ReadonlyArray<string> | null;
  askingUnitPriceIsNil?: boolean | null;
  askingUnitPriceLT?: string | null;
  askingUnitPriceLTE?: string | null;
  askingUnitPriceNEQ?: string | null;
  askingUnitPriceNotIn?: ReadonlyArray<string> | null;
  askingUnitPriceNotNil?: boolean | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationRequestDetailID?: string | null;
  estimationRequestDetailIDContains?: string | null;
  estimationRequestDetailIDContainsFold?: string | null;
  estimationRequestDetailIDEqualFold?: string | null;
  estimationRequestDetailIDGT?: string | null;
  estimationRequestDetailIDGTE?: string | null;
  estimationRequestDetailIDHasPrefix?: string | null;
  estimationRequestDetailIDHasSuffix?: string | null;
  estimationRequestDetailIDIn?: ReadonlyArray<string> | null;
  estimationRequestDetailIDLT?: string | null;
  estimationRequestDetailIDLTE?: string | null;
  estimationRequestDetailIDNEQ?: string | null;
  estimationRequestDetailIDNotIn?: ReadonlyArray<string> | null;
  hasDetail?: boolean | null;
  hasDetailWith?: ReadonlyArray<EstimationRequestDetailWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestItemWhereInput | null;
  or?: ReadonlyArray<EstimationRequestItemWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationRequestInternalAssigneeWhereInput = {
  and?: ReadonlyArray<EstimationRequestInternalAssigneeWhereInput> | null;
  estimationRequestID?: string | null;
  estimationRequestIDContains?: string | null;
  estimationRequestIDContainsFold?: string | null;
  estimationRequestIDEqualFold?: string | null;
  estimationRequestIDGT?: string | null;
  estimationRequestIDGTE?: string | null;
  estimationRequestIDHasPrefix?: string | null;
  estimationRequestIDHasSuffix?: string | null;
  estimationRequestIDIn?: ReadonlyArray<string> | null;
  estimationRequestIDLT?: string | null;
  estimationRequestIDLTE?: string | null;
  estimationRequestIDNEQ?: string | null;
  estimationRequestIDNotIn?: ReadonlyArray<string> | null;
  hasEstimationRequest?: boolean | null;
  hasEstimationRequestWith?: ReadonlyArray<EstimationRequestWhereInput> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestInternalAssigneeWhereInput | null;
  or?: ReadonlyArray<EstimationRequestInternalAssigneeWhereInput> | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationRequestAttachmentWhereInput = {
  and?: ReadonlyArray<EstimationRequestAttachmentWhereInput> | null;
  byteSize?: any | null;
  byteSizeGT?: any | null;
  byteSizeGTE?: any | null;
  byteSizeIn?: ReadonlyArray<any> | null;
  byteSizeLT?: any | null;
  byteSizeLTE?: any | null;
  byteSizeNEQ?: any | null;
  byteSizeNotIn?: ReadonlyArray<any> | null;
  contentType?: string | null;
  contentTypeContains?: string | null;
  contentTypeContainsFold?: string | null;
  contentTypeEqualFold?: string | null;
  contentTypeGT?: string | null;
  contentTypeGTE?: string | null;
  contentTypeHasPrefix?: string | null;
  contentTypeHasSuffix?: string | null;
  contentTypeIn?: ReadonlyArray<string> | null;
  contentTypeLT?: string | null;
  contentTypeLTE?: string | null;
  contentTypeNEQ?: string | null;
  contentTypeNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationRequestID?: string | null;
  estimationRequestIDContains?: string | null;
  estimationRequestIDContainsFold?: string | null;
  estimationRequestIDEqualFold?: string | null;
  estimationRequestIDGT?: string | null;
  estimationRequestIDGTE?: string | null;
  estimationRequestIDHasPrefix?: string | null;
  estimationRequestIDHasSuffix?: string | null;
  estimationRequestIDIn?: ReadonlyArray<string> | null;
  estimationRequestIDLT?: string | null;
  estimationRequestIDLTE?: string | null;
  estimationRequestIDNEQ?: string | null;
  estimationRequestIDNotIn?: ReadonlyArray<string> | null;
  hasEstimationRequest?: boolean | null;
  hasEstimationRequestWith?: ReadonlyArray<EstimationRequestWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: EstimationRequestAttachmentWhereInput | null;
  or?: ReadonlyArray<EstimationRequestAttachmentWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type SupplierContactWhereInput = {
  and?: ReadonlyArray<SupplierContactWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasEstimationRequestAsignees?: boolean | null;
  hasEstimationRequestAsigneesWith?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  hasEstimationRequestAssigneeContacts?: boolean | null;
  hasEstimationRequestAssigneeContactsWith?: ReadonlyArray<EstimationRequestAssigneeContactWhereInput> | null;
  hasMemos?: boolean | null;
  hasMemosWith?: ReadonlyArray<SupplierContactMemoWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  not?: SupplierContactWhereInput | null;
  or?: ReadonlyArray<SupplierContactWhereInput> | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type SupplierContactMemoWhereInput = {
  and?: ReadonlyArray<SupplierContactMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasSupplierContact?: boolean | null;
  hasSupplierContactWith?: ReadonlyArray<SupplierContactWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SupplierContactMemoWhereInput | null;
  or?: ReadonlyArray<SupplierContactMemoWhereInput> | null;
  supplierContactID?: string | null;
  supplierContactIDContains?: string | null;
  supplierContactIDContainsFold?: string | null;
  supplierContactIDEqualFold?: string | null;
  supplierContactIDGT?: string | null;
  supplierContactIDGTE?: string | null;
  supplierContactIDHasPrefix?: string | null;
  supplierContactIDHasSuffix?: string | null;
  supplierContactIDIn?: ReadonlyArray<string> | null;
  supplierContactIDLT?: string | null;
  supplierContactIDLTE?: string | null;
  supplierContactIDNEQ?: string | null;
  supplierContactIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type SupplierWhereInput = {
  and?: ReadonlyArray<SupplierWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasContacts?: boolean | null;
  hasContactsWith?: ReadonlyArray<SupplierContactWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasEstimationRequestAssignees?: boolean | null;
  hasEstimationRequestAssigneesWith?: ReadonlyArray<EstimationRequestAssigneeWhereInput> | null;
  hasEstimations?: boolean | null;
  hasEstimationsWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasOrders?: boolean | null;
  hasOrdersWith?: ReadonlyArray<OrderWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: SupplierWhereInput | null;
  or?: ReadonlyArray<SupplierWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type OrderWhereInput = {
  and?: ReadonlyArray<OrderWhereInput> | null;
  companyID?: string | null;
  companyIDContains?: string | null;
  companyIDContainsFold?: string | null;
  companyIDEqualFold?: string | null;
  companyIDGT?: string | null;
  companyIDGTE?: string | null;
  companyIDHasPrefix?: string | null;
  companyIDHasSuffix?: string | null;
  companyIDIn?: ReadonlyArray<string> | null;
  companyIDLT?: string | null;
  companyIDLTE?: string | null;
  companyIDNEQ?: string | null;
  companyIDNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  detailMessage?: string | null;
  detailMessageContains?: string | null;
  detailMessageContainsFold?: string | null;
  detailMessageEqualFold?: string | null;
  detailMessageGT?: string | null;
  detailMessageGTE?: string | null;
  detailMessageHasPrefix?: string | null;
  detailMessageHasSuffix?: string | null;
  detailMessageIn?: ReadonlyArray<string> | null;
  detailMessageIsNil?: boolean | null;
  detailMessageLT?: string | null;
  detailMessageLTE?: string | null;
  detailMessageNEQ?: string | null;
  detailMessageNotIn?: ReadonlyArray<string> | null;
  detailMessageNotNil?: boolean | null;
  estimationID?: string | null;
  estimationIDContains?: string | null;
  estimationIDContainsFold?: string | null;
  estimationIDEqualFold?: string | null;
  estimationIDGT?: string | null;
  estimationIDGTE?: string | null;
  estimationIDHasPrefix?: string | null;
  estimationIDHasSuffix?: string | null;
  estimationIDIn?: ReadonlyArray<string> | null;
  estimationIDIsNil?: boolean | null;
  estimationIDLT?: string | null;
  estimationIDLTE?: string | null;
  estimationIDNEQ?: string | null;
  estimationIDNotIn?: ReadonlyArray<string> | null;
  estimationIDNotNil?: boolean | null;
  hasAssignees?: boolean | null;
  hasAssigneesWith?: ReadonlyArray<OrderAssigneeWhereInput> | null;
  hasCompany?: boolean | null;
  hasCompanyWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDeliveryInfo?: boolean | null;
  hasDeliveryInfoWith?: ReadonlyArray<OrderDeliveryInfoWhereInput> | null;
  hasDetails?: boolean | null;
  hasDetailsWith?: ReadonlyArray<OrderDetailWhereInput> | null;
  hasEstimation?: boolean | null;
  hasEstimationWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasInternalAssignees?: boolean | null;
  hasInternalAssigneesWith?: ReadonlyArray<OrderInternalAssigneeWhereInput> | null;
  hasSalesOrders?: boolean | null;
  hasSalesOrdersWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasSupplier?: boolean | null;
  hasSupplierWith?: ReadonlyArray<SupplierWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  mailBodyMessage?: string | null;
  mailBodyMessageContains?: string | null;
  mailBodyMessageContainsFold?: string | null;
  mailBodyMessageEqualFold?: string | null;
  mailBodyMessageGT?: string | null;
  mailBodyMessageGTE?: string | null;
  mailBodyMessageHasPrefix?: string | null;
  mailBodyMessageHasSuffix?: string | null;
  mailBodyMessageIn?: ReadonlyArray<string> | null;
  mailBodyMessageIsNil?: boolean | null;
  mailBodyMessageLT?: string | null;
  mailBodyMessageLTE?: string | null;
  mailBodyMessageNEQ?: string | null;
  mailBodyMessageNotIn?: ReadonlyArray<string> | null;
  mailBodyMessageNotNil?: boolean | null;
  not?: OrderWhereInput | null;
  or?: ReadonlyArray<OrderWhereInput> | null;
  supplierID?: string | null;
  supplierIDContains?: string | null;
  supplierIDContainsFold?: string | null;
  supplierIDEqualFold?: string | null;
  supplierIDGT?: string | null;
  supplierIDGTE?: string | null;
  supplierIDHasPrefix?: string | null;
  supplierIDHasSuffix?: string | null;
  supplierIDIn?: ReadonlyArray<string> | null;
  supplierIDLT?: string | null;
  supplierIDLTE?: string | null;
  supplierIDNEQ?: string | null;
  supplierIDNotIn?: ReadonlyArray<string> | null;
  title?: string | null;
  titleContains?: string | null;
  titleContainsFold?: string | null;
  titleEqualFold?: string | null;
  titleGT?: string | null;
  titleGTE?: string | null;
  titleHasPrefix?: string | null;
  titleHasSuffix?: string | null;
  titleIn?: ReadonlyArray<string> | null;
  titleLT?: string | null;
  titleLTE?: string | null;
  titleNEQ?: string | null;
  titleNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type OrderAssigneeWhereInput = {
  and?: ReadonlyArray<OrderAssigneeWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  hasContact?: boolean | null;
  hasContactWith?: ReadonlyArray<SupplierContactWhereInput> | null;
  hasOrder?: boolean | null;
  hasOrderWith?: ReadonlyArray<OrderWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: OrderAssigneeWhereInput | null;
  or?: ReadonlyArray<OrderAssigneeWhereInput> | null;
  orderID?: string | null;
  orderIDContains?: string | null;
  orderIDContainsFold?: string | null;
  orderIDEqualFold?: string | null;
  orderIDGT?: string | null;
  orderIDGTE?: string | null;
  orderIDHasPrefix?: string | null;
  orderIDHasSuffix?: string | null;
  orderIDIn?: ReadonlyArray<string> | null;
  orderIDLT?: string | null;
  orderIDLTE?: string | null;
  orderIDNEQ?: string | null;
  orderIDNotIn?: ReadonlyArray<string> | null;
  recipientType?: OrderAssigneeRecipientType | null;
  recipientTypeIn?: ReadonlyArray<OrderAssigneeRecipientType> | null;
  recipientTypeNEQ?: OrderAssigneeRecipientType | null;
  recipientTypeNotIn?: ReadonlyArray<OrderAssigneeRecipientType> | null;
  supplierContactID?: string | null;
  supplierContactIDContains?: string | null;
  supplierContactIDContainsFold?: string | null;
  supplierContactIDEqualFold?: string | null;
  supplierContactIDGT?: string | null;
  supplierContactIDGTE?: string | null;
  supplierContactIDHasPrefix?: string | null;
  supplierContactIDHasSuffix?: string | null;
  supplierContactIDIn?: ReadonlyArray<string> | null;
  supplierContactIDLT?: string | null;
  supplierContactIDLTE?: string | null;
  supplierContactIDNEQ?: string | null;
  supplierContactIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type OrderDeliveryInfoWhereInput = {
  and?: ReadonlyArray<OrderDeliveryInfoWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  desiredDeliveryDate?: string | null;
  desiredDeliveryDateContains?: string | null;
  desiredDeliveryDateContainsFold?: string | null;
  desiredDeliveryDateEqualFold?: string | null;
  desiredDeliveryDateGT?: string | null;
  desiredDeliveryDateGTE?: string | null;
  desiredDeliveryDateHasPrefix?: string | null;
  desiredDeliveryDateHasSuffix?: string | null;
  desiredDeliveryDateIn?: ReadonlyArray<string> | null;
  desiredDeliveryDateLT?: string | null;
  desiredDeliveryDateLTE?: string | null;
  desiredDeliveryDateNEQ?: string | null;
  desiredDeliveryDateNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasOrder?: boolean | null;
  hasOrderWith?: ReadonlyArray<OrderWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: OrderDeliveryInfoWhereInput | null;
  or?: ReadonlyArray<OrderDeliveryInfoWhereInput> | null;
  orderID?: string | null;
  orderIDContains?: string | null;
  orderIDContainsFold?: string | null;
  orderIDEqualFold?: string | null;
  orderIDGT?: string | null;
  orderIDGTE?: string | null;
  orderIDHasPrefix?: string | null;
  orderIDHasSuffix?: string | null;
  orderIDIn?: ReadonlyArray<string> | null;
  orderIDLT?: string | null;
  orderIDLTE?: string | null;
  orderIDNEQ?: string | null;
  orderIDNotIn?: ReadonlyArray<string> | null;
  recipientsText?: string | null;
  recipientsTextContains?: string | null;
  recipientsTextContainsFold?: string | null;
  recipientsTextEqualFold?: string | null;
  recipientsTextGT?: string | null;
  recipientsTextGTE?: string | null;
  recipientsTextHasPrefix?: string | null;
  recipientsTextHasSuffix?: string | null;
  recipientsTextIn?: ReadonlyArray<string> | null;
  recipientsTextIsNil?: boolean | null;
  recipientsTextLT?: string | null;
  recipientsTextLTE?: string | null;
  recipientsTextNEQ?: string | null;
  recipientsTextNotIn?: ReadonlyArray<string> | null;
  recipientsTextNotNil?: boolean | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type OrderDetailWhereInput = {
  and?: ReadonlyArray<OrderDetailWhereInput> | null;
  branchNumber?: any | null;
  branchNumberGT?: any | null;
  branchNumberGTE?: any | null;
  branchNumberIn?: ReadonlyArray<any> | null;
  branchNumberLT?: any | null;
  branchNumberLTE?: any | null;
  branchNumberNEQ?: any | null;
  branchNumberNotIn?: ReadonlyArray<any> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  displayOrder?: any | null;
  displayOrderGT?: any | null;
  displayOrderGTE?: any | null;
  displayOrderIn?: ReadonlyArray<any> | null;
  displayOrderLT?: any | null;
  displayOrderLTE?: any | null;
  displayOrderNEQ?: any | null;
  displayOrderNotIn?: ReadonlyArray<any> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<OrderItemWhereInput> | null;
  hasOnetimeCost?: boolean | null;
  hasOnetimeCostWith?: ReadonlyArray<OrderOnetimeCostWhereInput> | null;
  hasOrder?: boolean | null;
  hasOrderWith?: ReadonlyArray<OrderWhereInput> | null;
  hasRecurringCost?: boolean | null;
  hasRecurringCostWith?: ReadonlyArray<OrderRecurringCostWhereInput> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: OrderDetailWhereInput | null;
  or?: ReadonlyArray<OrderDetailWhereInput> | null;
  orderID?: string | null;
  orderIDContains?: string | null;
  orderIDContainsFold?: string | null;
  orderIDEqualFold?: string | null;
  orderIDGT?: string | null;
  orderIDGTE?: string | null;
  orderIDHasPrefix?: string | null;
  orderIDHasSuffix?: string | null;
  orderIDIn?: ReadonlyArray<string> | null;
  orderIDLT?: string | null;
  orderIDLTE?: string | null;
  orderIDNEQ?: string | null;
  orderIDNotIn?: ReadonlyArray<string> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDIsNil?: boolean | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDNotNil?: boolean | null;
  type?: OrderDetailType | null;
  typeIn?: ReadonlyArray<OrderDetailType> | null;
  typeNEQ?: OrderDetailType | null;
  typeNotIn?: ReadonlyArray<OrderDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type OrderItemWhereInput = {
  and?: ReadonlyArray<OrderItemWhereInput> | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDIsNil?: boolean | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  demandItemIDNotNil?: boolean | null;
  hasDemandItem?: boolean | null;
  hasDemandItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasOrderDetail?: boolean | null;
  hasOrderDetailWith?: ReadonlyArray<OrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: OrderItemWhereInput | null;
  or?: ReadonlyArray<OrderItemWhereInput> | null;
  orderDetailID?: string | null;
  orderDetailIDContains?: string | null;
  orderDetailIDContainsFold?: string | null;
  orderDetailIDEqualFold?: string | null;
  orderDetailIDGT?: string | null;
  orderDetailIDGTE?: string | null;
  orderDetailIDHasPrefix?: string | null;
  orderDetailIDHasSuffix?: string | null;
  orderDetailIDIn?: ReadonlyArray<string> | null;
  orderDetailIDLT?: string | null;
  orderDetailIDLTE?: string | null;
  orderDetailIDNEQ?: string | null;
  orderDetailIDNotIn?: ReadonlyArray<string> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type TaxCategoryWhereInput = {
  and?: ReadonlyArray<TaxCategoryWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: TaxCategoryWhereInput | null;
  or?: ReadonlyArray<TaxCategoryWhereInput> | null;
  rate?: number | null;
  rateGT?: number | null;
  rateGTE?: number | null;
  rateIn?: ReadonlyArray<number> | null;
  rateLT?: number | null;
  rateLTE?: number | null;
  rateNEQ?: number | null;
  rateNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type OrderOnetimeCostWhereInput = {
  and?: ReadonlyArray<OrderOnetimeCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  hasOrderDetail?: boolean | null;
  hasOrderDetailWith?: ReadonlyArray<OrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: OrderOnetimeCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<OrderOnetimeCostWhereInput> | null;
  orderDetailID?: string | null;
  orderDetailIDContains?: string | null;
  orderDetailIDContainsFold?: string | null;
  orderDetailIDEqualFold?: string | null;
  orderDetailIDGT?: string | null;
  orderDetailIDGTE?: string | null;
  orderDetailIDHasPrefix?: string | null;
  orderDetailIDHasSuffix?: string | null;
  orderDetailIDIn?: ReadonlyArray<string> | null;
  orderDetailIDLT?: string | null;
  orderDetailIDLTE?: string | null;
  orderDetailIDNEQ?: string | null;
  orderDetailIDNotIn?: ReadonlyArray<string> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type OrderRecurringCostWhereInput = {
  and?: ReadonlyArray<OrderRecurringCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDIsNil?: boolean | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDNotNil?: boolean | null;
  hasDemandRecurringCost?: boolean | null;
  hasDemandRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasOrderDetail?: boolean | null;
  hasOrderDetailWith?: ReadonlyArray<OrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: OrderRecurringCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<OrderRecurringCostWhereInput> | null;
  orderDetailID?: string | null;
  orderDetailIDContains?: string | null;
  orderDetailIDContainsFold?: string | null;
  orderDetailIDEqualFold?: string | null;
  orderDetailIDGT?: string | null;
  orderDetailIDGTE?: string | null;
  orderDetailIDHasPrefix?: string | null;
  orderDetailIDHasSuffix?: string | null;
  orderDetailIDIn?: ReadonlyArray<string> | null;
  orderDetailIDLT?: string | null;
  orderDetailIDLTE?: string | null;
  orderDetailIDNEQ?: string | null;
  orderDetailIDNotIn?: ReadonlyArray<string> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type SalesOrderDetailWhereInput = {
  and?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  branchNumber?: any | null;
  branchNumberGT?: any | null;
  branchNumberGTE?: any | null;
  branchNumberIn?: ReadonlyArray<any> | null;
  branchNumberLT?: any | null;
  branchNumberLTE?: any | null;
  branchNumberNEQ?: any | null;
  branchNumberNotIn?: ReadonlyArray<any> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDeliveryEvents?: boolean | null;
  hasDeliveryEventsWith?: ReadonlyArray<SalesOrderDetailDeliveryEventWhereInput> | null;
  hasInvoiceDetails?: boolean | null;
  hasInvoiceDetailsWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<SalesOrderItemWhereInput> | null;
  hasNotionPage?: boolean | null;
  hasNotionPageWith?: ReadonlyArray<SalesOrderDetailNotionPageWhereInput> | null;
  hasOnetimeCost?: boolean | null;
  hasOnetimeCostWith?: ReadonlyArray<SalesOrderOnetimeCostWhereInput> | null;
  hasOrderDetails?: boolean | null;
  hasOrderDetailsWith?: ReadonlyArray<OrderDetailWhereInput> | null;
  hasRecurringCost?: boolean | null;
  hasRecurringCostWith?: ReadonlyArray<SalesOrderRecurringCostWhereInput> | null;
  hasSalesOrder?: boolean | null;
  hasSalesOrderWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderDetailWhereInput | null;
  or?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  orderNumber?: any | null;
  orderNumberGT?: any | null;
  orderNumberGTE?: any | null;
  orderNumberIn?: ReadonlyArray<any> | null;
  orderNumberLT?: any | null;
  orderNumberLTE?: any | null;
  orderNumberNEQ?: any | null;
  orderNumberNotIn?: ReadonlyArray<any> | null;
  salesOrderID?: string | null;
  salesOrderIDContains?: string | null;
  salesOrderIDContainsFold?: string | null;
  salesOrderIDEqualFold?: string | null;
  salesOrderIDGT?: string | null;
  salesOrderIDGTE?: string | null;
  salesOrderIDHasPrefix?: string | null;
  salesOrderIDHasSuffix?: string | null;
  salesOrderIDIn?: ReadonlyArray<string> | null;
  salesOrderIDLT?: string | null;
  salesOrderIDLTE?: string | null;
  salesOrderIDNEQ?: string | null;
  salesOrderIDNotIn?: ReadonlyArray<string> | null;
  type?: SalesOrderDetailType | null;
  typeIn?: ReadonlyArray<SalesOrderDetailType> | null;
  typeNEQ?: SalesOrderDetailType | null;
  typeNotIn?: ReadonlyArray<SalesOrderDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type SalesOrderDetailDeliveryEventWhereInput = {
  and?: ReadonlyArray<SalesOrderDetailDeliveryEventWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  fixedDeliveryDate?: any | null;
  fixedDeliveryDateGT?: any | null;
  fixedDeliveryDateGTE?: any | null;
  fixedDeliveryDateIn?: ReadonlyArray<any> | null;
  fixedDeliveryDateLT?: any | null;
  fixedDeliveryDateLTE?: any | null;
  fixedDeliveryDateNEQ?: any | null;
  fixedDeliveryDateNotIn?: ReadonlyArray<any> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  isValid?: boolean | null;
  isValidNEQ?: boolean | null;
  not?: SalesOrderDetailDeliveryEventWhereInput | null;
  or?: ReadonlyArray<SalesOrderDetailDeliveryEventWhereInput> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type SalesOrderItemWhereInput = {
  and?: ReadonlyArray<SalesOrderItemWhereInput> | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDIsNil?: boolean | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  demandItemIDNotNil?: boolean | null;
  hasDemandItem?: boolean | null;
  hasDemandItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderItemWhereInput | null;
  or?: ReadonlyArray<SalesOrderItemWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type SalesOrderDetailNotionPageWhereInput = {
  and?: ReadonlyArray<SalesOrderDetailNotionPageWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderDetailNotionPageWhereInput | null;
  notionPageID?: string | null;
  notionPageIDContains?: string | null;
  notionPageIDContainsFold?: string | null;
  notionPageIDEqualFold?: string | null;
  notionPageIDGT?: string | null;
  notionPageIDGTE?: string | null;
  notionPageIDHasPrefix?: string | null;
  notionPageIDHasSuffix?: string | null;
  notionPageIDIn?: ReadonlyArray<string> | null;
  notionPageIDLT?: string | null;
  notionPageIDLTE?: string | null;
  notionPageIDNEQ?: string | null;
  notionPageIDNotIn?: ReadonlyArray<string> | null;
  notionPageURL?: string | null;
  notionPageURLContains?: string | null;
  notionPageURLContainsFold?: string | null;
  notionPageURLEqualFold?: string | null;
  notionPageURLGT?: string | null;
  notionPageURLGTE?: string | null;
  notionPageURLHasPrefix?: string | null;
  notionPageURLHasSuffix?: string | null;
  notionPageURLIn?: ReadonlyArray<string> | null;
  notionPageURLLT?: string | null;
  notionPageURLLTE?: string | null;
  notionPageURLNEQ?: string | null;
  notionPageURLNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<SalesOrderDetailNotionPageWhereInput> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type SalesOrderOnetimeCostWhereInput = {
  and?: ReadonlyArray<SalesOrderOnetimeCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderOnetimeCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<SalesOrderOnetimeCostWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type SalesOrderRecurringCostWhereInput = {
  and?: ReadonlyArray<SalesOrderRecurringCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDIsNil?: boolean | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDNotNil?: boolean | null;
  hasDemandRecurringCost?: boolean | null;
  hasDemandRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasSalesOrderDetail?: boolean | null;
  hasSalesOrderDetailWith?: ReadonlyArray<SalesOrderDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderRecurringCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<SalesOrderRecurringCostWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  salesOrderDetailID?: string | null;
  salesOrderDetailIDContains?: string | null;
  salesOrderDetailIDContainsFold?: string | null;
  salesOrderDetailIDEqualFold?: string | null;
  salesOrderDetailIDGT?: string | null;
  salesOrderDetailIDGTE?: string | null;
  salesOrderDetailIDHasPrefix?: string | null;
  salesOrderDetailIDHasSuffix?: string | null;
  salesOrderDetailIDIn?: ReadonlyArray<string> | null;
  salesOrderDetailIDLT?: string | null;
  salesOrderDetailIDLTE?: string | null;
  salesOrderDetailIDNEQ?: string | null;
  salesOrderDetailIDNotIn?: ReadonlyArray<string> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type OrderInternalAssigneeWhereInput = {
  and?: ReadonlyArray<OrderInternalAssigneeWhereInput> | null;
  hasOrder?: boolean | null;
  hasOrderWith?: ReadonlyArray<OrderWhereInput> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: OrderInternalAssigneeWhereInput | null;
  or?: ReadonlyArray<OrderInternalAssigneeWhereInput> | null;
  orderID?: string | null;
  orderIDContains?: string | null;
  orderIDContainsFold?: string | null;
  orderIDEqualFold?: string | null;
  orderIDGT?: string | null;
  orderIDGTE?: string | null;
  orderIDHasPrefix?: string | null;
  orderIDHasSuffix?: string | null;
  orderIDIn?: ReadonlyArray<string> | null;
  orderIDLT?: string | null;
  orderIDLTE?: string | null;
  orderIDNEQ?: string | null;
  orderIDNotIn?: ReadonlyArray<string> | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationResponseDetailWhereInput = {
  and?: ReadonlyArray<EstimationResponseDetailWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationResponseID?: string | null;
  estimationResponseIDContains?: string | null;
  estimationResponseIDContainsFold?: string | null;
  estimationResponseIDEqualFold?: string | null;
  estimationResponseIDGT?: string | null;
  estimationResponseIDGTE?: string | null;
  estimationResponseIDHasPrefix?: string | null;
  estimationResponseIDHasSuffix?: string | null;
  estimationResponseIDIn?: ReadonlyArray<string> | null;
  estimationResponseIDLT?: string | null;
  estimationResponseIDLTE?: string | null;
  estimationResponseIDNEQ?: string | null;
  estimationResponseIDNotIn?: ReadonlyArray<string> | null;
  hasEstimationResponse?: boolean | null;
  hasEstimationResponseWith?: ReadonlyArray<EstimationResponseWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<EstimationResponseItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationResponseDetailWhereInput | null;
  or?: ReadonlyArray<EstimationResponseDetailWhereInput> | null;
  orderNumber?: any | null;
  orderNumberGT?: any | null;
  orderNumberGTE?: any | null;
  orderNumberIn?: ReadonlyArray<any> | null;
  orderNumberLT?: any | null;
  orderNumberLTE?: any | null;
  orderNumberNEQ?: any | null;
  orderNumberNotIn?: ReadonlyArray<any> | null;
  type?: EstimationResponseDetailType | null;
  typeIn?: ReadonlyArray<EstimationResponseDetailType> | null;
  typeNEQ?: EstimationResponseDetailType | null;
  typeNotIn?: ReadonlyArray<EstimationResponseDetailType> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationResponseItemWhereInput = {
  and?: ReadonlyArray<EstimationResponseItemWhereInput> | null;
  annualQuantity?: string | null;
  annualQuantityContains?: string | null;
  annualQuantityContainsFold?: string | null;
  annualQuantityEqualFold?: string | null;
  annualQuantityGT?: string | null;
  annualQuantityGTE?: string | null;
  annualQuantityHasPrefix?: string | null;
  annualQuantityHasSuffix?: string | null;
  annualQuantityIn?: ReadonlyArray<string> | null;
  annualQuantityIsNil?: boolean | null;
  annualQuantityLT?: string | null;
  annualQuantityLTE?: string | null;
  annualQuantityNEQ?: string | null;
  annualQuantityNotIn?: ReadonlyArray<string> | null;
  annualQuantityNotNil?: boolean | null;
  askingUnitPrice?: string | null;
  askingUnitPriceContains?: string | null;
  askingUnitPriceContainsFold?: string | null;
  askingUnitPriceEqualFold?: string | null;
  askingUnitPriceGT?: string | null;
  askingUnitPriceGTE?: string | null;
  askingUnitPriceHasPrefix?: string | null;
  askingUnitPriceHasSuffix?: string | null;
  askingUnitPriceIn?: ReadonlyArray<string> | null;
  askingUnitPriceIsNil?: boolean | null;
  askingUnitPriceLT?: string | null;
  askingUnitPriceLTE?: string | null;
  askingUnitPriceNEQ?: string | null;
  askingUnitPriceNotIn?: ReadonlyArray<string> | null;
  askingUnitPriceNotNil?: boolean | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationResponseDetailID?: string | null;
  estimationResponseDetailIDContains?: string | null;
  estimationResponseDetailIDContainsFold?: string | null;
  estimationResponseDetailIDEqualFold?: string | null;
  estimationResponseDetailIDGT?: string | null;
  estimationResponseDetailIDGTE?: string | null;
  estimationResponseDetailIDHasPrefix?: string | null;
  estimationResponseDetailIDHasSuffix?: string | null;
  estimationResponseDetailIDIn?: ReadonlyArray<string> | null;
  estimationResponseDetailIDLT?: string | null;
  estimationResponseDetailIDLTE?: string | null;
  estimationResponseDetailIDNEQ?: string | null;
  estimationResponseDetailIDNotIn?: ReadonlyArray<string> | null;
  hasDetail?: boolean | null;
  hasDetailWith?: ReadonlyArray<EstimationResponseDetailWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: EstimationResponseItemWhereInput | null;
  or?: ReadonlyArray<EstimationResponseItemWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type EstimationInternalAssigneeWhereInput = {
  and?: ReadonlyArray<EstimationInternalAssigneeWhereInput> | null;
  estimationID?: string | null;
  estimationIDContains?: string | null;
  estimationIDContainsFold?: string | null;
  estimationIDEqualFold?: string | null;
  estimationIDGT?: string | null;
  estimationIDGTE?: string | null;
  estimationIDHasPrefix?: string | null;
  estimationIDHasSuffix?: string | null;
  estimationIDIn?: ReadonlyArray<string> | null;
  estimationIDLT?: string | null;
  estimationIDLTE?: string | null;
  estimationIDNEQ?: string | null;
  estimationIDNotIn?: ReadonlyArray<string> | null;
  hasEstimation?: boolean | null;
  hasEstimationWith?: ReadonlyArray<EstimationWhereInput> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationInternalAssigneeWhereInput | null;
  or?: ReadonlyArray<EstimationInternalAssigneeWhereInput> | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type FreeeInvoiceWhereInput = {
  and?: ReadonlyArray<FreeeInvoiceWhereInput> | null;
  freeeInvoiceID?: any | null;
  freeeInvoiceIDGT?: any | null;
  freeeInvoiceIDGTE?: any | null;
  freeeInvoiceIDIn?: ReadonlyArray<any> | null;
  freeeInvoiceIDLT?: any | null;
  freeeInvoiceIDLTE?: any | null;
  freeeInvoiceIDNEQ?: any | null;
  freeeInvoiceIDNotIn?: ReadonlyArray<any> | null;
  hasInvoice?: boolean | null;
  hasInvoiceWith?: ReadonlyArray<InvoiceWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceID?: string | null;
  invoiceIDContains?: string | null;
  invoiceIDContainsFold?: string | null;
  invoiceIDEqualFold?: string | null;
  invoiceIDGT?: string | null;
  invoiceIDGTE?: string | null;
  invoiceIDHasPrefix?: string | null;
  invoiceIDHasSuffix?: string | null;
  invoiceIDIn?: ReadonlyArray<string> | null;
  invoiceIDLT?: string | null;
  invoiceIDLTE?: string | null;
  invoiceIDNEQ?: string | null;
  invoiceIDNotIn?: ReadonlyArray<string> | null;
  not?: FreeeInvoiceWhereInput | null;
  or?: ReadonlyArray<FreeeInvoiceWhereInput> | null;
};
export type InvoiceInternalMemoWhereInput = {
  and?: ReadonlyArray<InvoiceInternalMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasInvoice?: boolean | null;
  hasInvoiceWith?: ReadonlyArray<InvoiceWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceID?: string | null;
  invoiceIDContains?: string | null;
  invoiceIDContainsFold?: string | null;
  invoiceIDEqualFold?: string | null;
  invoiceIDGT?: string | null;
  invoiceIDGTE?: string | null;
  invoiceIDHasPrefix?: string | null;
  invoiceIDHasSuffix?: string | null;
  invoiceIDIn?: ReadonlyArray<string> | null;
  invoiceIDLT?: string | null;
  invoiceIDLTE?: string | null;
  invoiceIDNEQ?: string | null;
  invoiceIDNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceInternalMemoWhereInput | null;
  or?: ReadonlyArray<InvoiceInternalMemoWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type InvoiceItemWhereInput = {
  and?: ReadonlyArray<InvoiceItemWhereInput> | null;
  category?: ItemCategory | null;
  categoryIn?: ReadonlyArray<ItemCategory> | null;
  categoryIsNil?: boolean | null;
  categoryNEQ?: ItemCategory | null;
  categoryNotIn?: ReadonlyArray<ItemCategory> | null;
  categoryNotNil?: boolean | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDIsNil?: boolean | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  demandItemIDNotNil?: boolean | null;
  hasDemandItem?: boolean | null;
  hasDemandItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasInvoiceDetail?: boolean | null;
  hasInvoiceDetailWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceDetailID?: string | null;
  invoiceDetailIDContains?: string | null;
  invoiceDetailIDContainsFold?: string | null;
  invoiceDetailIDEqualFold?: string | null;
  invoiceDetailIDGT?: string | null;
  invoiceDetailIDGTE?: string | null;
  invoiceDetailIDHasPrefix?: string | null;
  invoiceDetailIDHasSuffix?: string | null;
  invoiceDetailIDIn?: ReadonlyArray<string> | null;
  invoiceDetailIDLT?: string | null;
  invoiceDetailIDLTE?: string | null;
  invoiceDetailIDNEQ?: string | null;
  invoiceDetailIDNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceItemWhereInput | null;
  or?: ReadonlyArray<InvoiceItemWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type InvoiceOnetimeCostWhereInput = {
  and?: ReadonlyArray<InvoiceOnetimeCostWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  hasInvoiceDetail?: boolean | null;
  hasInvoiceDetailWith?: ReadonlyArray<InvoiceDetailWhereInput> | null;
  hasTaxCategory?: boolean | null;
  hasTaxCategoryWith?: ReadonlyArray<TaxCategoryWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invoiceDetailID?: string | null;
  invoiceDetailIDContains?: string | null;
  invoiceDetailIDContainsFold?: string | null;
  invoiceDetailIDEqualFold?: string | null;
  invoiceDetailIDGT?: string | null;
  invoiceDetailIDGTE?: string | null;
  invoiceDetailIDHasPrefix?: string | null;
  invoiceDetailIDHasSuffix?: string | null;
  invoiceDetailIDIn?: ReadonlyArray<string> | null;
  invoiceDetailIDLT?: string | null;
  invoiceDetailIDLTE?: string | null;
  invoiceDetailIDNEQ?: string | null;
  invoiceDetailIDNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: InvoiceOnetimeCostWhereInput | null;
  note?: string | null;
  noteContains?: string | null;
  noteContainsFold?: string | null;
  noteEqualFold?: string | null;
  noteGT?: string | null;
  noteGTE?: string | null;
  noteHasPrefix?: string | null;
  noteHasSuffix?: string | null;
  noteIn?: ReadonlyArray<string> | null;
  noteLT?: string | null;
  noteLTE?: string | null;
  noteNEQ?: string | null;
  noteNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<InvoiceOnetimeCostWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  taxCategoryID?: string | null;
  taxCategoryIDContains?: string | null;
  taxCategoryIDContainsFold?: string | null;
  taxCategoryIDEqualFold?: string | null;
  taxCategoryIDGT?: string | null;
  taxCategoryIDGTE?: string | null;
  taxCategoryIDHasPrefix?: string | null;
  taxCategoryIDHasSuffix?: string | null;
  taxCategoryIDIn?: ReadonlyArray<string> | null;
  taxCategoryIDLT?: string | null;
  taxCategoryIDLTE?: string | null;
  taxCategoryIDNEQ?: string | null;
  taxCategoryIDNotIn?: ReadonlyArray<string> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandRecurringCostPriceWhereInput = {
  and?: ReadonlyArray<DemandRecurringCostPriceWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  demandRecurringCostID?: string | null;
  demandRecurringCostIDContains?: string | null;
  demandRecurringCostIDContainsFold?: string | null;
  demandRecurringCostIDEqualFold?: string | null;
  demandRecurringCostIDGT?: string | null;
  demandRecurringCostIDGTE?: string | null;
  demandRecurringCostIDHasPrefix?: string | null;
  demandRecurringCostIDHasSuffix?: string | null;
  demandRecurringCostIDIn?: ReadonlyArray<string> | null;
  demandRecurringCostIDLT?: string | null;
  demandRecurringCostIDLTE?: string | null;
  demandRecurringCostIDNEQ?: string | null;
  demandRecurringCostIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasRecurringCost?: boolean | null;
  hasRecurringCostWith?: ReadonlyArray<DemandRecurringCostWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandRecurringCostPriceWhereInput | null;
  or?: ReadonlyArray<DemandRecurringCostPriceWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type EstimationTextWhereInput = {
  and?: ReadonlyArray<EstimationTextWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  estimationDetailID?: string | null;
  estimationDetailIDContains?: string | null;
  estimationDetailIDContainsFold?: string | null;
  estimationDetailIDEqualFold?: string | null;
  estimationDetailIDGT?: string | null;
  estimationDetailIDGTE?: string | null;
  estimationDetailIDHasPrefix?: string | null;
  estimationDetailIDHasSuffix?: string | null;
  estimationDetailIDIn?: ReadonlyArray<string> | null;
  estimationDetailIDLT?: string | null;
  estimationDetailIDLTE?: string | null;
  estimationDetailIDNEQ?: string | null;
  estimationDetailIDNotIn?: ReadonlyArray<string> | null;
  hasEstimationDetail?: boolean | null;
  hasEstimationDetailWith?: ReadonlyArray<EstimationDetailWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: EstimationTextWhereInput | null;
  or?: ReadonlyArray<EstimationTextWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemFlexiblePackageWhereInput = {
  and?: ReadonlyArray<DemandItemFlexiblePackageWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  material?: string | null;
  materialContains?: string | null;
  materialContainsFold?: string | null;
  materialEqualFold?: string | null;
  materialGT?: string | null;
  materialGTE?: string | null;
  materialHasPrefix?: string | null;
  materialHasSuffix?: string | null;
  materialIn?: ReadonlyArray<string> | null;
  materialLT?: string | null;
  materialLTE?: string | null;
  materialNEQ?: string | null;
  materialNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemFlexiblePackageWhereInput | null;
  or?: ReadonlyArray<DemandItemFlexiblePackageWhereInput> | null;
  other?: string | null;
  otherContains?: string | null;
  otherContainsFold?: string | null;
  otherEqualFold?: string | null;
  otherGT?: string | null;
  otherGTE?: string | null;
  otherHasPrefix?: string | null;
  otherHasSuffix?: string | null;
  otherIn?: ReadonlyArray<string> | null;
  otherLT?: string | null;
  otherLTE?: string | null;
  otherNEQ?: string | null;
  otherNotIn?: ReadonlyArray<string> | null;
  printingColor?: string | null;
  printingColorContains?: string | null;
  printingColorContainsFold?: string | null;
  printingColorEqualFold?: string | null;
  printingColorGT?: string | null;
  printingColorGTE?: string | null;
  printingColorHasPrefix?: string | null;
  printingColorHasSuffix?: string | null;
  printingColorIn?: ReadonlyArray<string> | null;
  printingColorLT?: string | null;
  printingColorLTE?: string | null;
  printingColorNEQ?: string | null;
  printingColorNotIn?: ReadonlyArray<string> | null;
  processing?: string | null;
  processingContains?: string | null;
  processingContainsFold?: string | null;
  processingEqualFold?: string | null;
  processingGT?: string | null;
  processingGTE?: string | null;
  processingHasPrefix?: string | null;
  processingHasSuffix?: string | null;
  processingIn?: ReadonlyArray<string> | null;
  processingLT?: string | null;
  processingLTE?: string | null;
  processingNEQ?: string | null;
  processingNotIn?: ReadonlyArray<string> | null;
  size?: string | null;
  sizeContains?: string | null;
  sizeContainsFold?: string | null;
  sizeEqualFold?: string | null;
  sizeGT?: string | null;
  sizeGTE?: string | null;
  sizeHasPrefix?: string | null;
  sizeHasSuffix?: string | null;
  sizeIn?: ReadonlyArray<string> | null;
  sizeLT?: string | null;
  sizeLTE?: string | null;
  sizeNEQ?: string | null;
  sizeNotIn?: ReadonlyArray<string> | null;
  type?: string | null;
  typeContains?: string | null;
  typeContainsFold?: string | null;
  typeEqualFold?: string | null;
  typeGT?: string | null;
  typeGTE?: string | null;
  typeHasPrefix?: string | null;
  typeHasSuffix?: string | null;
  typeIn?: ReadonlyArray<string> | null;
  typeLT?: string | null;
  typeLTE?: string | null;
  typeNEQ?: string | null;
  typeNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemGiftBoxWhereInput = {
  and?: ReadonlyArray<DemandItemGiftBoxWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemGiftBoxWhereInput | null;
  or?: ReadonlyArray<DemandItemGiftBoxWhereInput> | null;
  other?: string | null;
  otherContains?: string | null;
  otherContainsFold?: string | null;
  otherEqualFold?: string | null;
  otherGT?: string | null;
  otherGTE?: string | null;
  otherHasPrefix?: string | null;
  otherHasSuffix?: string | null;
  otherIn?: ReadonlyArray<string> | null;
  otherLT?: string | null;
  otherLTE?: string | null;
  otherNEQ?: string | null;
  otherNotIn?: ReadonlyArray<string> | null;
  paperType?: string | null;
  paperTypeContains?: string | null;
  paperTypeContainsFold?: string | null;
  paperTypeEqualFold?: string | null;
  paperTypeGT?: string | null;
  paperTypeGTE?: string | null;
  paperTypeHasPrefix?: string | null;
  paperTypeHasSuffix?: string | null;
  paperTypeIn?: ReadonlyArray<string> | null;
  paperTypeLT?: string | null;
  paperTypeLTE?: string | null;
  paperTypeNEQ?: string | null;
  paperTypeNotIn?: ReadonlyArray<string> | null;
  printingColor?: string | null;
  printingColorContains?: string | null;
  printingColorContainsFold?: string | null;
  printingColorEqualFold?: string | null;
  printingColorGT?: string | null;
  printingColorGTE?: string | null;
  printingColorHasPrefix?: string | null;
  printingColorHasSuffix?: string | null;
  printingColorIn?: ReadonlyArray<string> | null;
  printingColorLT?: string | null;
  printingColorLTE?: string | null;
  printingColorNEQ?: string | null;
  printingColorNotIn?: ReadonlyArray<string> | null;
  processing?: string | null;
  processingContains?: string | null;
  processingContainsFold?: string | null;
  processingEqualFold?: string | null;
  processingGT?: string | null;
  processingGTE?: string | null;
  processingHasPrefix?: string | null;
  processingHasSuffix?: string | null;
  processingIn?: ReadonlyArray<string> | null;
  processingLT?: string | null;
  processingLTE?: string | null;
  processingNEQ?: string | null;
  processingNotIn?: ReadonlyArray<string> | null;
  size?: string | null;
  sizeContains?: string | null;
  sizeContainsFold?: string | null;
  sizeEqualFold?: string | null;
  sizeGT?: string | null;
  sizeGTE?: string | null;
  sizeHasPrefix?: string | null;
  sizeHasSuffix?: string | null;
  sizeIn?: ReadonlyArray<string> | null;
  sizeLT?: string | null;
  sizeLTE?: string | null;
  sizeNEQ?: string | null;
  sizeNotIn?: ReadonlyArray<string> | null;
  type?: string | null;
  typeContains?: string | null;
  typeContainsFold?: string | null;
  typeEqualFold?: string | null;
  typeGT?: string | null;
  typeGTE?: string | null;
  typeHasPrefix?: string | null;
  typeHasSuffix?: string | null;
  typeIn?: ReadonlyArray<string> | null;
  typeLT?: string | null;
  typeLTE?: string | null;
  typeNEQ?: string | null;
  typeNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemImageWhereInput = {
  and?: ReadonlyArray<DemandItemImageWhereInput> | null;
  byteSize?: any | null;
  byteSizeGT?: any | null;
  byteSizeGTE?: any | null;
  byteSizeIn?: ReadonlyArray<any> | null;
  byteSizeLT?: any | null;
  byteSizeLTE?: any | null;
  byteSizeNEQ?: any | null;
  byteSizeNotIn?: ReadonlyArray<any> | null;
  contentType?: string | null;
  contentTypeContains?: string | null;
  contentTypeContainsFold?: string | null;
  contentTypeEqualFold?: string | null;
  contentTypeGT?: string | null;
  contentTypeGTE?: string | null;
  contentTypeHasPrefix?: string | null;
  contentTypeHasSuffix?: string | null;
  contentTypeIn?: ReadonlyArray<string> | null;
  contentTypeLT?: string | null;
  contentTypeLTE?: string | null;
  contentTypeNEQ?: string | null;
  contentTypeNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  displayOrder?: any | null;
  displayOrderGT?: any | null;
  displayOrderGTE?: any | null;
  displayOrderIn?: ReadonlyArray<any> | null;
  displayOrderLT?: any | null;
  displayOrderLTE?: any | null;
  displayOrderNEQ?: any | null;
  displayOrderNotIn?: ReadonlyArray<any> | null;
  fileName?: string | null;
  fileNameContains?: string | null;
  fileNameContainsFold?: string | null;
  fileNameEqualFold?: string | null;
  fileNameGT?: string | null;
  fileNameGTE?: string | null;
  fileNameHasPrefix?: string | null;
  fileNameHasSuffix?: string | null;
  fileNameIn?: ReadonlyArray<string> | null;
  fileNameLT?: string | null;
  fileNameLTE?: string | null;
  fileNameNEQ?: string | null;
  fileNameNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemImageWhereInput | null;
  objectName?: string | null;
  objectNameContains?: string | null;
  objectNameContainsFold?: string | null;
  objectNameEqualFold?: string | null;
  objectNameGT?: string | null;
  objectNameGTE?: string | null;
  objectNameHasPrefix?: string | null;
  objectNameHasSuffix?: string | null;
  objectNameIn?: ReadonlyArray<string> | null;
  objectNameLT?: string | null;
  objectNameLTE?: string | null;
  objectNameNEQ?: string | null;
  objectNameNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<DemandItemImageWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemInternalMemoWhereInput = {
  and?: ReadonlyArray<DemandItemInternalMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemInternalMemoWhereInput | null;
  or?: ReadonlyArray<DemandItemInternalMemoWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type DemandItemOtherWhereInput = {
  and?: ReadonlyArray<DemandItemOtherWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemOtherWhereInput | null;
  or?: ReadonlyArray<DemandItemOtherWhereInput> | null;
  specText?: string | null;
  specTextContains?: string | null;
  specTextContainsFold?: string | null;
  specTextEqualFold?: string | null;
  specTextGT?: string | null;
  specTextGTE?: string | null;
  specTextHasPrefix?: string | null;
  specTextHasSuffix?: string | null;
  specTextIn?: ReadonlyArray<string> | null;
  specTextLT?: string | null;
  specTextLTE?: string | null;
  specTextNEQ?: string | null;
  specTextNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemPaperBagWhereInput = {
  and?: ReadonlyArray<DemandItemPaperBagWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  handle?: string | null;
  handleContains?: string | null;
  handleContainsFold?: string | null;
  handleEqualFold?: string | null;
  handleGT?: string | null;
  handleGTE?: string | null;
  handleHasPrefix?: string | null;
  handleHasSuffix?: string | null;
  handleIn?: ReadonlyArray<string> | null;
  handleLT?: string | null;
  handleLTE?: string | null;
  handleNEQ?: string | null;
  handleNotIn?: ReadonlyArray<string> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemPaperBagWhereInput | null;
  or?: ReadonlyArray<DemandItemPaperBagWhereInput> | null;
  other?: string | null;
  otherContains?: string | null;
  otherContainsFold?: string | null;
  otherEqualFold?: string | null;
  otherGT?: string | null;
  otherGTE?: string | null;
  otherHasPrefix?: string | null;
  otherHasSuffix?: string | null;
  otherIn?: ReadonlyArray<string> | null;
  otherLT?: string | null;
  otherLTE?: string | null;
  otherNEQ?: string | null;
  otherNotIn?: ReadonlyArray<string> | null;
  paperType?: string | null;
  paperTypeContains?: string | null;
  paperTypeContainsFold?: string | null;
  paperTypeEqualFold?: string | null;
  paperTypeGT?: string | null;
  paperTypeGTE?: string | null;
  paperTypeHasPrefix?: string | null;
  paperTypeHasSuffix?: string | null;
  paperTypeIn?: ReadonlyArray<string> | null;
  paperTypeLT?: string | null;
  paperTypeLTE?: string | null;
  paperTypeNEQ?: string | null;
  paperTypeNotIn?: ReadonlyArray<string> | null;
  printingColor?: string | null;
  printingColorContains?: string | null;
  printingColorContainsFold?: string | null;
  printingColorEqualFold?: string | null;
  printingColorGT?: string | null;
  printingColorGTE?: string | null;
  printingColorHasPrefix?: string | null;
  printingColorHasSuffix?: string | null;
  printingColorIn?: ReadonlyArray<string> | null;
  printingColorLT?: string | null;
  printingColorLTE?: string | null;
  printingColorNEQ?: string | null;
  printingColorNotIn?: ReadonlyArray<string> | null;
  processing?: string | null;
  processingContains?: string | null;
  processingContainsFold?: string | null;
  processingEqualFold?: string | null;
  processingGT?: string | null;
  processingGTE?: string | null;
  processingHasPrefix?: string | null;
  processingHasSuffix?: string | null;
  processingIn?: ReadonlyArray<string> | null;
  processingLT?: string | null;
  processingLTE?: string | null;
  processingNEQ?: string | null;
  processingNotIn?: ReadonlyArray<string> | null;
  size?: string | null;
  sizeContains?: string | null;
  sizeContainsFold?: string | null;
  sizeEqualFold?: string | null;
  sizeGT?: string | null;
  sizeGTE?: string | null;
  sizeHasPrefix?: string | null;
  sizeHasSuffix?: string | null;
  sizeIn?: ReadonlyArray<string> | null;
  sizeLT?: string | null;
  sizeLTE?: string | null;
  sizeNEQ?: string | null;
  sizeNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
};
export type DemandItemPriceWhereInput = {
  and?: ReadonlyArray<DemandItemPriceWhereInput> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  demandItemID?: string | null;
  demandItemIDContains?: string | null;
  demandItemIDContainsFold?: string | null;
  demandItemIDEqualFold?: string | null;
  demandItemIDGT?: string | null;
  demandItemIDGTE?: string | null;
  demandItemIDHasPrefix?: string | null;
  demandItemIDHasSuffix?: string | null;
  demandItemIDIn?: ReadonlyArray<string> | null;
  demandItemIDLT?: string | null;
  demandItemIDLTE?: string | null;
  demandItemIDNEQ?: string | null;
  demandItemIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasItem?: boolean | null;
  hasItemWith?: ReadonlyArray<DemandItemWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandItemPriceWhereInput | null;
  or?: ReadonlyArray<DemandItemPriceWhereInput> | null;
  quantity?: any | null;
  quantityGT?: any | null;
  quantityGTE?: any | null;
  quantityIn?: ReadonlyArray<any> | null;
  quantityLT?: any | null;
  quantityLTE?: any | null;
  quantityNEQ?: any | null;
  quantityNotIn?: ReadonlyArray<any> | null;
  unitPrice?: number | null;
  unitPriceGT?: number | null;
  unitPriceGTE?: number | null;
  unitPriceIn?: ReadonlyArray<number> | null;
  unitPriceLT?: number | null;
  unitPriceLTE?: number | null;
  unitPriceNEQ?: number | null;
  unitPriceNotIn?: ReadonlyArray<number> | null;
  unitSellingPrice?: number | null;
  unitSellingPriceGT?: number | null;
  unitSellingPriceGTE?: number | null;
  unitSellingPriceIn?: ReadonlyArray<number> | null;
  unitSellingPriceLT?: number | null;
  unitSellingPriceLTE?: number | null;
  unitSellingPriceNEQ?: number | null;
  unitSellingPriceNotIn?: ReadonlyArray<number> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type DemandInternalMemoWhereInput = {
  and?: ReadonlyArray<DemandInternalMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  demandID?: string | null;
  demandIDContains?: string | null;
  demandIDContainsFold?: string | null;
  demandIDEqualFold?: string | null;
  demandIDGT?: string | null;
  demandIDGTE?: string | null;
  demandIDHasPrefix?: string | null;
  demandIDHasSuffix?: string | null;
  demandIDIn?: ReadonlyArray<string> | null;
  demandIDLT?: string | null;
  demandIDLTE?: string | null;
  demandIDNEQ?: string | null;
  demandIDNotIn?: ReadonlyArray<string> | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasDemand?: boolean | null;
  hasDemandWith?: ReadonlyArray<CompanyWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: DemandInternalMemoWhereInput | null;
  or?: ReadonlyArray<DemandInternalMemoWhereInput> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type SalesOrderInternalAssigneeWhereInput = {
  and?: ReadonlyArray<SalesOrderInternalAssigneeWhereInput> | null;
  hasSalesOrder?: boolean | null;
  hasSalesOrderWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderInternalAssigneeWhereInput | null;
  or?: ReadonlyArray<SalesOrderInternalAssigneeWhereInput> | null;
  salesOrderID?: string | null;
  salesOrderIDContains?: string | null;
  salesOrderIDContainsFold?: string | null;
  salesOrderIDEqualFold?: string | null;
  salesOrderIDGT?: string | null;
  salesOrderIDGTE?: string | null;
  salesOrderIDHasPrefix?: string | null;
  salesOrderIDHasSuffix?: string | null;
  salesOrderIDIn?: ReadonlyArray<string> | null;
  salesOrderIDLT?: string | null;
  salesOrderIDLTE?: string | null;
  salesOrderIDNEQ?: string | null;
  salesOrderIDNotIn?: ReadonlyArray<string> | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type SalesOrderInternalMemoWhereInput = {
  and?: ReadonlyArray<SalesOrderInternalMemoWhereInput> | null;
  body?: string | null;
  bodyContains?: string | null;
  bodyContainsFold?: string | null;
  bodyEqualFold?: string | null;
  bodyGT?: string | null;
  bodyGTE?: string | null;
  bodyHasPrefix?: string | null;
  bodyHasSuffix?: string | null;
  bodyIn?: ReadonlyArray<string> | null;
  bodyLT?: string | null;
  bodyLTE?: string | null;
  bodyNEQ?: string | null;
  bodyNotIn?: ReadonlyArray<string> | null;
  createdAt?: any | null;
  createdAtGT?: any | null;
  createdAtGTE?: any | null;
  createdAtIn?: ReadonlyArray<any> | null;
  createdAtLT?: any | null;
  createdAtLTE?: any | null;
  createdAtNEQ?: any | null;
  createdAtNotIn?: ReadonlyArray<any> | null;
  creatorID?: string | null;
  creatorIDContains?: string | null;
  creatorIDContainsFold?: string | null;
  creatorIDEqualFold?: string | null;
  creatorIDGT?: string | null;
  creatorIDGTE?: string | null;
  creatorIDHasPrefix?: string | null;
  creatorIDHasSuffix?: string | null;
  creatorIDIn?: ReadonlyArray<string> | null;
  creatorIDLT?: string | null;
  creatorIDLTE?: string | null;
  creatorIDNEQ?: string | null;
  creatorIDNotIn?: ReadonlyArray<string> | null;
  deletedAt?: any | null;
  deletedAtGT?: any | null;
  deletedAtGTE?: any | null;
  deletedAtIn?: ReadonlyArray<any> | null;
  deletedAtIsNil?: boolean | null;
  deletedAtLT?: any | null;
  deletedAtLTE?: any | null;
  deletedAtNEQ?: any | null;
  deletedAtNotIn?: ReadonlyArray<any> | null;
  deletedAtNotNil?: boolean | null;
  hasCreatedBy?: boolean | null;
  hasCreatedByWith?: ReadonlyArray<UserWhereInput> | null;
  hasSalesOrder?: boolean | null;
  hasSalesOrderWith?: ReadonlyArray<SalesOrderWhereInput> | null;
  hasUpdatedBy?: boolean | null;
  hasUpdatedByWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: SalesOrderInternalMemoWhereInput | null;
  or?: ReadonlyArray<SalesOrderInternalMemoWhereInput> | null;
  salesOrderID?: string | null;
  salesOrderIDContains?: string | null;
  salesOrderIDContainsFold?: string | null;
  salesOrderIDEqualFold?: string | null;
  salesOrderIDGT?: string | null;
  salesOrderIDGTE?: string | null;
  salesOrderIDHasPrefix?: string | null;
  salesOrderIDHasSuffix?: string | null;
  salesOrderIDIn?: ReadonlyArray<string> | null;
  salesOrderIDLT?: string | null;
  salesOrderIDLTE?: string | null;
  salesOrderIDNEQ?: string | null;
  salesOrderIDNotIn?: ReadonlyArray<string> | null;
  updatedAt?: any | null;
  updatedAtGT?: any | null;
  updatedAtGTE?: any | null;
  updatedAtIn?: ReadonlyArray<any> | null;
  updatedAtLT?: any | null;
  updatedAtLTE?: any | null;
  updatedAtNEQ?: any | null;
  updatedAtNotIn?: ReadonlyArray<any> | null;
  updaterID?: string | null;
  updaterIDContains?: string | null;
  updaterIDContainsFold?: string | null;
  updaterIDEqualFold?: string | null;
  updaterIDGT?: string | null;
  updaterIDGTE?: string | null;
  updaterIDHasPrefix?: string | null;
  updaterIDHasSuffix?: string | null;
  updaterIDIn?: ReadonlyArray<string> | null;
  updaterIDLT?: string | null;
  updaterIDLTE?: string | null;
  updaterIDNEQ?: string | null;
  updaterIDNotIn?: ReadonlyArray<string> | null;
};
export type salesOrderFragmentRefetchQuery$variables = {
  after?: any | null;
  count?: number | null;
  direction?: OrderDirection | null;
  field?: SalesOrderOrderField | null;
  where?: SalesOrderWhereInput | null;
};
export type salesOrderFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"salesOrderFragment">;
};
export type salesOrderFragmentRefetchQuery = {
  response: salesOrderFragmentRefetchQuery$data;
  variables: salesOrderFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": "DESC",
    "kind": "LocalArgument",
    "name": "direction"
  },
  {
    "defaultValue": "CREATED_AT",
    "kind": "LocalArgument",
    "name": "field"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "direction",
  "variableName": "direction"
},
v3 = {
  "kind": "Variable",
  "name": "field",
  "variableName": "field"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "fields": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "orderBy"
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v8 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "salesOrderFragmentRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "salesOrderFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "salesOrderFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "SalesOrderConnection",
        "kind": "LinkedField",
        "name": "salesOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transactionID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "receivedDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "where",
                                    "value": {
                                      "isValid": true
                                    }
                                  }
                                ],
                                "concreteType": "SalesOrderDetailDeliveryEventConnection",
                                "kind": "LinkedField",
                                "name": "deliveryEvents",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrderDetailDeliveryEventEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SalesOrderDetailDeliveryEvent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fixedDeliveryDate",
                                            "storageKey": null
                                          },
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Estimation",
                    "kind": "LinkedField",
                    "name": "estimations",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationResponse",
                        "kind": "LinkedField",
                        "name": "estimationResponse",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationRequestAssignee",
                            "kind": "LinkedField",
                            "name": "assignee",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationRequest",
                                "kind": "LinkedField",
                                "name": "estimationRequest",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Order",
                    "kind": "LinkedField",
                    "name": "orders",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderInternalAssignee",
                    "kind": "LinkedField",
                    "name": "internalAssignees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "salesOrderFragment_salesOrders",
        "kind": "LinkedHandle",
        "name": "salesOrders"
      }
    ]
  },
  "params": {
    "cacheID": "31c8e21e467ced6eb046f089d9b07569",
    "id": null,
    "metadata": {},
    "name": "salesOrderFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query salesOrderFragmentRefetchQuery(\n  $after: Cursor\n  $count: Int = 100\n  $direction: OrderDirection = DESC\n  $field: SalesOrderOrderField = CREATED_AT\n  $where: SalesOrderWhereInput = null\n) {\n  ...salesOrderFragment_1WQNTv\n}\n\nfragment SalesOrderTableRowFragment on SalesOrder {\n  id\n  transactionID\n  title\n  demand {\n    id\n    name\n  }\n  supplier {\n    id\n    name\n  }\n  receivedDate\n  details {\n    edges {\n      node {\n        deliveryEvents(where: {isValid: true}) {\n          edges {\n            node {\n              fixedDeliveryDate\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n  estimations {\n    id\n    estimationResponse {\n      id\n      assignee {\n        id\n        estimationRequest {\n          id\n        }\n      }\n    }\n  }\n  orders {\n    id\n  }\n  internalAssignees {\n    user {\n      id\n      profile {\n        lastName\n        firstName\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment salesOrderFragment_1WQNTv on Query {\n  salesOrders(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) {\n    edges {\n      cursor\n      node {\n        ...SalesOrderTableRowFragment\n        id\n        __typename\n      }\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "51271a40926bea0f5643273badc41429";

export default node;
