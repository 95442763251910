import { FormControl, HStack, VStack } from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';
import { SpecFormLabel } from './SpecFormLabel';
import { SpecInput } from './SpecInput';

export const GiftBoxSpecForm = ({
  index,
}: {
  index: number;
}) => {
  const form = useFormContext<EstimationFormType>();
  const { errors } = form.formState;

  return (
    <VStack>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>サイズ</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.size`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.size`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>形状</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.type`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.type`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>用紙</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.paperType`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.paperType`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>印刷</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.printingColor`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.printingColor`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>加工</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.processing`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.processing`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>その他</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.giftBox.other`)} />
            <ErrorMessage name={`details.${index}.spec.giftBox.other`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
    </VStack>
  );
};
