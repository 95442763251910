import { Input } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';

export const SpecInput = forwardRef<
  HTMLInputElement,
  ReturnType<UseFormRegister<EstimationFormType>>
>((props, ref) => {
  return <Input ref={ref} size="xs" px={1} py={0.5} type="text" fontSize="sm" {...props} />;
});
