import { FormControl, HStack, VStack } from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';
import { SpecFormLabel } from './SpecFormLabel';
import { SpecInput } from './SpecInput';

export const CardboardSpecForm = ({
  index,
}: {
  index: number;
}) => {
  const form = useFormContext<EstimationFormType>();
  const { errors } = form.formState;

  return (
    <VStack>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>サイズ</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.size`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.size`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>形状</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.type`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.type`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>構成</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.material`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.material`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>段厚</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.thickness`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.thickness`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>印刷</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.printingColor`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.printingColor`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>加工</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.processing`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.processing`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>その他</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.cardboard.other`)} />
            <ErrorMessage name={`details.${index}.spec.cardboard.other`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
    </VStack>
  );
};
