import { HStack, VStack } from '@chakra-ui/react';
import { EstimationDetailType } from 'features/estimations/form';
import { SpecLabel } from './SpecLabel';
import { SpecValue } from './SpecValue';

export const EstimationConfirmOtherPreview = ({ detail }: { detail: EstimationDetailType }) => {
  const { other } = detail.spec;
  if (!other || !Object.values(other).some(Boolean)) {
    return null;
  }

  return (
    <VStack borderLeft="2px solid" borderColor="gray.200" pl={3} mt={2} ml={4} spacing={1}>
      <HStack spacing={1} alignItems="flex-start" w="100%">
        <SpecLabel>その他</SpecLabel>
        <SpecValue>{detail.spec.other?.specText}</SpecValue>
      </HStack>
    </VStack>
  );
};
