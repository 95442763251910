import { Box } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateDetailAmount } from 'utils/priceCalculation';

type Props = {
  index: number;
};

export const EstimationFormItemAmount = ({ index }: Props) => {
  const form = useFormContext<EstimationFormType>();
  const detail = useWatch({ name: `details.${index}`, control: form.control });
  const amount = calculateDetailAmount({
    quantity: detail?.quantity || 0,
    price: detail?.unitSellingPrice || 0,
  });

  return (
    <Box
      bgColor="gray.50"
      px={4}
      py={2}
      minW="120px"
      height="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      {amount.toLocaleString()}
    </Box>
  );
};
