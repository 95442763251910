import { isDocumentDisplayStatusShown } from '../models';
import { EstimationDetailType } from './schema';

export const useEstimationDetailLogic = () => {
  const filterByDocumentDisplayStatusShown = (details: EstimationDetailType[]) => {
    return details.filter((detail) => isDocumentDisplayStatusShown(detail.documentDisplayStatus));
  };

  const deduplicateAdjacentNames = (objects: EstimationDetailType[]): EstimationDetailType[] => {
    if (objects.length === 0) return [];

    // 最初の要素をコピーして新しい配列に追加
    const result: EstimationDetailType[] = [
      { ...objects[0] }, // 浅いコピー
    ];

    for (let i = 1; i < objects.length; i++) {
      const prev = objects[i - 1];
      const curr = objects[i];

      // 現在の要素をコピー
      const newElement: EstimationDetailType = { ...curr };

      // 前の要素と現在の要素の name, category, spec を比較
      if (
        prev.name === curr.name &&
        prev.category === curr.category &&
        JSON.stringify(prev.spec) === JSON.stringify(curr.spec)
      ) {
        newElement.name = '同上';
      }

      result.push(newElement);
    }

    return result;
  };

  return {
    filterByDocumentDisplayStatusShown,
    deduplicateAdjacentNames,
  };
};
