import { Button, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { ZodGrossMarginRatio, grossMarginRatioSchema } from 'features/estimation/zod';
import { EstimationFormType } from 'features/estimations/form';
import { toast } from 'lib/toast';
import { useForm, useFormContext } from 'react-hook-form';
import { calculateGrossProfitUnitPrice } from 'utils/priceCalculation';

type Props = {
  grossMarginRatio?: number;
};

export const EstimationFormGrossMarginRatioInput = ({ grossMarginRatio }: Props) => {
  const estimationForm = useFormContext<EstimationFormType>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ZodGrossMarginRatio>({
    resolver: zodResolver(grossMarginRatioSchema),
    defaultValues: {
      grossMarginRatio,
    },
  });

  const handleGrossMarginRatioSubmit = handleSubmit((data) => {
    if (Number.isNaN(data.grossMarginRatio)) return;

    const details = estimationForm.getValues('details');
    const newDetails = details.map((detail) => {
      if (!detail.unitPrice) {
        return detail;
      }
      const unitPrice = detail.unitPrice;
      const unitSellingPrice = calculateGrossProfitUnitPrice({
        unitPrice,
        grossProfitMargin: data.grossMarginRatio / 100,
      });
      return {
        ...detail,
        unitSellingPrice,
      };
    });

    estimationForm.setValue('details', newDetails);
    toast({
      title: '粗利率を適用しました',
      status: 'success',
    });
  });

  return (
    <FormControl isInvalid={!!errors?.grossMarginRatio} w="auto">
      <VStack align="stretch" spacing={2}>
        <HStack
          align="center"
          borderRadius="md"
          backgroundColor="gray.50"
          py={2}
          px={4}
          spacing={4}
        >
          <Text fontSize="sm" fontWeight="bold">
            顧客単価の一括変更
          </Text>
          <HStack spacing={1}>
            <Text fontSize="sm">仕入れ単価 ×</Text>
            <ConfigurableNumberInput
              {...register(`grossMarginRatio`, { valueAsNumber: true })}
              w="5rem"
              size="sm"
              borderRadius="md"
              backgroundColor="white"
              type="decimal"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleGrossMarginRatioSubmit();
                }
              }}
            />
            <Text fontSize="sm">%</Text>
          </HStack>
          <Button colorScheme="blue" size="sm" onClick={handleGrossMarginRatioSubmit}>
            適用
          </Button>
        </HStack>
        <ErrorMessage errors={errors} name="grossMarginRatio" />
      </VStack>
    </FormControl>
  );
};
