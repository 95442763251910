import { Stack, Text } from '@chakra-ui/react';
import { Picture } from '@icon-park/react';

export const DemandItemDetailBlockNoImageThumbnail = () => {
  return (
    <Stack
      width="108px"
      height="108px"
      bgColor="gray.50"
      color="gray.400"
      rounded="md"
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <Picture size="24" />
      <Text fontSize="sm">NO IMAGE</Text>
    </Stack>
  );
};
