import { Link as RouterLink, useLocation } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

export const RouteLinkWithReferrer = ({ children, ...props }: LinkProps) => {
  const location = useLocation();

  return (
    <RouterLink
      color="blue.500"
      state={{
        pathname: location.pathname,
        search: location.search,
      }}
      {...props}
    >
      {children}
    </RouterLink>
  );
};
