import { IconButton } from '@chakra-ui/react';
import { Eyes, PreviewClose } from '@icon-park/react';
import { EstimationFormType, useEstimationDetailLogic } from 'features/estimations/form';
import {
  ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS,
  isDocumentDisplayStatusHidden,
  isDocumentDisplayStatusShown,
} from 'features/estimations/models';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  index: number;
};

export const EstimationFormItemDisplayStatus = ({ index }: Props) => {
  const form = useFormContext<EstimationFormType>();
  const { setValue } = form;
  const details = useWatch({ name: `details`, control: form.control });
  const detail = useWatch({ name: `details.${index}`, control: form.control });

  const { filterByDocumentDisplayStatusShown } = useEstimationDetailLogic();

  const isDisabledDocumentDisplayStatusButton = () =>
    filterByDocumentDisplayStatusShown(details).length === 1 &&
    isDocumentDisplayStatusShown(detail.documentDisplayStatus);

  return (
    <IconButton
      variant="solid"
      isRound
      aria-label="shown"
      bgColor={isDocumentDisplayStatusShown(detail.documentDisplayStatus) ? 'blue.100' : ''}
      _hover={{
        bgColor: !isDisabledDocumentDisplayStatusButton()
          ? isDocumentDisplayStatusShown(detail.documentDisplayStatus)
            ? 'blue.200'
            : 'gray.200'
          : '',
      }}
      icon={
        isDocumentDisplayStatusShown(detail.documentDisplayStatus) ? (
          <Eyes color="blue.700" />
        ) : (
          <PreviewClose />
        )
      }
      onClick={() => {
        const currentValue = detail.documentDisplayStatus;

        if (isDocumentDisplayStatusShown(currentValue)) {
          setValue(
            `details.${index}.documentDisplayStatus`,
            ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Hidden,
          );
        } else if (isDocumentDisplayStatusHidden(currentValue)) {
          setValue(
            `details.${index}.documentDisplayStatus`,
            ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
          );
        }
      }}
      isDisabled={isDisabledDocumentDisplayStatusButton()}
    />
  );
};
