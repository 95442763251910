/**
 * @generated SignedSource<<66cb0a2077dad7503099e4763ff4b269>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationFormLineItemsWrapperFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EstimationFormLineItemsFragment">;
  readonly " $fragmentType": "EstimationFormLineItemsWrapperFragment";
};
export type EstimationFormLineItemsWrapperFragment$key = {
  readonly " $data"?: EstimationFormLineItemsWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationFormLineItemsWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationFormLineItemsWrapperFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EstimationFormLineItemsFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a9d9cef6610fd9968088e9d21a716e0b";

export default node;
