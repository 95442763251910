import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import {
  getEstimationWhere,
  useEstimationFilter,
} from 'features/estimation/hooks/useEstimationFilter';
import { useQueryParams } from 'features/estimation/hooks/useEstimatoinQueryPrams';
import { EstimationsTableRow } from 'features/estimations/components/EstimationsTableRow';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserSelectBox } from 'features/user/components/UserSelectBox';
import { estimationsFragment$key } from 'gql/__generated__/estimationsFragment.graphql';
import { estimationsPageQuery } from 'gql/__generated__/estimationsPageQuery.graphql';
import { useEffect } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Link, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const EstimationsPageQuery = graphql`
  query estimationsPageQuery ($where: EstimationWhereInput, $demandID: ID!, $isDemandSkip: Boolean!, $supplierID: ID!, $isSupplierSkip: Boolean!) {
    ...estimationsFragment @arguments(where: $where)
    companies(where: {id: $demandID}) @skip(if: $isDemandSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
    suppliers(where: {id: $supplierID}) @skip(if: $isSupplierSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const EstimationsFragment = graphql`
  fragment estimationsFragment on Query
    @refetchable(queryName: "estimationsFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "EstimationOrderField", defaultValue: CREATED_AT }
      where: {type: "EstimationWhereInput", defaultValue: null}
    )
  {
    estimations(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "estimationsFragment_estimations") {
      edges {
        cursor
        node {
          ...EstimationsTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export function EstimationsPage() {
  const { updateQueryParams } = useQueryParams();
  const [searchParams] = useSearchParams();
  const demandId = searchParams.get('demand');
  const supplierId = searchParams.get('supplier');
  const assigneeId = searchParams.get('assignee');

  const query = useLazyLoadQuery<estimationsPageQuery>(
    EstimationsPageQuery,
    {
      ...getEstimationWhere({
        userId: assigneeId || '',
        demandId: demandId || '',
        supplierId: supplierId || '',
      }),
      demandID: demandId || '',
      isDemandSkip: !demandId,
      supplierID: supplierId || '',
      isSupplierSkip: !supplierId,
    },
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    EstimationsFragment,
    query as estimationsFragment$key,
  );
  const {
    onClearDemandInput,
    onClearSupplierInput,
    onChangeUser,
    onChangeDemand,
    onChangeSupplier,
    isPending,
  } = useEstimationFilter(refetch);

  const handleClickMore = () => loadNext(100);

  const demand = (query.companies?.edges || [])[0]?.node;
  const supplier = (query.suppliers?.edges || [])[0]?.node;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateQueryParams({ demand: demandId || '', assignee: assigneeId || '' });
  }, [demandId, assigneeId]);

  return (
    <>
      <Button colorScheme="blue" width="208px" as={Link} to={paths.estimations.new.url()}>
        見積書作成
      </Button>
      <HStack mt="1.5rem">
        <FormControl w="220px">
          <FormLabel mb={1}>デマンド</FormLabel>
          <CompanyComboBox
            onChangeSelected={onChangeDemand}
            defaultSelectedItem={demand || { id: '', name: '' }}
            onClearInput={onClearDemandInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>サプライヤー</FormLabel>
          <SupplierComboBox
            onChangeSelected={onChangeSupplier}
            defaultSelectedItem={supplier || { id: '', name: '' }}
            onClearInput={onClearSupplierInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>担当者</FormLabel>
          <UserSelectBox
            value={assigneeId || ''}
            enableValue={true}
            onChange={(e) => onChangeUser(e.target.value)}
          />
        </FormControl>
      </HStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="12%">件名</Th>
                <Th w="12%">デマンド</Th>
                <Th w="12%">サプライヤー</Th>
                <Th w="11%">作成日</Th>
                <Th w="9%">担当者</Th>
                <Th w="7%" textAlign="center">
                  見積依頼
                </Th>
                <Th w="7%" textAlign="center">
                  発注請書
                </Th>
                <Th w="7%" textAlign="center">
                  発注書
                </Th>
                <Th w="6%" />
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.estimations.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <EstimationsTableRow
                      key={`estimation-${edge?.cursor}-${i}`}
                      queryRef={edge?.node}
                    />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.estimations.totalCount}
              currentLength={data.estimations.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
