import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandFormDataType, demandFormSchema } from 'features/demand/form';
import { useForm } from 'react-hook-form';

type FormType = 'new' | 'edit';

const getSubmitButtonLabel = (type: FormType) => {
  switch (type) {
    case 'new':
      return '追加';
    case 'edit':
      return '更新';
  }
};

type Props = {
  type?: FormType;
  onSubmit: (data: DemandFormDataType) => void;
  isMutationInFlight: boolean;
  value?: DemandFormDataType;
};

export const DemandForm = ({
  type = 'new',
  onSubmit,
  isMutationInFlight,
  value = {
    name: '',
  },
}: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
  } = useForm<DemandFormDataType>({
    resolver: zodResolver(demandFormSchema),
    defaultValues: value,
  });

  const handleSubmit = (data: DemandFormDataType) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={hookFormSubmit(handleSubmit)}>
      <VStack spacing={8} alignItems="flex-start">
        <VStack spacing={6} w="full">
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>デマンド名</FormLabel>
            <Input type="string" {...register('name')} />
            <ErrorMessage name="name" errors={errors} />
          </FormControl>

          <FormControl isInvalid={!!errors.memo}>
            <FormLabel>メモ</FormLabel>
            <Textarea {...register('memo')} />
            <ErrorMessage name="memo" errors={errors} />
          </FormControl>
        </VStack>

        <Box w="full">
          <Button colorScheme="blue" w="full" type="submit" isDisabled={isMutationInFlight}>
            {getSubmitButtonLabel(type)}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
