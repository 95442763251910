/**
 * @generated SignedSource<<d0c9e0f74dc5588cecf5aae1225203bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type edit_DemandsDetailEditPageQuery$variables = {
  code: string;
};
export type edit_DemandsDetailEditPageQuery$data = {
  readonly companies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly internalMemos: ReadonlyArray<{
          readonly body: string;
        }> | null;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type edit_DemandsDetailEditPageQuery = {
  response: edit_DemandsDetailEditPageQuery$data;
  variables: edit_DemandsDetailEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_DemandsDetailEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_DemandsDetailEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "045aa7a0b7a000d4880f0d777fc150df",
    "id": null,
    "metadata": {},
    "name": "edit_DemandsDetailEditPageQuery",
    "operationKind": "query",
    "text": "query edit_DemandsDetailEditPageQuery(\n  $code: String!\n) {\n  companies(where: {code: $code}) {\n    edges {\n      node {\n        id\n        name\n        internalMemos {\n          body\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ec014c0e5ecd201940ffaf38f11c11d";

export default node;
