import { HStack, VStack } from '@chakra-ui/react';
import { EstimationDetailType } from 'features/estimations/form';
import { SpecLabel } from './SpecLabel';
import { SpecValue } from './SpecValue';

export const EstimationConfirmGiftBoxPreview = ({ detail }: { detail: EstimationDetailType }) => {
  const { giftBox } = detail.spec;
  if (!giftBox || !Object.values(giftBox).some(Boolean)) {
    return null;
  }

  return (
    <VStack borderLeft="2px solid" borderColor="gray.200" pl={3} mt={2} ml={4} spacing={1}>
      {detail.spec.giftBox?.size && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>サイズ</SpecLabel>
          <SpecValue>{detail.spec.giftBox.size}</SpecValue>
        </HStack>
      )}
      {detail.spec.giftBox?.type && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>形状</SpecLabel>
          <SpecValue>{detail.spec.giftBox.type}</SpecValue>
        </HStack>
      )}
      {detail.spec.giftBox?.paperType && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>用紙</SpecLabel>
          <SpecValue>{detail.spec.giftBox.paperType}</SpecValue>
        </HStack>
      )}
      {detail.spec.giftBox?.printingColor && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>印刷</SpecLabel>
          <SpecValue>{detail.spec.giftBox.printingColor}</SpecValue>
        </HStack>
      )}
      {detail.spec.giftBox?.processing && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>加工</SpecLabel>
          <SpecValue>{detail.spec.giftBox.processing}</SpecValue>
        </HStack>
      )}
      {detail.spec.giftBox?.other && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>その他</SpecLabel>
          <SpecValue>{detail.spec.giftBox.other}</SpecValue>
        </HStack>
      )}
    </VStack>
  );
};
