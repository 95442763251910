import { FormLabel } from '@chakra-ui/react';

export const SpecFormLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <FormLabel
      pt={1.5}
      fontSize="xs"
      color="gray.600"
      w="2.8rem"
      whiteSpace="nowrap"
      textAlign="right"
      lineHeight="1.25"
      m={0}
    >
      {children}
    </FormLabel>
  );
};
