import { FormControl, HStack, VStack } from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';
import { SpecFormLabel } from './SpecFormLabel';
import { SpecInput } from './SpecInput';

export const PaperBagSpecForm = ({
  index,
}: {
  index: number;
}) => {
  const form = useFormContext<EstimationFormType>();
  const { errors } = form.formState;

  return (
    <VStack>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>サイズ</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.size`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.size`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>用紙</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.paperType`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.paperType`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>印刷</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.printingColor`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.printingColor`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>加工</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.processing`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.processing`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>持ち手</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.handle`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.handle`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
      <FormControl>
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecFormLabel>その他</SpecFormLabel>
          <VStack w="100%">
            <SpecInput {...form.register(`details.${index}.spec.paperBag.other`)} />
            <ErrorMessage name={`details.${index}.spec.paperBag.other`} errors={errors} />
          </VStack>
        </HStack>
      </FormControl>
    </VStack>
  );
};
