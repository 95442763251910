import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { concatFullName } from 'utils/label';
import { calculateGrossProfitUnitPrice } from 'utils/priceCalculation';
import { ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS } from '../models';
import { EstimationDetailCategory, EstimationFormType } from './schema';
import { DEFAULT_DETAIL_MESSAGE, DEFAULT_EXPIRATION_PERIOD } from './template';

const DEFAULT_GROSS_PROFIT_RATE = 1.3;

const buildDetailMessage = (importantNotes: string): string =>
  `${DEFAULT_DETAIL_MESSAGE}\n\n${importantNotes}\n`;

const getEstimationDetailCategory = (category: string): EstimationDetailCategory => {
  switch (category) {
    case 'cardboard':
      return DEMAND_ITEM_CATEGORY.Cardboard;
    case 'flexiblePackage':
      return DEMAND_ITEM_CATEGORY.FlexiblePackage;
    case 'giftBox':
      return DEMAND_ITEM_CATEGORY.GiftBox;
    case 'paperBag':
      return DEMAND_ITEM_CATEGORY.PaperBag;
    case 'other':
      return DEMAND_ITEM_CATEGORY.Other;
    default:
      return 'cost';
  }
};

const getSpec = (category: EstimationDetailCategory, specRow: Record<string, string>) => {
  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return {
        cardboard: {
          size: specRow.size,
          type: specRow.type,
          material: specRow.material,
          thickness: specRow.thickness,
          printingColor: specRow.printingColor,
          processing: specRow.processing,
          other: specRow.other,
        },
      };
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return {
        flexiblePackage: {
          size: specRow.size,
          type: specRow.type,
          material: specRow.material,
          printingColor: specRow.printingColor,
          processing: specRow.processing,
          other: specRow.other,
        },
      };
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return {
        giftBox: {
          size: specRow.size,
          type: specRow.type,
          paperType: specRow.paperType,
          printingColor: specRow.printingColor,
          processing: specRow.processing,
          other: specRow.other,
        },
      };
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return {
        paperBag: {
          size: specRow.size,
          paperType: specRow.paperType,
          printingColor: specRow.printingColor,
          processing: specRow.processing,
          handle: specRow.handle,
          other: specRow.other,
        },
      };
    case DEMAND_ITEM_CATEGORY.Other:
      return {
        other: {
          specText: specRow.other,
        },
      };
    default:
      return {
        cost: {
          note: specRow.note,
        },
      };
  }
};

export const createDefaultEstimationForm = ({
  tax,
  currentUser,
}: {
  tax: {
    id: string;
    rate: number;
  };
  currentUser: {
    userID: string;
    firstName: string;
    lastName: string;
  };
}): EstimationFormType => {
  return {
    demand: {
      name: '',
      id: '',
    },
    supplier: {
      name: '',
      id: '',
    },
    title: '',
    detailMessage: DEFAULT_DETAIL_MESSAGE,
    expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
    details: [
      {
        category: DEMAND_ITEM_CATEGORY.Cardboard,
        spec: {
          cardboard: {
            size: '',
            type: '',
            material: '',
            thickness: '',
            printingColor: '',
            processing: '',
            other: '',
          },
        },
        tax,
        name: '',
        unitPrice: '',
        quantity: '',
        unitSellingPrice: '',
        documentDisplayStatus: ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
      },
    ],
    internalAssignees: [
      {
        value: currentUser.userID,
        label: concatFullName({
          lastName: currentUser.lastName,
          firstName: currentUser.firstName,
        }),
      },
    ],
    showAmountSummary: false,
  };
};

export const mapResponseToEstimationForm = ({
  demand,
  supplier,
  importantNotes,
  assignees,
  details,
  tax,
}: {
  demand: {
    name: string;
    id: string;
  };
  supplier: {
    name: string;
    id: string;
  };
  importantNotes: string;
  assignees: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  details: {
    id: string;
    name: string;
    unitPrice: number;
    quantity: number;
    specJSON: Record<string, string>;
  }[];
  tax: {
    id: string;
    rate: number;
  };
}): EstimationFormType => {
  const internalAssignees =
    assignees.map((assignee) => ({
      value: assignee.id,
      label: concatFullName({
        lastName: assignee.lastName || '',
        firstName: assignee.firstName || '',
      }),
    })) || [];

  return {
    demand: {
      id: demand.id,
      name: demand.name,
    },
    supplier: {
      id: supplier.id,
      name: supplier.name,
    },
    title: '',
    detailMessage: buildDetailMessage(importantNotes),
    expirationPeriod: DEFAULT_EXPIRATION_PERIOD,
    details: details.map((detail) => {
      const specRow = detail.specJSON;
      const category = getEstimationDetailCategory(specRow.category as string);
      const spec = getSpec(category, specRow);

      return {
        name: detail.name,
        tax,
        category: category,
        spec,
        unitPrice: detail.unitPrice,
        quantity: detail.quantity,
        unitSellingPrice: calculateGrossProfitUnitPrice({
          unitPrice: detail.unitPrice,
          grossProfitMargin: DEFAULT_GROSS_PROFIT_RATE,
        }),
        documentDisplayStatus: ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
      };
    }),
    internalAssignees,
    showAmountSummary: false,
  };
};
