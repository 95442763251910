import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandRecurringCostConfirm } from 'features/demandRecurringCost/components/DemandRecurringCostConfirm';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { confirm_RecurringCostCreateConfirmPageMutation } from 'gql/__generated__/confirm_RecurringCostCreateConfirmPageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandIdContext } from '../context';

export const DemandRecurringCostCreateConfirmPage = () => {
  const navigate = useNavigate();
  const form = useFormContext<DemandRecurringCostFormType>();

  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_RecurringCostCreateConfirmPageMutation>(
      graphql`
      mutation confirm_RecurringCostCreateConfirmPageMutation(
        $input: DemandRecurringCostInput!
      ) {
        createDemandRecurringCost(input: $input) {
          id
        }
      }
    `,
    );

  const { getValues } = form;
  const { name } = getValues();

  const demandId = useContext(DemandIdContext);
  if (!demandId) {
    throw new Error('demandId is null');
  }

  if (!name)
    return <Navigate to={paths.demands._demandCode(demandCode).recurringCosts.new.url()} />;

  const handleSubmit = (data: DemandRecurringCostFormType) => {
    commitMutation({
      variables: {
        input: {
          demandID: demandId,
          name: data.name,
          prices: data.prices.map((price) => ({
            quantity: Number(price.quantity) || 0,
            unitPrice: Number(price.unitPrice) || 0,
            unitSellingPrice: Number(price.unitSellingPrice) || 0,
          })),
          supplierID: data.supplier.id || null,
          taxCategoryID: data.taxId,
          internalMemo: data.memo,
          note: data.note,
        },
      },
      onError() {
        toast({
          title: '継続費用の作成に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        toast({
          title: '継続費用を作成しました',
          status: 'success',
        });
        navigate(paths.demands._demandCode(demandCode).url());
      },
    });
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.demands._demandCode(demandCode).recurringCosts.new.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="内容の確認" />
      <DemandRecurringCostConfirm onSubmit={handleSubmit} isSubmitting={isMutationInFlight} />
    </>
  );
};
