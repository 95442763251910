import { HStack, VStack } from '@chakra-ui/react';
import { EstimationDetailType } from 'features/estimations/form';
import { SpecLabel } from './SpecLabel';
import { SpecValue } from './SpecValue';

export const EstimationConfirmPaperBagPreview = ({ detail }: { detail: EstimationDetailType }) => {
  const { paperBag } = detail.spec;
  if (!paperBag || !Object.values(paperBag).some(Boolean)) {
    return null;
  }

  return (
    <VStack borderLeft="2px solid" borderColor="gray.200" pl={3} mt={2} ml={4} spacing={1}>
      {detail.spec.paperBag?.size && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>サイズ</SpecLabel>
          <SpecValue>{detail.spec.paperBag.size}</SpecValue>
        </HStack>
      )}
      {detail.spec.paperBag?.paperType && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>用紙</SpecLabel>
          <SpecValue>{detail.spec.paperBag.paperType}</SpecValue>
        </HStack>
      )}
      {detail.spec.paperBag?.printingColor && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>印刷</SpecLabel>
          <SpecValue>{detail.spec.paperBag.printingColor}</SpecValue>
        </HStack>
      )}
      {detail.spec.paperBag?.processing && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>加工</SpecLabel>
          <SpecValue>{detail.spec.paperBag.processing}</SpecValue>
        </HStack>
      )}
      {detail.spec.paperBag?.handle && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>持ち手</SpecLabel>
          <SpecValue>{detail.spec.paperBag.handle}</SpecValue>
        </HStack>
      )}
      {detail.spec.paperBag?.other && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>その他</SpecLabel>
          <SpecValue>{detail.spec.paperBag.other}</SpecValue>
        </HStack>
      )}
    </VStack>
  );
};
