import { Box, Button, Checkbox, HStack, VStack } from '@chakra-ui/react';
import { Help } from '@icon-park/react';
import { DEFAULT_GROSS_MARGIN_RATIO } from 'features/estimations/form/template';
import { EstimationFormLineItemsWrapperFragment$key } from 'gql/__generated__/EstimationFormLineItemsWrapperFragment.graphql';
import { ChangeEvent, useState } from 'react';
import { useFragment } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { EstimationFormGrossMarginRatioInput } from './EstimationFormGrossMarginRatioInput';
import { EstimationFormLineItems } from './EstimationFormLineItems';

const estimationFormLineItemsWrapper = graphql`
  fragment EstimationFormLineItemsWrapperFragment on Query {
    ...EstimationFormLineItemsFragment
  }
`;

type Props = {
  queryRef: EstimationFormLineItemsWrapperFragment$key;
};

export const EstimationFormLineItemsWrapper = ({ queryRef }: Props) => {
  const [searchParams] = useSearchParams();
  const responseId = searchParams.get('responseId');

  const query = useFragment(estimationFormLineItemsWrapper, queryRef);

  const [hiddenSpec, setHiddenSpec] = useState(false);
  const handleChangeSpecDisplay = (event: ChangeEvent<HTMLInputElement>) => {
    setHiddenSpec(event.target.checked);
  };

  return (
    <>
      <HStack spacing={4} align="stretch" w="4xl">
        <VStack align="center" spacing={0}>
          <EstimationFormGrossMarginRatioInput
            grossMarginRatio={responseId ? DEFAULT_GROSS_MARGIN_RATIO : undefined}
          />
        </VStack>
        <VStack align="center" spacing={0}>
          <HStack
            align="center"
            borderRadius="md"
            backgroundColor="gray.50"
            py={2}
            px={4}
            spacing={8}
            height="100%"
          >
            <Checkbox onChange={handleChangeSpecDisplay} spacing={1}>
              <Box fontSize="sm" fontWeight="bold">
                仕様を隠す
              </Box>
            </Checkbox>
          </HStack>
        </VStack>
        <VStack align="center" spacing={0}>
          <Button
            leftIcon={<Help size="16" />}
            fontSize="sm"
            fontWeight="bold"
            backgroundColor="gray.50"
            height="100%"
            as="a"
            // TODO: ヘルプページのURLを設定する
            href="#"
            target="_blank"
          >
            一時的な割引を行う場合
          </Button>
        </VStack>
      </HStack>
      <EstimationFormLineItems queryRef={query} hiddenSpec={hiddenSpec} />
    </>
  );
};
