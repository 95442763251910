import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import {
  INVOICE_FORM_TYPE,
  InvoiceFormType,
  InvoiceInputFormValueType,
  getFormPageLabel,
  invoiceInputFormSchema,
} from 'features/invoice/form';
import { InvoiceInputFormFragment$key } from 'gql/__generated__/InvoiceInputFormFragment.graphql';
import { InvoiceInputFormQuery } from 'gql/__generated__/InvoiceInputFormQuery.graphql';
import { toast } from 'lib/toast';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { InvoiceDetailsInputForm } from './InvoiceDetailsInputForm';
import { InvoiceInputFormDetailPrices } from './InvoiceInputFormDetailPrices';
import { buildDefaultDetailsValue } from './helper';

type Props = {
  type?: InvoiceFormType;
  defaultValues?: InvoiceInputFormValueType;
  fetchIds: string[];
  selectedSalesOrderDetailIds: string[];
  toSelectForm: (data: InvoiceInputFormValueType) => void;
  onSubmit: (data: InvoiceInputFormValueType) => void;
};

const invoiceInputFormQuery = graphql`
  query InvoiceInputFormQuery  ($isSkip: Boolean!, $where: SalesOrderDetailWhereInput, $salesOrderDetailIds: [ID!]){
    ...InvoiceInputFormFragment @arguments(isSkip: $isSkip, where: $where)
    ...InvoiceDetailsInputFormFragment  @arguments(salesOrderDetailIds: $salesOrderDetailIds)
  }
`;

const invoiceInputFormFragment = graphql`
  fragment InvoiceInputFormFragment on Query
    @argumentDefinitions (
      where: {type: "SalesOrderDetailWhereInput", defaultValue: null}
      isSkip: {type: "Boolean", defaultValue: true}
    )
  {
    salesOrderDetails(where: $where, orderBy: { direction: ASC, field: ORDER_NUMBER }) @skip(if: $isSkip) {
      edges {
        node {
          id
          branchNumber
          invoiceDetails(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
            edges {
              node {
                id
                invoiceDetailsSales (where: {isValid: true}) {
                  edges {
                    node {
                      salesDate
                    }
                  }
                }
                item {
                  id
                  name
                  quantity
                  unitSellingPrice
                  tax: taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          deliveryEvents(where: {isValid: true}) {
            edges {
              node {
                fixedDeliveryDate
              }
            }
          }
          item {
            id
            name
            quantity
            unitSellingPrice
            tax: taxCategory {
              id
              rate
            }
          }
          salesOrder {
            transactionID
            title
          }
        }
      }
    }

    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
  }
`;

export const InvoiceInputForm = ({
  type = INVOICE_FORM_TYPE.new,
  defaultValues,
  fetchIds,
  toSelectForm,
  selectedSalesOrderDetailIds,
  onSubmit,
}: Props) => {
  const queryRef = useLazyLoadQuery<InvoiceInputFormQuery>(
    invoiceInputFormQuery,
    {
      isSkip: fetchIds.length === 0,
      where: { idIn: [...fetchIds] },
      salesOrderDetailIds: selectedSalesOrderDetailIds,
    },
    { fetchPolicy: 'network-only' },
  );
  const { salesOrderDetails, taxCategories } = useFragment(
    invoiceInputFormFragment,
    queryRef as InvoiceInputFormFragment$key,
  );

  const tax = taxCategories?.edges?.at(0)?.node;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    register,
  } = useForm<InvoiceInputFormValueType>({
    resolver: zodResolver(invoiceInputFormSchema),
    defaultValues: {
      ...defaultValues,
      details: [
        ...buildDefaultDetailsValue({
          inputDetails: defaultValues?.details || [],
          salesOrderDetails,
          tax: {
            id: tax?.id || '',
            rate: tax?.rate || 0,
          },
        }),
      ],
    },
  });

  useEffect(() => {
    const toastId = toast({
      title: '明細を取引日順に並び替えました',
      status: 'success',
    });

    return () => {
      toast.close(toastId);
    };
  }, []);

  return (
    <>
      <Box mb={6}>
        <PageBack onClick={() => toSelectForm(getValues())} />
      </Box>
      <Box mb={6}>
        <SubHeading label={getFormPageLabel(type)} />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6} alignItems="flex-start">
          <FormControl isInvalid={!!errors.title} isRequired w="4xl">
            <FormLabel>件名</FormLabel>
            <Input type="string" {...register('title')} />
            <ErrorMessage name="title" errors={errors} />
          </FormControl>
          <HStack spacing={4} align="start">
            <FormControl isInvalid={!!errors.dates?.bookedAt} isRequired width="164px">
              <FormLabel>請求日</FormLabel>
              <Input type="date" {...register('dates.bookedAt')} />
              <ErrorMessage name="dates.bookedAt" errors={errors} />
            </FormControl>
            <FormControl isInvalid={!!errors.dates?.paymentDueAt} isRequired width="164px">
              <FormLabel>振込期日</FormLabel>
              <Input type="date" {...register('dates.paymentDueAt')} />
              <ErrorMessage name="dates.paymentDueAt" errors={errors} />
            </FormControl>
          </HStack>
          <VStack align="stretch" spacing={0}>
            <HStack align="flex-start" spacing={2}>
              <Box w={4} />
              <CustomFormLabel isRequired w="22.5rem">
                品名
              </CustomFormLabel>
              <CustomFormLabel w="10rem">取引日</CustomFormLabel>
              <CustomFormLabel isRequired w="7.5rem">
                数量
              </CustomFormLabel>
              <CustomFormLabel isRequired w="7.5rem">
                単価
              </CustomFormLabel>
              <CustomFormLabel isRequired w="7rem">
                税区分
              </CustomFormLabel>
              <CustomFormLabel w="7.5rem">金額 (税抜)</CustomFormLabel>
            </HStack>
            <InvoiceDetailsInputForm
              control={control}
              getValues={getValues}
              setValue={setValue}
              register={register}
              errors={errors}
              queryRef={queryRef}
            />
          </VStack>
          <HStack spacing={6} align="flex-start" justifyContent="space-between" w="4xl">
            <VStack spacing={6} alignItems="flex-start" w="70%">
              <FormControl isInvalid={!!errors.detailMessage} w="full">
                <FormLabel>備考</FormLabel>
                <Textarea {...register('detailMessage')} rows={6} />
                <ErrorMessage name="detailMessage" errors={errors} />
              </FormControl>
              <FormControl isInvalid={!!errors.memo} w="full">
                <FormLabel>社内メモ</FormLabel>
                <Textarea {...register('memo')} rows={6} />
                <ErrorMessage name="memo" errors={errors} />
                <Text color="gray.500" fontSize="sm" mt={1}>
                  数量・単価の変更、費用の追加を行った場合は必ず理由を記載してください
                </Text>
              </FormControl>
            </VStack>
            <Box width="240px">
              <InvoiceInputFormDetailPrices control={control} />
            </Box>
          </HStack>
          <Box w="4xl">
            <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
              確認画面へ進む
            </Button>
          </Box>
        </VStack>
      </form>
    </>
  );
};
