/**
 * @generated SignedSource<<36eb0193319bbba5fe1e0efaafed6b19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DemandItemImageSize = "Size1440x1440" | "Size216x216" | "Size440x440" | "Size896x896" | "%future added value";
export type DemandItemResizedImagesInput = {
  itemID: string;
  sizes: ReadonlyArray<DemandItemImageSize>;
};
export type useDemandItemResizedImagesQuery$variables = {
  input: DemandItemResizedImagesInput;
};
export type useDemandItemResizedImagesQuery$data = {
  readonly demandItemResizedImages: ReadonlyArray<{
    readonly displayOrder: number;
    readonly imageID: string;
    readonly images: ReadonlyArray<{
      readonly size: DemandItemImageSize;
      readonly url: string;
    }>;
    readonly objectName: string;
  }>;
};
export type useDemandItemResizedImagesQuery = {
  response: useDemandItemResizedImagesQuery$data;
  variables: useDemandItemResizedImagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DemandItemResizedImages",
    "kind": "LinkedField",
    "name": "demandItemResizedImages",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayOrder",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DemandItemResizedImage",
        "kind": "LinkedField",
        "name": "images",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDemandItemResizedImagesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDemandItemResizedImagesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dac10ae4dfdbcf065fefaf6752b8e76d",
    "id": null,
    "metadata": {},
    "name": "useDemandItemResizedImagesQuery",
    "operationKind": "query",
    "text": "query useDemandItemResizedImagesQuery(\n  $input: DemandItemResizedImagesInput!\n) {\n  demandItemResizedImages(input: $input) {\n    imageID\n    objectName\n    displayOrder\n    images {\n      size\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0f2ad8b2f49f87e22dae3ca76074332";

export default node;
