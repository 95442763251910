import { Text } from '@chakra-ui/react';

export const SpecLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      pt={1.5}
      fontSize="xs"
      color="gray.600"
      w="2.8rem"
      whiteSpace="nowrap"
      textAlign="right"
      lineHeight="1.25"
      m={0}
    >
      {children}
    </Text>
  );
};
