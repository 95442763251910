import { Button, HStack, Tooltip } from '@chakra-ui/react';
import { ArrowRight, Delete, Edit } from '@icon-park/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { RouteLinkWithReferrer } from 'components/RouteLinkWithReferrer';
type Props = {
  onDelete?: (callback?: () => void) => void;
  hasOrder?: boolean;
  isDeleting?: boolean;
  editPath?: string;
  detailPath?: string;
};

export const DemandItemDetailBlockFooter = ({
  editPath,
  detailPath,
  hasOrder = false,
  isDeleting = false,
  onDelete,
}: Props) => {
  const handleDelete = (callback: () => void) => {
    if (!onDelete) return;
    onDelete(callback);
  };

  return (
    <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
      {onDelete && (
        <ConfirmModalButton
          header="削除しますか？"
          button={({ onOpen }) => (
            <Tooltip
              hasArrow
              label="発注済みのため削除できません"
              isDisabled={!hasOrder}
              placement="top"
            >
              <Button leftIcon={<Delete />} disabled={!!hasOrder} onClick={onOpen}>
                削除
              </Button>
            </Tooltip>
          )}
          footer={({ onClose }) => (
            <HStack spacing={3}>
              <Button onClick={onClose} isDisabled={isDeleting}>
                キャンセル
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  handleDelete(onClose);
                }}
                isDisabled={isDeleting}
              >
                削除
              </Button>
            </HStack>
          )}
        />
      )}

      <HStack spacing={4}>
        {editPath && (
          <Button colorScheme="blue" leftIcon={<Edit />} as={RouteLinkWithReferrer} to={editPath}>
            編集
          </Button>
        )}
        {detailPath && (
          <Button
            colorScheme="blue"
            rightIcon={<ArrowRight />}
            as={RouteLinkWithReferrer}
            to={detailPath}
          >
            詳細
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
