/**
 * @generated SignedSource<<92a6036cf97df1959f1b09b01db379aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type create_EstimationCreatePageQuery$variables = {};
export type create_EstimationCreatePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EstimationFormV2Fragment">;
};
export type create_EstimationCreatePageQuery = {
  response: create_EstimationCreatePageQuery$data;
  variables: create_EstimationCreatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaxCategoryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategory",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "create_EstimationCreatePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EstimationFormV2Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "create_EstimationCreatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": "tax",
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdf6dacbc615a158a9a7480dfe2330ed",
    "id": null,
    "metadata": {},
    "name": "create_EstimationCreatePageQuery",
    "operationKind": "query",
    "text": "query create_EstimationCreatePageQuery {\n  ...EstimationFormV2Fragment\n}\n\nfragment EstimationFormLineItemsFragment on Query {\n  ...TaxSelectBoxFragment\n  tax: taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n\nfragment EstimationFormLineItemsWrapperFragment on Query {\n  ...EstimationFormLineItemsFragment\n}\n\nfragment EstimationFormV2Fragment on Query {\n  ...EstimationFormLineItemsWrapperFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03b17af430211b5ffa460b49e33b27e4";

export default node;
