import { AuthProvider } from 'components/provider/AuthProvider';
import { Root } from 'pages';
import { CompanyPage } from 'pages/company';
import { CompanyDetailPage } from 'pages/company/detail';
import { CompanyEditPage } from 'pages/company/edit';
import { CompanyPageLayout } from 'pages/company/layout';
import { CompanyNewPage } from 'pages/company/new';
import { DemandsPage } from 'pages/demands';
import { DemandCreatePage } from 'pages/demands/create';
import { DemandsDetailPage } from 'pages/demands/detail';
import { DemandItemDetailPage } from 'pages/demands/detail/demand-items';
import { DemandItemCreatePage } from 'pages/demands/detail/demand-items/create';
import { DemandItemCreateConfirmPage } from 'pages/demands/detail/demand-items/create/confirm';
import { DemandItemCreateLayout } from 'pages/demands/detail/demand-items/create/layout';
import { DemandItemEditPage } from 'pages/demands/detail/demand-items/edit';
import { DemandItemEditConfirmPage } from 'pages/demands/detail/demand-items/edit/confirm';
import { DemandItemEditLayout } from 'pages/demands/detail/demand-items/edit/layout';
import { DemandItemDetailLayout } from 'pages/demands/detail/demand-items/layout';
import { DemandsDetailEditPage } from 'pages/demands/detail/edit';
import { DemandRecurringCostCreatePage } from 'pages/demands/detail/recurring-costs/create';
import { DemandRecurringCostCreateConfirmPage } from 'pages/demands/detail/recurring-costs/create/confirm';
import { DemandRecurringCostCreateLayout } from 'pages/demands/detail/recurring-costs/create/layout';
import { DemandRecurringCostEditPage } from 'pages/demands/detail/recurring-costs/edit';
import { DemandRecurringCostEditConfirmPage } from 'pages/demands/detail/recurring-costs/edit/confirm';
import { DemandRecurringCostEditLayout } from 'pages/demands/detail/recurring-costs/edit/layout';
import { EstimationPage } from 'pages/estimation';
import { EstimationRequestPage } from 'pages/estimation-request';
import { EstimationRequestAddAssigneesPage } from 'pages/estimation-request/add-assignees';
import { EstimationRequestDetailPage } from 'pages/estimation-request/detail';
import { EstimationRequestDuplicationPage } from 'pages/estimation-request/duplication';
import { EstimationRequestPageLayout } from 'pages/estimation-request/layout';
import { EstimationRequestNewPage } from 'pages/estimation-request/new';
import { EstimationResponsePage } from 'pages/estimation-response';
import { EstimationDetailPage } from 'pages/estimation/detail';
import { EstimationDuplicationPage } from 'pages/estimation/duplication';
import { EstimationEditPage } from 'pages/estimation/edit';
import { EstimationPageLayout } from 'pages/estimation/layout';
import { EstimationNewPage } from 'pages/estimation/new';
import { EstimationsPage } from 'pages/estimations';
import { EstimationCreatePage } from 'pages/estimations/create';
import { EstimationCreateConfirmPage } from 'pages/estimations/create/confirm';
import { EstimationCreateLayout } from 'pages/estimations/create/layout';
import { EstimationsDetailPage } from 'pages/estimations/detail';
import { EstimationsPageLayout } from 'pages/estimations/layout';
import { InvoicePage } from 'pages/invoice';
import { InvoiceDetailPage } from 'pages/invoice/detail';
import { InvoiceEditPage } from 'pages/invoice/edit';
import { InvoicePageLayout } from 'pages/invoice/layout';
import { InvoiceNewPage } from 'pages/invoice/new';
import { LoginPage } from 'pages/login';
import { OauthCallbackPage } from 'pages/oauth-callback';
import { OrderPage } from 'pages/order';
import { OrderDetailPage } from 'pages/order/detail';
import { OrderEditPage } from 'pages/order/edit';
import { OrderPageLayout } from 'pages/order/layout';
import { OrderNewPage } from 'pages/order/new';
import { OrderSendingPage } from 'pages/order/sending';
import { SalesOrderPage } from 'pages/sales-order';
import { SalesOrderDetailPage } from 'pages/sales-order/detail';
import { SalesOrderEditPage } from 'pages/sales-order/edit';
import { SalesOrderPageLayout } from 'pages/sales-order/layout';
import { SalesOrderNewPage } from 'pages/sales-order/new';
import { SalesOrdersPage } from 'pages/sales-orders';
import { SalesOrdersPageLayout } from 'pages/sales-orders/layout';
import { SalesOrdersReorderPage } from 'pages/sales-orders/reorder';
import { SettingPageLayout } from 'pages/settings/layout';
import { ProfilePage } from 'pages/settings/profile';
import { SupplierPage } from 'pages/supplier';
import { SupplierDetailPage } from 'pages/supplier/detail';
import { SupplierEditPage } from 'pages/supplier/edit';
import { SupplierPageLayout } from 'pages/supplier/layout';
import { SupplierNewPage } from 'pages/supplier/new';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthProvider />}>
        <Route path="/" element={<Root />} />

        <Route path="/estimation-request" element={<EstimationRequestPageLayout />}>
          <Route index element={<EstimationRequestPage />} />
          <Route path="new" element={<EstimationRequestNewPage />} />
          <Route path=":id" element={<EstimationRequestDetailPage />} />
          <Route path="duplication">
            <Route path=":id" element={<EstimationRequestDuplicationPage />} />
          </Route>
          <Route path="add-assignees/:id" element={<EstimationRequestAddAssigneesPage />}></Route>
        </Route>

        <Route path="/estimation" element={<EstimationPageLayout />}>
          <Route index element={<EstimationPage />} />
          <Route path=":id">
            <Route index element={<EstimationDetailPage />} />
          </Route>
          <Route path="new" element={<EstimationNewPage />} />
          <Route path="edit">
            <Route path=":id" element={<EstimationEditPage />} />
          </Route>
          <Route path="duplication">
            <Route path=":id" element={<EstimationDuplicationPage />} />
          </Route>
        </Route>

        <Route path="/estimations" element={<EstimationsPageLayout />}>
          <Route index element={<EstimationsPage />} />
          <Route path=":id">
            <Route index element={<EstimationsDetailPage />} />
          </Route>
          <Route path="new" element={<EstimationCreateLayout />}>
            <Route index element={<EstimationCreatePage />} />
            <Route path="confirm" element={<EstimationCreateConfirmPage />} />
          </Route>
        </Route>

        <Route path="/order" element={<OrderPageLayout />}>
          <Route index element={<OrderPage />} />
          <Route path=":id" element={<OrderDetailPage />} />
          <Route path="new" element={<OrderNewPage />} />
          <Route path="edit">
            <Route path=":id" element={<OrderEditPage />} />
          </Route>
          <Route path="sending">
            <Route path=":id" element={<OrderSendingPage />} />
          </Route>
        </Route>

        <Route path="/sales-orders" element={<SalesOrderPageLayout />}>
          <Route index element={<SalesOrderPage />} />
          <Route path="new" element={<SalesOrderNewPage />} />
          <Route path=":id">
            <Route index element={<SalesOrderDetailPage />} />
            <Route path="edit" element={<SalesOrderEditPage />} />
          </Route>
        </Route>

        {/* 商品管理を組み込んだバージョンの発注請書画面 */}
        <Route path="/v2/sales-orders" element={<SalesOrdersPageLayout />}>
          <Route index element={<SalesOrdersPage />} />
          <Route path="reorder" element={<SalesOrdersReorderPage />} />
        </Route>

        <Route path="/invoice" element={<InvoicePageLayout />}>
          <Route index element={<InvoicePage />} />
          <Route path=":id" element={<InvoiceDetailPage />} />
          <Route path="new" element={<InvoiceNewPage />} />
          <Route path="edit">
            <Route path=":id" element={<InvoiceEditPage />} />
          </Route>
        </Route>

        <Route path="/company" element={<CompanyPageLayout />}>
          <Route index element={<CompanyPage />} />
          <Route path=":id">
            <Route index element={<CompanyDetailPage />} />
            <Route path="edit" element={<CompanyEditPage />} />
          </Route>
          <Route path="new">
            <Route index element={<CompanyNewPage />} />
          </Route>
        </Route>

        <Route path="/demands">
          <Route index element={<DemandsPage />} />
          <Route path="new" element={<DemandCreatePage />} />
          <Route path=":demandCode">
            <Route index element={<DemandsDetailPage />} />
            <Route path="edit" element={<DemandsDetailEditPage />} />
            <Route path="/demands/:demandCode/demand-items">
              <Route path="new" element={<DemandItemCreateLayout />}>
                <Route index element={<DemandItemCreatePage />} />
                <Route path="confirm" element={<DemandItemCreateConfirmPage />} />
              </Route>
              <Route path=":itemCode" element={<DemandItemDetailLayout />}>
                <Route index element={<DemandItemDetailPage />} />
                <Route path="edit" element={<DemandItemEditLayout />}>
                  <Route index element={<DemandItemEditPage />} />
                  <Route path="confirm" element={<DemandItemEditConfirmPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="/demands/:demandCode/recurring-costs">
              <Route path="new" element={<DemandRecurringCostCreateLayout />}>
                <Route index element={<DemandRecurringCostCreatePage />} />
                <Route path="confirm" element={<DemandRecurringCostCreateConfirmPage />} />
              </Route>
              <Route path=":costNumber">
                <Route path="edit" element={<DemandRecurringCostEditLayout />}>
                  <Route index element={<DemandRecurringCostEditPage />} />
                  <Route path="confirm" element={<DemandRecurringCostEditConfirmPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/supplier" element={<SupplierPageLayout />}>
          <Route index element={<SupplierPage />} />
          <Route path="new" element={<SupplierNewPage />} />
          <Route path=":id">
            <Route index element={<SupplierDetailPage />} />
          </Route>
          <Route path="edit">
            <Route path=":id" element={<SupplierEditPage />} />
          </Route>
        </Route>

        <Route path="/settings" element={<SettingPageLayout />}>
          <Route path="profile" element={<ProfilePage />} />
        </Route>
      </Route>
      <Route path="/estimation-response" element={<EstimationResponsePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/oauth/:app/callback" element={<OauthCallbackPage />} />

      {/* TODO: 商品管理をリリースするときにコメントを外す */}
      {/* <RedirectRoutes /> */}
    </>,
  ),
);
export const AppRoutes = () => <RouterProvider router={router} />;
