import { Loading } from 'components/Loading';
import { OrderDownload } from 'features/order/components/OrderDownload';
import { OrderForm } from 'features/order/components/OrderForm';
import { OrderSendingForm } from 'features/order/components/OrderSendingForm';
import { useOrderEditForm } from 'features/order/hooks/useOrderEditForm';
import { RECIPIENT_TYPE } from 'features/order/type';
import { defaultMailBodyMessage } from 'features/order/util';
import { ZodOrderFormData } from 'features/order/zod';
import {
  edit_OrderEditPageFragment$data,
  edit_OrderEditPageFragment$key,
} from 'gql/__generated__/edit_OrderEditPageFragment.graphql';
import { edit_OrderEditPageQuery } from 'gql/__generated__/edit_OrderEditPageQuery.graphql';
import { Suspense, useLayoutEffect } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useParams, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const orderEditPageQuery = graphql`
  query edit_OrderEditPageQuery ($id: ID!){
    ...edit_OrderEditPageFragment @arguments(id: $id)
    ...OrderFormFragment
  }
`;

const orderEditPageFragment = graphql`
  fragment edit_OrderEditPageFragment on Query 
    @argumentDefinitions(
      id: {type: "ID"}
    )
  {
    orders(where: {id: $id}) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          details {
            id
            type
            salesOrderDetailID
            item {
              id
              name
              unitPrice
              quantity
              tax: taxCategory {
                id
                rate
              }
            }
          }
          title
          detailMessage
          mailBodyMessage
          deliveryInfo {
            desiredDeliveryDate
            recipientsText
          }
          assignees {
            recipientType
            contact {
              id
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
          salesOrders {
            id
          }
        }
      }
    }
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
    currentUser {
      lastName
    }
  }
`;

export const OrderEditPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<edit_OrderEditPageQuery>(
    orderEditPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );
  const { orders, taxCategories, currentUser } = useFragment(
    orderEditPageFragment,
    query as edit_OrderEditPageFragment$key,
  );

  const order = orders?.edges?.at(0)?.node;
  const tax = taxCategories?.edges?.at(0)?.node;

  const {
    data,
    showPreview,
    onClickToConfirm,
    onSubmit,
    isMutationInFlight,
    onClickToNewForm,
    updateSubmitType,
    sendingData,
  } = useOrderEditForm({
    orderId: id,
    state: buildOrderEditFormState({
      order,
      taxCategories: {
        id: tax?.id || '',
        rate: tax?.rate || 0,
      },
    }),
    sendingValues: buildOrderEditSendingFormState(currentUser, order),
  });

  useLayoutEffect(() => {
    if (!showPreview && type) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, showPreview, type]);

  if (!order) return null;

  return (
    <Suspense fallback={<Loading />}>
      {type && showPreview ? (
        <>
          {type === 'sending' && (
            <OrderSendingForm
              onClickSubmit={onSubmit}
              values={data}
              isMutationInFlight={isMutationInFlight}
              onClickToNewForm={onClickToNewForm}
              sendingData={sendingData}
            />
          )}
          {type === 'download' && (
            <OrderDownload
              type="edit"
              onClickSubmit={onSubmit}
              values={data}
              isMutationInFlight={isMutationInFlight}
              onClickToNewForm={onClickToNewForm}
            />
          )}
        </>
      ) : (
        <OrderForm
          type="edit"
          onClickToConfirm={onClickToConfirm}
          data={data}
          queryRef={query}
          updateSubmitType={updateSubmitType}
          previousUrl={paths.order._id(id)}
        />
      )}
    </Suspense>
  );
};

type OrderNodeType = NonNullable<
  NonNullable<
    NonNullable<NonNullable<edit_OrderEditPageFragment$data['orders']>['edges']>[number]
  >['node']
>;

type CurrentUserType = edit_OrderEditPageFragment$data['currentUser'];

const buildOrderEditFormState = ({
  order,
  taxCategories,
}: {
  order?: OrderNodeType | null;
  taxCategories: {
    id: string;
    rate: number;
  };
}): ZodOrderFormData => {
  if (!order) {
    return {
      title: '',
      company: {
        id: '',
        name: '',
      },
      supplier: {
        id: '',
        name: '',
      },
      detailMessage: '',
      deliveryInfo: {},
      details: [],
      internalAssignees: [],
    };
  }

  const internalAssignees =
    order?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: order.company.id,
      name: order.company.name,
    },
    supplier: {
      id: order.supplier.id,
      name: order.supplier.name,
    },
    title: order.title,
    detailMessage: order.detailMessage || '',
    deliveryInfo: {
      desiredDeliveryDate: order.deliveryInfo?.desiredDeliveryDate,
      recipientsText: order.deliveryInfo?.recipientsText || '',
    },
    details: (order.details || []).map((detail) => {
      const item = detail.item;
      if (item) {
        return {
          name: item.name,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          salesOrderDetailId: detail.salesOrderDetailID || undefined,
          tax: {
            id: item.tax.id,
            rate: item.tax.rate,
          },
        };
      }

      return {
        name: '',
        salesOrderDetailId: undefined,
        tax: {
          id: taxCategories.id,
          rate: taxCategories.rate,
        },
      };
    }),
    internalAssignees,
  };
};

const buildOrderEditSendingFormState = (
  currentUser: CurrentUserType,
  order?: OrderNodeType | null,
) => {
  if (!order) {
    return {
      mailBodyMessage: defaultMailBodyMessage({ lastName: currentUser.lastName }),
      supplierContactsTo: [],
      supplierContactsCc: [],
    };
  }

  return {
    mailBodyMessage:
      order.mailBodyMessage || defaultMailBodyMessage({ lastName: currentUser.lastName }),
    supplierContactsTo:
      order.assignees
        ?.filter((assign) => assign.recipientType === RECIPIENT_TYPE.to)
        .map((assign) => assign.contact.id) || [],
    supplierContactsCc:
      order.assignees
        ?.filter((assign) => assign.recipientType === RECIPIENT_TYPE.cc)
        .map((assign) => assign.contact.id) || [],
  };
};
