import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Image,
  Select,
  VStack,
} from '@chakra-ui/react';
import { AddOne, Copy } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { ESTIMATION_DETAIL_CATEGORIES, EstimationFormType } from 'features/estimations/form';
import { ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS } from 'features/estimations/models';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { EstimationFormLineItemsFragment$key } from 'gql/__generated__/EstimationFormLineItemsFragment.graphql';
import TextEstimationDocumentDisplayStatus from 'public/images/text_estimation_document_display_status.svg';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { graphql, useFragment } from 'react-relay';
import { EstimationFormItemAmount } from './EstimationFormItemAmount';
import { EstimationFormItemDisplayStatus } from './EstimationFormItemDisplayStatus';
import { EstimationFormItemGrossRatio } from './EstimationFormItemGrossRatio';
import { EstimationFormLineItemDeleteButton } from './EstimationFormLineItemDeleteButton';
import { EstimationFormSpecs } from './EstimationFormSpecs/EstimationFormSpecs';

const estimationFormLineItemsFragment = graphql`
  fragment EstimationFormLineItemsFragment on Query {
    ...TaxSelectBoxFragment,
    tax: taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    },
  }
`;

type Props = {
  queryRef: EstimationFormLineItemsFragment$key;
  hiddenSpec: boolean;
};

export const EstimationFormLineItems = ({ queryRef, hiddenSpec }: Props) => {
  const query = useFragment(estimationFormLineItemsFragment, queryRef);
  const { tax } = query;

  const form = useFormContext<EstimationFormType>();
  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = form;

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'details',
  });

  const appendDetail = () => {
    append({
      name: '',
      unitPrice: '',
      quantity: '',
      unitSellingPrice: '',
      documentDisplayStatus: ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
      tax: {
        id: tax?.edges?.[0]?.node?.id ?? '',
        rate: tax?.edges?.[0]?.node?.rate ?? '',
      },
      category: DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {},
    });
  };

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const handleDuplicationItem = (index: number) => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  const isDisabledUpButton = (index: number) => index === 0;

  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  return (
    <VStack align="start" w="1306px">
      <Image
        src={TextEstimationDocumentDisplayStatus}
        alt="開いた目を閉じた目にすると、見積書に記載せず、単価を記録しておけます"
      />

      <VStack align="stretch" spacing={0}>
        <HStack align="flex-start" spacing={2}>
          <Box w={16} />
          <CustomFormLabel isRequired w="12.5rem">
            品名
          </CustomFormLabel>
          <CustomFormLabel isRequired w="7.5rem">
            カテゴリ
          </CustomFormLabel>
          <CustomFormLabel w="17.75rem" hidden={hiddenSpec}>
            仕様
          </CustomFormLabel>
          <CustomFormLabel isRequired w="6rem">
            数量
          </CustomFormLabel>
          <CustomFormLabel isRequired w="6rem">
            仕入単価
          </CustomFormLabel>
          <CustomFormLabel isRequired w="6rem">
            顧客単価
          </CustomFormLabel>
          <CustomFormLabel isRequired w="6rem">
            税区分
          </CustomFormLabel>
          <CustomFormLabel w="6.5rem">金額 (税抜)</CustomFormLabel>
        </HStack>
        <VStack align="stretch" spacing={4}>
          {fields.map((field, index) => (
            <HStack key={field.id} align="flex-start" spacing={2}>
              {/* 並び順変更する矢印 */}
              <VStack>
                <ChangeOrderButton
                  type="up"
                  onClick={() => handleClickUp(index)}
                  isDisabled={isDisabledUpButton(index)}
                />
                <ChangeOrderButton
                  type="down"
                  onClick={() => handleClickDown(index)}
                  isDisabled={isDisabledDownButton(index)}
                />
              </VStack>

              <EstimationFormItemDisplayStatus index={index} />

              {/* 品名 */}
              <FormControl isInvalid={!!(errors.details || [])[index]?.name} isRequired w="12.5rem">
                <AutoResizeTextarea {...register(`details.${index}.name`)} />
                <ErrorMessage name={`details.${index}.name`} errors={errors} />
              </FormControl>

              {/* カテゴリー */}
              <FormControl isInvalid={!!(errors.details || [])[index]?.name} isRequired w="7.5rem">
                <Select fontSize="sm" {...register(`details.${index}.category`)}>
                  {Object.entries(ESTIMATION_DETAIL_CATEGORIES).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* 仕様 */}
              <FormControl
                isInvalid={!!(errors.details || [])[index]?.name}
                w="17.75rem"
                hidden={hiddenSpec}
              >
                <Box borderLeft="2px solid" borderColor="gray.200" pl={3}>
                  <EstimationFormSpecs index={index} />
                </Box>
              </FormControl>

              {/* 数量 */}
              <FormControl
                isInvalid={!!(errors.details || [])[index]?.quantity}
                isRequired
                flex={1}
                w="6rem"
              >
                <ConfigurableNumberInput {...register(`details.${index}.quantity`)} />
                <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
              </FormControl>

              {/* 仕入単価 */}
              <FormControl
                isInvalid={!!(errors.details || [])[index]?.unitPrice}
                isRequired
                flex={1}
                w="6rem"
              >
                <ConfigurableNumberInput {...register(`details.${index}.unitPrice`)} step="0.01" />
                <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
              </FormControl>

              {/* 顧客単価 */}
              <FormControl
                isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
                isRequired
                flex={1}
                w="6rem"
              >
                <ConfigurableNumberInput
                  {...register(`details.${index}.unitSellingPrice`)}
                  step="0.01"
                />
                <EstimationFormItemGrossRatio index={index} />
                <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
              </FormControl>

              {/* 税区分 */}
              <FormControl
                isInvalid={!!(errors.details || [])[index]?.tax?.id}
                isRequired
                flex={1}
                w="6rem"
              >
                <Controller
                  name={`details.${index}.tax`}
                  control={control}
                  render={() => (
                    <TaxSelectBox
                      defaultValue={getValues(`details.${index}.tax.id`)}
                      queryRef={query}
                      onChange={(e) => {
                        setValue(`details.${index}.tax`, {
                          ...getValues(`details.${index}.tax`),
                          ...{
                            id: e.target.value,
                            rate: e.target[e.target.selectedIndex].getAttribute(
                              'data-rate',
                            ) as string,
                          },
                        });
                      }}
                    />
                  )}
                />
                <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
              </FormControl>

              {/* 金額 */}
              <Box w="6.5rem">
                <EstimationFormItemAmount index={index} />
              </Box>

              {/* 複製ボタン */}
              <Box>
                <IconButton
                  onClick={() => handleDuplicationItem(index)}
                  variant="ghost"
                  isRound
                  aria-label="copy"
                  icon={<Copy />}
                />
              </Box>

              {/* 削除ボタン */}
              <EstimationFormLineItemDeleteButton remove={() => remove(index)} />
            </HStack>
          ))}
          <Button w="full" colorScheme="gray" leftIcon={<AddOne />} onClick={() => appendDetail()}>
            追加
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};
