import { Text } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateGrossProfitMargin } from 'utils/priceCalculation';

type Props = {
  index: number;
};

export const EstimationFormItemGrossRatio = ({ index }: Props) => {
  const form = useFormContext<EstimationFormType>();
  const detail = useWatch({ name: `details.${index}`, control: form.control });

  return (
    <Text fontSize="sm" color="gray.500" textAlign="right" mt={2} whiteSpace="nowrap">
      粗利率:{' '}
      {calculateGrossProfitMargin(
        Number(detail.unitPrice) || 0,
        Number(detail.unitSellingPrice) || 0,
      )}
      %
    </Text>
  );
};
