import { Box, Flex, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { PageHeading } from 'components/PageHeading';
import { SubHeading } from 'components/SubHeading';
import { DemandFormDataType } from 'features/demand/form';
import { useFilterParameter } from 'features/demandItem/parameter/useFilterParameter';
import { edit_DemandsDetailEditPageMutation } from 'gql/__generated__/edit_DemandsDetailEditPageMutation.graphql';
import { edit_DemandsDetailEditPageQuery } from 'gql/__generated__/edit_DemandsDetailEditPageQuery.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandForm } from '../../components/DemandForm';

const DemandsDetailEditPageQuery = graphql`
  query edit_DemandsDetailEditPageQuery ($code: String!) {
    companies(where: {code: $code}) {
      edges {
        node {
          id
          name
          internalMemos {
            body
          }
        }
      }
    }
  }
`;

export const DemandsDetailEditPage = () => {
  const navigate = useNavigate();
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { itemCategories, itemSuppliers } = useFilterParameter();

  const { companies } = useLazyLoadQuery<edit_DemandsDetailEditPageQuery>(
    DemandsDetailEditPageQuery,
    { code: demandCode },
    { fetchPolicy: 'network-only' },
  );

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<edit_DemandsDetailEditPageMutation>(
      graphql`
      mutation edit_DemandsDetailEditPageMutation(
        $id: ID!
        $input: UpdateDemandInput!
      ) {
        updateDemand(id: $id, input: $input) {
          id
        }
      }
    `,
    );

  const demandData = companies?.edges?.[0]?.node;

  if (!demandData) return null;

  const demand = {
    name: demandData.name,
    memo: demandData.internalMemos?.[0]?.body || '',
  };

  const onSubmit = (data: DemandFormDataType) => {
    commitMutation({
      variables: {
        id: demandData.id,
        input: {
          name: data.name,
          memo: data.memo || undefined,
        },
      },
      onCompleted: () => {
        toast({
          title: 'デマンドを更新しました',
          status: 'success',
        });
        navigate(
          paths.demands._demandCode(demandCode).url({
            itemCategories,
            itemSuppliers,
          }),
        );
      },
    });
  };

  return (
    <VStack spacing={8} alignItems="flex-start">
      <PageHeading label={`デマンド：${demand.name}`} />
      <Flex justify="space-between" width="768px">
        <RouterLink
          to={paths.demands._demandCode(demandCode).url({
            itemCategories,
            itemSuppliers,
          })}
        >
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="デマンドの詳細" />
      <Box w="480px">
        <DemandForm
          type="edit"
          value={demand}
          onSubmit={onSubmit}
          isMutationInFlight={isMutationInFlight}
        />
      </Box>
    </VStack>
  );
};
