import { AddOne } from '@icon-park/react';
import { FileUploadButton } from 'components/FileUploadButton';
import { DemandItemFormType } from 'features/demandItem/form';
import { DemandItemImageForm_useUploadDemandItemTempImageMutation } from 'gql/__generated__/DemandItemImageForm_useUploadDemandItemTempImageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { graphql } from 'relay-runtime';
import { DemandItemImagePreview } from './DemandItemImagePreview';

export const DemandItemImageForm = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<DemandItemFormType>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'images',
  });

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<DemandItemImageForm_useUploadDemandItemTempImageMutation>(graphql`
      mutation DemandItemImageForm_useUploadDemandItemTempImageMutation($input: UploadDemandItemTempImageInput!) {
        uploadDemandItemTempImage(input: $input) {
          objectName
          thumbnailUrl
        }
      }
    `);

  const onUploadDemandItemTempImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    if (files.length === 0) return;

    const promises = new Array<Promise<void>>();
    const errors = new Array<string>();

    const toastId = toast({
      title: '商品写真をアップロード中です',
      status: 'loading',
    });

    const latestDisplayOrder = fields[fields.length - 1]?.displayOrder ?? 1;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      promises.push(
        new Promise<void>((resolve, reject) => {
          commitMutation({
            variables: {
              input: {
                image: {
                  file: null,
                },
              },
            },
            uploadables: {
              [`variables.input.image.file`]: file,
            },
            onCompleted: (data) => {
              append({
                displayOrder: latestDisplayOrder + i + 1,
                objectName: data.uploadDemandItemTempImage.objectName,
                url: data.uploadDemandItemTempImage.thumbnailUrl,
              });
              resolve();
            },
            onError: (error) => {
              errors.push(error.message);
              reject(error);
            },
          });
        }),
      );
    }

    await Promise.all(promises);

    toast.close(toastId);

    if (errors.length > 0) {
      toast({
        title: '商品写真のアップロードに失敗しました',
        description: errors.join('\n'),
        status: 'error',
      });
      return;
    }

    toast({
      title: '商品写真をアップロードしました',
      status: 'success',
    });
  };

  const onClickDeleteButton = (index: number) => {
    remove(index);
  };

  const onClickImage = (_objectName: string) => {
    // TODO: 画像をクリックしたら、その画像を拡大表示する
  };

  return (
    <>
      <DemandItemImagePreview
        images={fields}
        isMutationInFlight={isMutationInFlight}
        onDelete={onClickDeleteButton}
        onClick={onClickImage}
      />
      <FileUploadButton
        name="images"
        label="追加"
        multiple={true}
        accept=".png, .jpg, .jpeg, .heif, .heic"
        register={register}
        errors={errors.images?.[0]}
        leftIcon={<AddOne />}
        buttonProps={{ isDisabled: isMutationInFlight }}
        onChange={onUploadDemandItemTempImages}
      />
    </>
  );
};
