import { DemandMemo } from 'features/company/components/DemandMemo';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext, useWatch } from 'react-hook-form';

export const EstimationFormDemandMemo = () => {
  const form = useFormContext<EstimationFormType>();
  const demand = useWatch({ name: 'demand', control: form.control });

  return <DemandMemo demandId={demand?.id || ''} />;
};
