import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import {
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
  buildDemandItemCode,
} from 'features/demandItem/models';
import { DemandItemDetailInfoFragment$key } from 'gql/__generated__/DemandItemDetailInfoFragment.graphql';
import { graphql, useFragment } from 'react-relay';
import { dateFormatWithTimeWithoutDayOfWeek } from 'utils/date';
import { paths } from 'utils/paths';
import { DemandItemDetailNoImageThumbnail } from './DemandItemDetailNoImageThumbnail';
import { DemandItemDetailThumbnails } from './DemandItemDetailThumbnails';
import { DemandItemSpecInfo } from './DemandItemSpecInfo';

const DemandItemDetailInfoFragment = graphql`
  fragment DemandItemDetailInfoFragment on DemandItem {
    id
    name
    supplier {
      id
      name
    }
    internalMemos {
      body
    }
    demand {
      code
    }
    itemNumber
    version
    category
    startedAt
    endedAt
    taxCategory {
      id
      rate
    }
    prices {
      id
      quantity
      unitPrice
      unitSellingPrice
    }
    images {
      id
      displayOrder
    }
    orderItems(orderBy: { direction: DESC, field: CREATED_AT }, first: 1) {
      edges {
        node {
          orderDetail {
            order {
              id
              title
            }
          }
        }
      }
    }
    ...DemandItemSpecInfoFragment
  }
`;

type Props = {
  queryRef: DemandItemDetailInfoFragment$key;
};

const getValidityPeriod = (startedAt: string, endedAt: string) => {
  const startedAtDate = dateFormatWithTimeWithoutDayOfWeek(startedAt);

  if (!endedAt) {
    return startedAtDate;
  }

  const endedAtDate = dateFormatWithTimeWithoutDayOfWeek(endedAt);
  return `${startedAtDate} - ${endedAtDate}`;
};

export const DemandItemDetailInfo = ({ queryRef }: Props) => {
  const item = useFragment(DemandItemDetailInfoFragment, queryRef);
  const memo = item.internalMemos?.[0]?.body || '-';
  const demandItemCode = buildDemandItemCode({
    demandCode: item.demand.code,
    category: item.category as DemandItemCategory,
    itemNumber: item.itemNumber,
    version: item.version,
  });

  return (
    <HStack spacing={10} width="1024px" justifyContent="space-between" alignItems="flex-start">
      {item.images && item.images.length > 0 ? (
        <DemandItemDetailThumbnails item={item} />
      ) : (
        <DemandItemDetailNoImageThumbnail />
      )}

      <VStack spacing={6} width="536px">
        <VStack spacing={3} alignItems="flex-start" width="100%" fontSize="sm">
          <HStack spacing={2}>
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              コード
            </Box>
            <Box minHeight="24px">{demandItemCode}</Box>
          </HStack>
          <HStack spacing={2}>
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              有効期間
            </Box>
            <Box minHeight="24px">{getValidityPeriod(item.startedAt, item.endedAt)}</Box>
          </HStack>
          <HStack spacing={2}>
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              サプライヤー
            </Box>
            <Box minHeight="24px">{item.supplier.name}</Box>
          </HStack>
          <HStack spacing={2}>
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              カテゴリー
            </Box>
            <Box minHeight="24px">
              {DEMAND_ITEM_CATEGORY_LABEL[item.category as DemandItemCategory].categoryName}
            </Box>
          </HStack>
          <HStack spacing={2}>
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              最終発注
            </Box>
            <Box minHeight="24px">
              {item.orderItems.edges?.[0]?.node?.orderDetail?.order?.title ? (
                <Link
                  to={paths.order._id(item.orderItems.edges[0].node?.orderDetail?.order?.id)}
                  target="_blank"
                >
                  <HStack gap={1}>
                    <Box>{item.orderItems.edges[0].node?.orderDetail?.order?.title}</Box>
                    <EfferentFour theme="outline" />
                  </HStack>
                </Link>
              ) : (
                '-'
              )}
            </Box>
          </HStack>

          <DemandItemSpecInfo queryRef={item} />

          <HStack spacing={2} alignItems="flex-start">
            <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right" minHeight="24px">
              社内メモ
            </Box>
            <Box whiteSpace="pre-wrap" minHeight="24px">
              {memo}
            </Box>
          </HStack>
        </VStack>
        <VStack spacing={1} width="100%">
          <DemandItemPriceTable
            values={(item.prices || []).map((price) => ({
              id: price.id,
              quantity: price.quantity,
              unitPrice: price.unitPrice,
              unitSellingPrice: price.unitSellingPrice,
            }))}
          />
          <HStack width="100%" justifyContent="flex-end">
            <Text fontSize="xs" color="gray.500">
              税区分：{item.taxCategory.rate}%
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </HStack>
  );
};
