import {
  Box,
  IconButton,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react';
import { Left, Right } from '@icon-park/react';
import {
  DemandItemResizedImagesType,
  useDemandItemResizedImages,
} from 'features/demandItem/api/useDemandItemResizedImages';
import { DemandItemImageSize } from 'gql/graphql.types';
import { useEffect, useState } from 'react';
import { EffectFade, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

type Props = {
  itemId: string;
  imageCount: number;
  isOpen: boolean;
  onClose: () => void;
};

export const DemandItemImageSlider = ({ itemId, imageCount, isOpen, onClose }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  const { data, fetch } = useDemandItemResizedImages({
    itemID: itemId,
    sizes: [DemandItemImageSize.Size216x216, DemandItemImageSize.Size1440x1440],
  });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="transparent" border="none" shadow="none" maxWidth="max-content">
        {/* メイン画像のスライダー */}
        <MainImageSlider data={data} thumbsSwiper={thumbsSwiper} setActiveIndex={setActiveIndex} />

        {/* サムネイル一覧のスライダー */}
        <ThumbnailsImageSlider
          data={data}
          imageCount={imageCount}
          activeIndex={activeIndex}
          setThumbsSwiper={setThumbsSwiper}
        />
      </ModalContent>
    </Modal>
  );
};

const MainImageSlider = ({
  data,
  thumbsSwiper,
  setActiveIndex,
}: {
  data: DemandItemResizedImagesType | undefined;
  thumbsSwiper: SwiperClass | null;
  setActiveIndex: (index: number) => void;
}) => {
  return (
    <Swiper
      style={{
        position: 'relative',
        width: '768px',
        height: '640px',
      }}
      navigation={{
        prevEl: '#swiper-button-prev',
        nextEl: '#swiper-button-next',
      }}
      loop={true}
      effect="fade"
      thumbs={{ swiper: thumbsSwiper }}
      onSlideChange={(swiper) => {
        // ループを考慮した realIndex を使う
        setActiveIndex(swiper.realIndex);
      }}
      modules={[EffectFade, FreeMode, Navigation, Thumbs]}
    >
      {data ? (
        data
          .toSorted((a, b) => a.displayOrder - b.displayOrder)
          .map((image, index) => {
            const url = image.images.find(
              (image) => image.size === DemandItemImageSize.Size1440x1440,
            )?.url;

            return (
              <SwiperSlide
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box width="640px" height="640px" rounded="2xl" overflow="hidden" bgColor="gray.50">
                  {url ? (
                    <Image src={url} width="640px" height="640px" objectFit="contain" />
                  ) : (
                    <Skeleton width="640px" height="640px" rounded="2xl" />
                  )}
                </Box>
              </SwiperSlide>
            );
          })
      ) : (
        <SwiperSlide
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <Box width="640px" height="640px" rounded="2xl" overflow="hidden" bgColor="gray.50">
            <Skeleton width="640px" height="640px" rounded="2xl" />
          </Box>
        </SwiperSlide>
      )}
      <MainImageSliderPrevButton />
      <MainImageSliderNextButton />
    </Swiper>
  );
};

const MainImageSliderPrevButton = () => {
  return (
    <IconButton
      id="swiper-button-prev"
      aria-label="前の画像へ"
      position="absolute"
      top="50%"
      left="0"
      width="48px"
      height="48px"
      rounded="full"
      bgColor="gray.100"
      icon={<Left size={24} />}
      zIndex={2}
    />
  );
};

const MainImageSliderNextButton = () => {
  return (
    <IconButton
      id="swiper-button-next"
      aria-label="次の画像へ"
      position="absolute"
      top="50%"
      right="0"
      rounded="full"
      width="48px"
      height="48px"
      bgColor="gray.100"
      icon={<Right size={24} />}
      zIndex={2}
    />
  );
};

const ThumbnailsImageSlider = ({
  data,
  imageCount,
  activeIndex,
  setThumbsSwiper,
}: {
  data: DemandItemResizedImagesType | undefined;
  imageCount: number;
  activeIndex: number;
  setThumbsSwiper: (swiper: SwiperClass) => void;
}) => {
  return (
    <Swiper
      style={{
        maxWidth: '100%',
        marginTop: '24px',
      }}
      onSwiper={(swiper) => setThumbsSwiper(swiper)}
      spaceBetween={12}
      slidesPerView={imageCount}
      watchSlidesProgress={true}
      modules={[FreeMode, Navigation, Thumbs]}
    >
      {data
        ? data
            .toSorted((a, b) => a.displayOrder - b.displayOrder)
            .map((image, index) => {
              const url = image.images.find(
                (image) => image.size === DemandItemImageSize.Size216x216,
              )?.url;
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    maxWidth: '80px',
                    maxHeight: '80px',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    width="80px"
                    height="80px"
                    rounded="lg"
                    overflow="hidden"
                    bgColor="gray.50"
                    boxSizing="border-box"
                    outline={index === activeIndex ? '3px solid #3182CE' : 'none'}
                    outlineOffset={index === activeIndex ? '-3px' : '0'}
                  >
                    <Image src={url} width="80px" height="80px" rounded="lg" objectFit="contain" />
                  </Box>
                </SwiperSlide>
              );
            })
        : //　商品に紐づく画像枚数分のスケルトンを表示する
          Array(imageCount)
            .fill(0)
            .map((_, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ maxWidth: '80px', maxHeight: '80px', pointerEvents: 'none' }}
                >
                  <Skeleton width="80px" height="80px" rounded="lg" />
                </SwiperSlide>
              );
            })}
    </Swiper>
  );
};
