import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';

export const useDemandItemImageSlider = () => {
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [imageCount, setImageCount] = useState<number>(0);
  const { isOpen: isOpenSlider, onOpen, onClose } = useDisclosure();

  const handleOpenSlider = (itemId: string, imageCount: number) => {
    // スライダーを表示するときに背景のスクロールを禁止する
    document.body.style.overflow = 'hidden';
    setSelectedItemId(itemId);
    setImageCount(imageCount);
    onOpen();
  };

  const handleCloseSlider = () => {
    // スライダーを閉じるときに背景のスクロールを許可する
    document.body.style.overflow = 'auto';
    setSelectedItemId('');
    setImageCount(0);
    onClose();
  };

  return { selectedItemId, imageCount, isOpenSlider, handleOpenSlider, handleCloseSlider };
};
