import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import {} from 'utils/priceCalculation';
import { EstimationConfirmItemPriceList } from './EstimationConfirmItemPriceList';
import { EstimationConfirmPreview } from './EstimationConfirmPreview';
type Props = {
  onSubmit: (data: EstimationFormType) => void;
  isSubmitting?: boolean;
};

export const EstimationConfirmV2 = ({ onSubmit, isSubmitting = false }: Props) => {
  const form = useFormContext<EstimationFormType>();
  const { getValues, handleSubmit } = form;

  const { title, internalAssignees } = getValues();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="768px" spacing={6} alignItems="flex-start">
        <VStack spacing={3} alignItems="flex-start" fontSize="sm">
          <HStack align="top" w="full">
            <Text color="gray.500">件名</Text>
            <Text>{title}</Text>
          </HStack>
          <HStack align="top" w="full">
            <Text color="gray.500">担当者</Text>
            <HStack wrap="wrap" spacing={0}>
              {(internalAssignees || []).map((assignee, index) => (
                <Fragment key={assignee.value}>
                  {index !== 0 && (
                    <Box color="gray.200" px={2}>
                      /
                    </Box>
                  )}
                  <HStack>
                    <Box whiteSpace="nowrap">{assignee.label}</Box>
                  </HStack>
                </Fragment>
              ))}
            </HStack>
          </HStack>
        </VStack>

        {/* 見積書部分 */}
        <EstimationConfirmPreview />

        <Button colorScheme="blue" w="full" type="submit" isDisabled={isSubmitting}>
          作成してダウンロード
        </Button>

        <EstimationConfirmItemPriceList />
      </VStack>
    </form>
  );
};
