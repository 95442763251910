import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { SalesOrdersReorderFormValuesType } from 'features/salesOrdersV2/form/reorder';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { SalesOrdersReorderStepper } from '../SalesOrdersReorderStepper';
import { SalesOrdersReorderFormPrices } from './SalesOrdersReorderFormPrices';

export const SalesOrdersReorderForm = ({
  control,
  getValues,
  register,
  errors,
}: {
  control: Control<SalesOrdersReorderFormValuesType>;
  getValues: UseFormGetValues<SalesOrdersReorderFormValuesType>;
  register: UseFormRegister<SalesOrdersReorderFormValuesType>;
  errors: Partial<FieldErrorsImpl<SalesOrdersReorderFormValuesType>>;
}) => {
  return (
    <>
      <HStack spacing={4} align="top" w="100%">
        <FormControl isInvalid={!!errors.title} isRequired>
          <FormLabel>件名</FormLabel>
          <Input type="string" {...register('title')} />
          <ErrorMessage name="title" errors={errors} />
        </FormControl>

        <FormControl isInvalid={!!errors.receivedDate} maxW="160px" isRequired>
          <FormLabel>受注日</FormLabel>
          <Input type="date" {...register('receivedDate')} />
          <ErrorMessage name="receivedDate" errors={errors} />
        </FormControl>
      </HStack>

      <SalesOrdersReorderStepper />

      <HStack spacing={6} align="flex-start" justifyContent="space-between" w="100%">
        <VStack w="65%" spacing={4}>
          <FormControl isInvalid={!!errors.detailMessage}>
            <FormLabel m={0}>備考</FormLabel>
            <Textarea mt={2} {...register('detailMessage')} rows={8} />
            <ErrorMessage name="detailMessage" errors={errors} />
          </FormControl>

          <FormControl isInvalid={!!errors.memo}>
            <FormLabel>社内メモ</FormLabel>
            <Textarea {...register('memo')} rows={8} />
            <ErrorMessage name="memo" errors={errors} />
            <Text color="gray.500" fontSize="sm" mt={1}>
              初期費用の減額や値引きを行った場合は必ず理由を記載してください
            </Text>
          </FormControl>

          <FormControl isInvalid={!!errors.internalAssignees} isRequired>
            <FormLabel>担当者</FormLabel>
            <Controller
              name="internalAssignees"
              control={control}
              render={({ field: { onChange } }) => (
                <UserMultipleSelectBox
                  onChange={onChange}
                  defaultValue={getValues('internalAssignees')}
                  menuPlacement="top"
                />
              )}
            />

            <ErrorMessage name="internalAssignees" errors={errors} />
          </FormControl>
        </VStack>

        <SalesOrdersReorderFormPrices control={control} />
      </HStack>

      <Box w="100%">
        <Button colorScheme="blue" w="full" type="submit">
          確認画面へ進む
        </Button>
      </Box>
    </>
  );
};
