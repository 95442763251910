import { Loading } from 'components/Loading';
import { OrderSendingForm } from 'features/order/components/OrderSendingForm';
import { useOrderSending } from 'features/order/hooks/useOrderSending';
import { RECIPIENT_TYPE } from 'features/order/type';
import { defaultMailBodyMessage } from 'features/order/util';
import {
  sending_OrderSendingPageQuery,
  sending_OrderSendingPageQuery$data,
} from 'gql/__generated__/sending_OrderSendingPageQuery.graphql';
import { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const orderSendingPageQuery = graphql`
  query sending_OrderSendingPageQuery ($id: ID!){
    orders(where: {id: $id}) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          details {
            id
            type
            item {
              id
              name
              unitPrice
              quantity
              tax: taxCategory {
                id
                rate
              }
            }
          }
          title
          detailMessage
          mailBodyMessage
          deliveryInfo {
            desiredDeliveryDate
            recipientsText
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
          assignees {
            recipientType
            contact {
              id
            }
          }
          estimationID
        }
      }
    }
    currentUser {
      lastName
    }
  }
`;

export const OrderSendingPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const navigate = useNavigate();

  const { orders, currentUser } = useLazyLoadQuery<sending_OrderSendingPageQuery>(
    orderSendingPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const { onSubmit, isMutationInFlight } = useOrderSending({
    orderId: id,
  });

  const order = orders?.edges?.at(0)?.node;
  if (!order) return null;

  return (
    <Suspense fallback={<Loading />}>
      <OrderSendingForm
        onClickSubmit={onSubmit}
        values={buildOrderEditFormState(order)}
        sendingData={buildOrderEditSendingFormState(currentUser, order)}
        isMutationInFlight={isMutationInFlight}
        onClickToNewForm={() => navigate(paths.order._id(id))}
      />
    </Suspense>
  );
};

type OrderNodeType = NonNullable<
  NonNullable<
    NonNullable<NonNullable<sending_OrderSendingPageQuery$data['orders']>['edges']>[number]
  >['node']
>;

type CurrentUserType = sending_OrderSendingPageQuery$data['currentUser'];

const buildOrderEditFormState = (order: OrderNodeType) => {
  const internalAssignees =
    order?.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    estimationID: order.estimationID || '',
    company: {
      id: order.company.id,
      name: order.company.name,
    },
    supplier: {
      id: order.supplier.id,
      name: order.supplier.name,
    },
    title: order.title,
    detailMessage: order.detailMessage || '',
    deliveryInfo: {
      desiredDeliveryDate: order.deliveryInfo?.desiredDeliveryDate,
      recipientsText: order.deliveryInfo?.recipientsText || '',
    },
    details: (order.details || []).map((detail) => {
      return {
        name: detail.item?.name || '',
        unitPrice: detail.item?.unitPrice,
        quantity: detail.item?.quantity,
        tax: {
          id: detail.item?.tax.id || '',
          rate: detail.item?.tax.rate || '',
        },
      };
    }),
    internalAssignees,
  };
};

const buildOrderEditSendingFormState = (currentUser: CurrentUserType, order: OrderNodeType) => {
  return {
    mailBodyMessage:
      order.mailBodyMessage || defaultMailBodyMessage({ lastName: currentUser.lastName }),
    supplierContactsTo:
      order.assignees
        ?.filter((assign) => assign.recipientType === RECIPIENT_TYPE.to)
        .map((assign) => assign.contact.id) || [],
    supplierContactsCc:
      order.assignees
        ?.filter((assign) => assign.recipientType === RECIPIENT_TYPE.cc)
        .map((assign) => assign.contact.id) || [],
  };
};
