import { HStack, VStack } from '@chakra-ui/react';
import { EstimationDetailType } from 'features/estimations/form';
import { SpecLabel } from './SpecLabel';
import { SpecValue } from './SpecValue';

export const EstimationConfirmFlexiblePackagePreview = ({
  detail,
}: { detail: EstimationDetailType }) => {
  const { flexiblePackage } = detail.spec;
  if (!flexiblePackage || !Object.values(flexiblePackage).some(Boolean)) {
    return null;
  }

  return (
    <VStack borderLeft="2px solid" borderColor="gray.200" pl={3} mt={2} ml={4} spacing={1}>
      {detail.spec.flexiblePackage?.size && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>サイズ</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage?.size}</SpecValue>
        </HStack>
      )}
      {detail.spec.flexiblePackage?.type && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>形状</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage?.type}</SpecValue>
        </HStack>
      )}
      {detail.spec.flexiblePackage?.material && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>構成</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage.material}</SpecValue>
        </HStack>
      )}
      {detail.spec.flexiblePackage?.printingColor && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>印刷</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage.printingColor}</SpecValue>
        </HStack>
      )}
      {detail.spec.flexiblePackage?.processing && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>加工</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage.processing}</SpecValue>
        </HStack>
      )}
      {detail.spec.flexiblePackage?.other && (
        <HStack spacing={1} alignItems="flex-start" w="100%">
          <SpecLabel>その他</SpecLabel>
          <SpecValue>{detail.spec.flexiblePackage.other}</SpecValue>
        </HStack>
      )}
    </VStack>
  );
};
