import { Box } from '@chakra-ui/react';
import { PageHeading } from 'components/PageHeading';
import { SalesOrdersSearchQueryProvider } from 'features/salesOrdersV2/components/provider/SearchQueryProvider';
import { Outlet } from 'react-router-dom';

export const SalesOrdersPageLayout = () => (
  <SalesOrdersSearchQueryProvider>
    <>
      <Box mb={6}>
        <PageHeading label="発注請書【開発中】" />
      </Box>
      <Outlet />
    </>
  </SalesOrdersSearchQueryProvider>
);
