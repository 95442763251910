import {} from '@chakra-ui/react';
import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { EstimationDetailType } from 'features/estimations/form';
import { EstimationConfirmCardboardPreview } from './EstimationConfirmCardboardPreview';
import { EstimationConfirmCostPreview } from './EstimationConfirmCostPreview';
import { EstimationConfirmFlexiblePackagePreview } from './EstimationConfirmFlexiblePackagePreview';
import { EstimationConfirmGiftBoxPreview } from './EstimationConfirmGiftBoxPreview';
import { EstimationConfirmOtherPreview } from './EstimationConfirmOtherPreview';
import { EstimationConfirmPaperBagPreview } from './EstimationConfirmPaperBagPreview';

export const EstimationConfirmSpecPreview = ({ detail }: { detail: EstimationDetailType }) => {
  switch (detail.category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return <EstimationConfirmCardboardPreview detail={detail} />;
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return <EstimationConfirmFlexiblePackagePreview detail={detail} />;
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return <EstimationConfirmGiftBoxPreview detail={detail} />;
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return <EstimationConfirmPaperBagPreview detail={detail} />;
    case DEMAND_ITEM_CATEGORY.Other:
      return <EstimationConfirmOtherPreview detail={detail} />;
    case 'cost':
      return <EstimationConfirmCostPreview detail={detail} />;
    default:
      return <EstimationConfirmOtherPreview detail={detail} />;
  }
};
