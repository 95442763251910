import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { isDocumentDisplayStatusShown } from 'features/estimations/models';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalGrossProfitAmount,
  calculateTotalGrossProfitMargin,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const EstimationFormPriceSummary = () => {
  const form = useFormContext<EstimationFormType>();

  const showAmountSummary = useWatch({ name: 'showAmountSummary', control: form.control });

  const details = useWatch({ name: 'details', control: form.control });
  const showDetails = details.filter((detail) =>
    isDocumentDisplayStatusShown(detail.documentDisplayStatus),
  );
  const detailsForCalculation = showDetails.map((detail) => ({
    quantity: detail.quantity || 0,
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  return (
    <Flex direction="column" w="240px" gap={2}>
      <Flex justify="space-between" opacity={showAmountSummary ? 1 : 0.3}>
        <Box w="140px" fontSize="sm">
          小計
        </Box>
        <Box fontSize="sm">
          <>{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</>
        </Box>
      </Flex>
      <Flex
        justify="space-between"
        color={showAmountSummary ? 'gray.500' : 'inherit'}
        opacity={showAmountSummary ? 1 : 0.3}
      >
        <Box
          w="140px"
          fontSize="xs"
          borderColor="gray.200"
          borderStyle="solid"
          borderLeftWidth="2px"
          pl={2}
          height="1.5rem"
        >
          粗利
        </Box>
        <Box fontSize="xs">
          <>
            {`${calculateTotalGrossProfitAmount(
              detailsForCalculation,
            ).toLocaleString()} (${calculateTotalGrossProfitMargin(detailsForCalculation)}%)`}
          </>
        </Box>
      </Flex>
      <Flex justify="space-between" opacity={showAmountSummary ? 1 : 0.3}>
        <Box whiteSpace="nowrap" w="140px" fontSize="sm">
          消費税
        </Box>
        <Box fontSize="sm">
          <>{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</>
        </Box>
      </Flex>
      <Flex justify="space-between" opacity={showAmountSummary ? 1 : 0.3}>
        <Box w="140px" fontWeight="bold">
          合計金額
        </Box>
        <Box fontWeight="bold">
          <>{calculateTotalAmount(detailsForCalculation).toLocaleString()}</>
        </Box>
      </Flex>

      <Checkbox {...form.register('showAmountSummary')} spacing={1}>
        合計金額を見積書に表示
      </Checkbox>
    </Flex>
  );
};
