import { Badge, Box, Button, HStack, Text, theme } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useState } from 'react';

/* ========== container styles ========== */
const inactiveStepStyle = css`
  background-color: transparent;
  outline: 1px solid ${theme.colors.gray[200]};
`;

const activeStepStyle = css`
  background-color: ${theme.colors.gray[50]};
`;

const finishedStepStyle = css`
  background-color: ${theme.colors.gray[50]};
  outline: 1px solid ${theme.colors.blue[500]};
`;

/* ========== divider styles ========== */
const unfinishedStepDividerStyle = css`
  content: "";
  position: absolute;
  top: 50%;
  right: -16px;
  width: 16px;
  height: 1px;
  background-color: ${theme.colors.gray[200]};
`;

const finishedStepDividerStyle = css`
  content: "";
  position: absolute;
  top: 50%;
  right: -16px;
  width: 16px;
  height: 1px;
  background-color: ${theme.colors.blue[500]};
`;

export const SalesOrdersReorderStepper = () => {
  const steps: {
    description: string;
    buttonLabel: {
      default: string;
      finished: string;
    };
    optional?: boolean;
  }[] = [
    {
      description: '発注を行う商品を一覧から選択しましょう',
      buttonLabel: {
        default: '商品を選択',
        finished: '最初から商品を選び直す',
      },
    },
    {
      description: '継続的に発生する費用を追加しましょう',
      buttonLabel: {
        default: '継続費用を選択',
        finished: '継続費用を選び直す',
      },
      optional: true,
    },
    {
      description: '一時的に発生する費用や割引があれば明細に追加しましょう',
      buttonLabel: {
        default: '明細行を追加',
        finished: '', // MEMO: このステップは完了になることがない
      },
      optional: true,
    },
  ];

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const isInactiveStep = (index: number) => {
    return index > activeStepIndex;
  };

  const isActiveStep = (index: number) => {
    return activeStepIndex === index;
  };

  const isFinishedStep = (index: number) => {
    return index < activeStepIndex;
  };

  const incrementStep = () => {
    if (activeStepIndex < steps.length - 1) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  const decrementStep = () => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  const calcStepWidth = (stepsCount: number) => {
    return `calc(100% / ${stepsCount})`;
  };

  const stepStyle = (index: number) => {
    if (isActiveStep(index)) {
      return activeStepStyle;
    }
    if (isFinishedStep(index)) {
      return finishedStepStyle;
    }
    return inactiveStepStyle;
  };

  const stepDividerStyle = (index: number) => {
    // 最後のステップの場合、dividerは表示しない
    if (index >= steps.length - 1) {
      return undefined;
    }
    if (isFinishedStep(index)) {
      return finishedStepDividerStyle;
    }
    return unfinishedStepDividerStyle;
  };

  const stepButtonLabel = (index: number) => {
    if (isFinishedStep(index)) {
      return steps[index].buttonLabel.finished;
    }
    return steps[index].buttonLabel.default;
  };

  return (
    <Box w="100%">
      <HStack>
        <Button onClick={decrementStep}>前へ</Button>
        <Button onClick={incrementStep}>次へ</Button>
      </HStack>
      <HStack gap={4}>
        {steps.map((step, index) => (
          <Box
            key={index}
            w={calcStepWidth(steps.length)}
            p={6}
            position="relative"
            borderRadius={8}
            boxSizing="border-box"
            css={stepStyle(index)}
            _after={stepDividerStyle(index)}
          >
            <HStack gap={1} alignItems="center">
              <Text fontWeight="bold" lineHeight={1.5}>
                STEP{index + 1}
              </Text>
              {step.optional && (
                <Badge
                  variant="outline"
                  colorScheme="gray"
                  textAlign="center"
                  rounded="sm"
                  fontWeight="bold"
                >
                  任意
                </Badge>
              )}
            </HStack>
            <Text mt={1} fontSize="sm">
              {step.description}
            </Text>
            <Button size="sm" mt={4} w="100%" colorScheme="blue" disabled={isInactiveStep(index)}>
              {stepButtonLabel(index)}
            </Button>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};
