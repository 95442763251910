import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  EstimationFormType,
  createDefaultEstimationForm,
  estimationFormSchema,
  mapResponseToEstimationForm,
} from 'features/estimations/form';
import {
  layout_EstimationCreateLayoutQuery,
  layout_EstimationCreateLayoutQuery$data,
} from 'gql/__generated__/layout_EstimationCreateLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useSearchParams } from 'react-router-dom';

const estimationCreateLayoutQuery = graphql`
  query layout_EstimationCreateLayoutQuery($responseId: ID!, $isSkip: Boolean!) {
    response: estimationResponses(where: {id: $responseId}) @skip(if: $isSkip) {
      edges {
        node {
          id
          importantNotes
          details {
            type
            item {
              id
              name
              quantity
              unitPrice
              specJSON
            }
          }
          assignee {
            supplier {
              id
              name
            }
            estimationRequest {
              id
              company {
                id
                name
              }
              internalAssignees {
                user {
                  id
                  profile {
                    lastName
                    firstName
                  }
                }
              }
            }
          }
        }
      }
    }

    tax: taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    },

    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
  }
`;

type ResponseType = NonNullable<
  NonNullable<NonNullable<layout_EstimationCreateLayoutQuery$data['response']>['edges']>[0]
>['node'];

const getDefaultValue = ({
  tax,
  currentUser,
  response,
}: {
  tax: {
    id: string;
    rate: number;
  };
  currentUser: {
    userID: string;
    firstName: string;
    lastName: string;
  };
  response?: ResponseType;
}) => {
  return response
    ? mapResponseToEstimationForm({
        demand: {
          id: response.assignee.estimationRequest.company.id,
          name: response.assignee.estimationRequest.company.name,
        },
        supplier: {
          id: response.assignee.supplier.id,
          name: response.assignee.supplier.name,
        },
        importantNotes: response.importantNotes,
        assignees:
          response.assignee.estimationRequest.internalAssignees?.map((assignee) => ({
            id: assignee.user.id,
            firstName: assignee.user.profile?.[0].firstName ?? '',
            lastName: assignee.user.profile?.[0]?.lastName ?? '',
          })) ?? [],
        details:
          response.details?.map((detail) => ({
            id: detail.item?.id ?? '',
            name: detail.item?.name ?? '',
            unitPrice: detail.item?.unitPrice ?? 0,
            quantity: Number(detail.item?.quantity) ?? 0,
            specJSON: detail.item?.specJSON ?? {},
          })) || [],
        tax,
      })
    : createDefaultEstimationForm({
        tax,
        currentUser,
      });
};

export const EstimationCreateLayout = () => {
  const [searchParams] = useSearchParams();
  const responseId = searchParams.get('responseId');

  const { tax, currentUser, response } = useLazyLoadQuery<layout_EstimationCreateLayoutQuery>(
    estimationCreateLayoutQuery,
    { responseId: responseId || '', isSkip: !responseId },
    { fetchPolicy: 'network-only' },
  );

  const sourceResponse = response?.edges?.[0]?.node;

  const form = useForm<EstimationFormType>({
    resolver: zodResolver(estimationFormSchema),
    defaultValues: getDefaultValue({
      tax: {
        id: tax?.edges?.[0]?.node?.id ?? '',
        rate: tax?.edges?.[0]?.node?.rate ?? 0,
      },
      currentUser,
      response: sourceResponse,
    }),
  });

  return (
    <VStack spacing={6} alignItems="flex-start">
      <FormProvider {...form}>
        <Outlet />
      </FormProvider>
    </VStack>
  );
};
