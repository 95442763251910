/**
 * @generated SignedSource<<fc82810895dcf832d156944f6835d62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandItemsAndFilterWrapperFragment$data = {
  readonly demandItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "DemandItemsAndFilterWrapperFragment";
};
export type DemandItemsAndFilterWrapperFragment$key = {
  readonly " $data"?: DemandItemsAndFilterWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemsAndFilterWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemsAndFilterWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "isCurrent": true
          }
        }
      ],
      "concreteType": "DemandItemConnection",
      "kind": "LinkedField",
      "name": "demandItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DemandItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Supplier",
                  "kind": "LinkedField",
                  "name": "supplier",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "demandItems(where:{\"isCurrent\":true})"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "5a243d216e5e852613d5e64008eb76c7";

export default node;
