import { InvoiceInputFormValueType } from 'features/invoice/form';
import { InvoiceInputFormFragment$data } from 'gql/__generated__/InvoiceInputFormFragment.graphql';
import { dateFormatHyphenDate } from 'utils/date';

export const buildDefaultDetailsValue = ({
  inputDetails,
  salesOrderDetails,
  tax,
}: {
  inputDetails: InvoiceInputFormValueType['details'];
  salesOrderDetails: InvoiceInputFormFragment$data['salesOrderDetails'];
  tax: { id: string; rate: number };
}): InvoiceInputFormValueType['details'] => {
  const details = (salesOrderDetails?.edges || []).map((detail) => {
    // 請求書データがすでに存在していれば請求書の明細を返す（編集時）
    if (detail?.node?.invoiceDetails.edges && detail?.node?.invoiceDetails?.edges.length > 0) {
      const invoice = detail.node.invoiceDetails.edges.at(0)?.node;
      const item = invoice?.item;
      const salesDate = invoice?.invoiceDetailsSales?.edges?.at(0)?.node?.salesDate;

      if (invoice && item) {
        return {
          salesDate: salesDate ? dateFormatHyphenDate(salesDate) : '',
          invoiceDetailId: invoice.id,
          salesOrderDetailId: detail.node.id || '',
          name: replaceNewlinesToSlash(item.name),
          quantity: item.quantity,
          unitSellingPrice: item.unitSellingPrice || 0,
          tax: {
            id: item.tax.id,
            rate: item.tax.rate,
          },
        };
      }

      return {
        salesDate: '',
        salesOrderDetailId: '',
        name: '',
        tax,
      };
    }

    const salesDate = detail?.node?.deliveryEvents.edges?.at(0)?.node?.fixedDeliveryDate;
    const item = detail?.node?.item;
    if (item) {
      return {
        salesDate: salesDate ? dateFormatHyphenDate(salesDate) : '',
        salesOrderDetailId: detail?.node?.id || '',
        name: replaceNewlinesToSlash(item.name),
        quantity: item.quantity,
        unitSellingPrice: item.unitSellingPrice,
        tax: {
          id: item.tax.id,
          rate: item.tax.rate,
        },
      };
    }

    return {
      salesDate: '',
      salesOrderDetailId: '',
      name: '',
      tax,
    };
  });

  const mergedDetails = [...inputDetails, ...details];

  /**
   * 取引日で明細行をソートする
   * - 取引日が空の明細行は末尾にする
   * - 取引日がある明細行は取引日の降順でソート(同じ日付なら請書明細のid順でソート)
   */
  const detailsWithoutSalesDate = mergedDetails.filter(
    (detail) => detail.salesDate === undefined || detail.salesDate === '',
  );

  const detailsWithSalesDate = mergedDetails
    .filter((detail) => detail.salesDate !== undefined && detail.salesDate !== '')
    .toSorted((a, b) => {
      if (!a.salesDate || !b.salesDate) {
        // ここに来ることはあり得ないが、Array.prototype.filterでは型を絞れないので仕方なくエラーにする
        throw new Error('salesDate is undefined or empty');
      }

      const aSalesDateTime = new Date(a.salesDate).getTime();
      const bSalesDateTime = new Date(b.salesDate).getTime();

      if (aSalesDateTime === bSalesDateTime) {
        return a.salesOrderDetailId?.localeCompare(b.salesOrderDetailId || '') || 0;
      }

      return aSalesDateTime - bSalesDateTime;
    });

  const sortedDetails = [...detailsWithSalesDate, ...detailsWithoutSalesDate];

  return [...sortedDetails].filter((v): v is NonNullable<typeof v> => v != null);
};

const replaceNewlinesToSlash = (input: string) => {
  // 半角スペースと全角スペースを「/」に置き換える
  return input.replace(/\r\n|\r|\n/g, '／');
};
