import {
  useDemandItemPrimaryImageThumbnailsQuery$data,
  useDemandItemPrimaryImageThumbnailsQuery as useDemandItemPrimaryImageThumbnailsQueryType,
} from 'gql/__generated__/useDemandItemPrimaryImageThumbnailsQuery.graphql';
import { DemandItemPrimaryImageThumbnailsInput } from 'gql/graphql.types';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useDemandItemPrimaryImageThumbnailsQuery = graphql`
  query useDemandItemPrimaryImageThumbnailsQuery($input: DemandItemPrimaryImageThumbnailsInput!) {
    demandItemPrimaryImageThumbnails(input: $input) {
      itemID
      url
    }
  }
`;

// 指定した商品に対して最も表示優先度が高い画像のサムネイルを取得する
export const useDemandItemPrimaryImageThumbnails = (
  input: DemandItemPrimaryImageThumbnailsInput,
) => {
  const [data, setData] =
    useState<useDemandItemPrimaryImageThumbnailsQuery$data['demandItemPrimaryImageThumbnails']>();

  const fetch = async () => {
    const res = await fetchQuery<useDemandItemPrimaryImageThumbnailsQueryType>(
      RelayEnvironment,
      useDemandItemPrimaryImageThumbnailsQuery,
      {
        input,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).toPromise();

    setData(res?.demandItemPrimaryImageThumbnails);
  };

  return { data, fetch };
};
