/**
 * @generated SignedSource<<9c831d9aff954e7527ea05854fedd200>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadDemandItemTempImageInput = {
  image: UploadFile;
};
export type UploadFile = {
  file: any;
};
export type DemandItemImageForm_useUploadDemandItemTempImageMutation$variables = {
  input: UploadDemandItemTempImageInput;
};
export type DemandItemImageForm_useUploadDemandItemTempImageMutation$data = {
  readonly uploadDemandItemTempImage: {
    readonly objectName: string;
    readonly thumbnailUrl: string;
  };
};
export type DemandItemImageForm_useUploadDemandItemTempImageMutation = {
  response: DemandItemImageForm_useUploadDemandItemTempImageMutation$data;
  variables: DemandItemImageForm_useUploadDemandItemTempImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadedDemandItemTempImage",
    "kind": "LinkedField",
    "name": "uploadDemandItemTempImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandItemImageForm_useUploadDemandItemTempImageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DemandItemImageForm_useUploadDemandItemTempImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "887c3549daf4d6b9503ad76bc74500c6",
    "id": null,
    "metadata": {},
    "name": "DemandItemImageForm_useUploadDemandItemTempImageMutation",
    "operationKind": "mutation",
    "text": "mutation DemandItemImageForm_useUploadDemandItemTempImageMutation(\n  $input: UploadDemandItemTempImageInput!\n) {\n  uploadDemandItemTempImage(input: $input) {\n    objectName\n    thumbnailUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "75bf194c8ce1ebfaae781a5bbc1edef7";

export default node;
