import { EstimationDetailDocumentDisplayStatus as EstimationDetailDocumentDisplayStatusEnum } from 'gql/graphql.types';

export type EstimationDetailDocumentDisplayStatus = EstimationDetailDocumentDisplayStatusEnum;

export const ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS = EstimationDetailDocumentDisplayStatusEnum;

export const isDocumentDisplayStatusShown = (status: string) =>
  status === ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown;

export const isDocumentDisplayStatusHidden = (status: string) =>
  status === ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Hidden;
