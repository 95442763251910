/**
 * @generated SignedSource<<53e753ba4351fef09a0d1c85bb01e618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandItemDetailInfoFragment$data = {
  readonly category: ItemCategory;
  readonly demand: {
    readonly code: string;
  };
  readonly endedAt: any | null;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly displayOrder: any;
    readonly id: string;
  }> | null;
  readonly internalMemos: ReadonlyArray<{
    readonly body: string;
  }> | null;
  readonly itemNumber: any;
  readonly name: string;
  readonly orderItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly orderDetail: {
          readonly order: {
            readonly id: string;
            readonly title: string;
          };
        };
      } | null;
    } | null> | null;
  };
  readonly prices: ReadonlyArray<{
    readonly id: string;
    readonly quantity: any;
    readonly unitPrice: number;
    readonly unitSellingPrice: number;
  }> | null;
  readonly startedAt: any | null;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly taxCategory: {
    readonly id: string;
    readonly rate: number;
  };
  readonly version: any;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemSpecInfoFragment">;
  readonly " $fragmentType": "DemandItemDetailInfoFragment";
};
export type DemandItemDetailInfoFragment$key = {
  readonly " $data"?: DemandItemDetailInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemDetailInfoFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemInternalMemo",
      "kind": "LinkedField",
      "name": "internalMemos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "demand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemPrice",
      "kind": "LinkedField",
      "name": "prices",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitSellingPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayOrder",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "CREATED_AT"
          }
        }
      ],
      "concreteType": "OrderItemConnection",
      "kind": "LinkedField",
      "name": "orderItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderDetail",
                  "kind": "LinkedField",
                  "name": "orderDetail",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Order",
                      "kind": "LinkedField",
                      "name": "order",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DemandItemSpecInfoFragment"
    }
  ],
  "type": "DemandItem",
  "abstractKey": null
};
})();

(node as any).hash = "d306a0987dc95e0f05701661fb1deff7";

export default node;
