import { useLocation } from 'react-router-dom';

export const useFilterParameter = () => {
  const location = useLocation();
  const { state } = location;

  let itemCategories: string[] = [];
  let itemSuppliers: string[] = [];
  if (state?.search) {
    const queryParams = new URLSearchParams(state.search);
    itemCategories = queryParams.get('itemCategories')?.split(',') || [];
    itemSuppliers = queryParams.get('itemSuppliers')?.split(',') || [];
  }

  return { itemCategories, itemSuppliers };
};
