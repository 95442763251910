import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { CardboardSpecForm } from './CardboardSpecForm';
import { CostSpecForm } from './CostSpecForm';
import { FlexiblePackageSpecForm } from './FlexiblePackageSpecForm';
import { GiftBoxSpecForm } from './GiftBoxSpecForm';
import { OtherSpecForm } from './OtherSpecForm';
import { PaperBagSpecForm } from './PaperBagSpecForm';

type Props = {
  index: number;
};

export const EstimationFormSpecs = ({ index }: Props) => {
  const form = useFormContext<EstimationFormType>();

  const category = useWatch({
    name: `details.${index}.category`,
    control: form.control,
  });

  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return <CardboardSpecForm index={index} />;
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return <FlexiblePackageSpecForm index={index} />;
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return <GiftBoxSpecForm index={index} />;
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return <PaperBagSpecForm index={index} />;
    case DEMAND_ITEM_CATEGORY.Other:
      return <OtherSpecForm index={index} />;
    case 'cost':
      return <CostSpecForm index={index} />;
    default:
      return <OtherSpecForm index={index} />;
  }
};
