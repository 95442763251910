import { QueryParamsContext } from 'features/salesOrdersV2/components/provider/SearchQueryProvider';
import { useContext } from 'react';

export const useQueryParams = () => {
  const context = useContext(QueryParamsContext);
  if (!context) {
    throw new Error('useQueryParams must be used within a QueryParamsProvider');
  }
  return context;
};
