/**
 * @generated SignedSource<<cafcd5871938469860550e65b2b75b5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DemandItemPrimaryImageThumbnailsInput = {
  items: ReadonlyArray<DemandItemPrimaryImageThumbnailsItemInput>;
};
export type DemandItemPrimaryImageThumbnailsItemInput = {
  id: string;
};
export type useDemandItemPrimaryImageThumbnailsQuery$variables = {
  input: DemandItemPrimaryImageThumbnailsInput;
};
export type useDemandItemPrimaryImageThumbnailsQuery$data = {
  readonly demandItemPrimaryImageThumbnails: ReadonlyArray<{
    readonly itemID: string;
    readonly url: string;
  }>;
};
export type useDemandItemPrimaryImageThumbnailsQuery = {
  response: useDemandItemPrimaryImageThumbnailsQuery$data;
  variables: useDemandItemPrimaryImageThumbnailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DemandItemPrimaryImageThumbnail",
    "kind": "LinkedField",
    "name": "demandItemPrimaryImageThumbnails",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDemandItemPrimaryImageThumbnailsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDemandItemPrimaryImageThumbnailsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7047eec6e91c7ecf86de424ab760dd18",
    "id": null,
    "metadata": {},
    "name": "useDemandItemPrimaryImageThumbnailsQuery",
    "operationKind": "query",
    "text": "query useDemandItemPrimaryImageThumbnailsQuery(\n  $input: DemandItemPrimaryImageThumbnailsInput!\n) {\n  demandItemPrimaryImageThumbnails(input: $input) {\n    itemID\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4a61be73be1dc08511f0a6978cc09ed";

export default node;
