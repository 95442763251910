import {
  BuildingOne,
  Deposit,
  FactoryBuilding,
  Fire,
  Order,
  Seal,
  SendEmail,
  TriangleRuler,
} from '@icon-park/react';
import { getEnvironment } from 'utils/environment';
import { paths } from 'utils/paths';
import { Environments } from './environment';

// 開発環境でのみ表示したいサイドナビ
const devOnlySideBarList = () => {
  if (getEnvironment() === Environments.Production) return [];

  return [
    {
      category: '開発中の機能',
      items: [
        { label: '見積書(v2)', icon: Fire, href: paths.estimations.url() },
        { label: '発注請書(v2)', icon: Fire, href: paths.salesOrders.url() },
        { label: 'デマンド(v2)', icon: Fire, href: paths.demands.url() },
      ],
    },
  ];
};

export const SideBarList = [
  {
    category: '書類一覧',
    items: [
      {
        label: '見積依頼',
        icon: SendEmail,
        href: paths.estimationRequest.url(),
      },
      {
        label: '見積書',
        icon: TriangleRuler,
        href: paths.estimation.url(),
      },
      {
        label: '発注請書',
        icon: Seal,
        href: paths.salesOrder.url(),
      },
      {
        label: '発注書',
        icon: Order,
        href: paths.order.url(),
      },
      {
        label: '請求書',
        icon: Deposit,
        href: paths.invoice.url(),
      },
    ],
  },
  {
    category: 'マスタ',
    items: [
      {
        label: 'デマンド',
        icon: BuildingOne,
        href: paths.company.url(),
      },
      {
        label: 'サプライヤー',
        icon: FactoryBuilding,
        href: paths.supplier.url(),
      },
    ],
  },
].concat(devOnlySideBarList());
