import { Button, FormControl, HStack, Input, Text } from '@chakra-ui/react';
import { useGrpcClient } from 'hooks/useGrpcClient';
import { toast } from 'lib/toast';
import { MonthlyCheckingReportService } from 'proto/service/monthly_checking_report/v1/export_connect';
import { useCallback, useEffect, useRef, useState } from 'react';
import { cdateJST } from 'utils/date';

export const SalesOrdersMonthlyCheckingReportExporter = () => {
  const { grpcClient, authorized } = useGrpcClient(MonthlyCheckingReportService);
  const [isLoading, setIsLoading] = useState(false);

  const now = cdateJST().locale('ja');
  const beginningOfPreviousMonth = now.prev('month').startOf('month');
  const dateRef = useRef<HTMLInputElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!dateRef.current) return;

    if (dateRef.current.value === '') {
      dateRef.current.value = beginningOfPreviousMonth.format('YYYY-MM');
    }
  }, [dateRef, beginningOfPreviousMonth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsLoading(true);
      e.preventDefault();
      if (!dateRef.current) return;

      const date = dateRef.current.value;

      const year = parseInt(date.split('-')[0]);
      const month = parseInt(date.split('-')[1]);
      try {
        const res = await grpcClient.export({ year, month });
        toast({
          title: '突合データをエクスポートしました',
          description: `URL: ${res.url}`,
          status: 'success',
        });
        window.open(res.url, '_blank', 'noopener, noreferrer');
      } catch (e) {
        if (!(e instanceof Error)) return;
        toast({
          title: 'エラーが発生しました',
          description: e.message,
          status: 'error',
        });
      }

      setIsLoading(false);
    },
    [dateRef, grpcClient],
  );

  if (!authorized) return null;

  return (
    <form onSubmit={handleSubmit}>
      <HStack spacing={1}>
        <FormControl width="150px" isDisabled={isLoading}>
          <Input type="month" isRequired ref={dateRef} />
        </FormControl>
        <Text>納品分を</Text>
        <Button colorScheme="blue" type="submit" isLoading={isLoading}>
          エクスポート
        </Button>
      </HStack>
    </form>
  );
};
