import {
  useDemandItemResizedImagesQuery$data,
  useDemandItemResizedImagesQuery as useDemandItemResizedImagesQueryType,
} from 'gql/__generated__/useDemandItemResizedImagesQuery.graphql';
import { DemandItemResizedImagesInput } from 'gql/graphql.types';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useDemandItemResizedImagesQuery = graphql`
  query useDemandItemResizedImagesQuery($input: DemandItemResizedImagesInput!) {
    demandItemResizedImages(input: $input) {
      imageID
      objectName
      displayOrder
      images {
        size
        url
      }
    }
  }
`;

export type DemandItemResizedImagesType =
  useDemandItemResizedImagesQuery$data['demandItemResizedImages'];

// 指定した商品に紐付くリサイズ済み画像の一覧を取得する
export const useDemandItemResizedImages = (input: DemandItemResizedImagesInput) => {
  const [data, setData] = useState<DemandItemResizedImagesType>();

  const fetch = async () => {
    const res = await fetchQuery<useDemandItemResizedImagesQueryType>(
      RelayEnvironment,
      useDemandItemResizedImagesQuery,
      {
        input,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).toPromise();

    setData(res?.demandItemResizedImages);
  };

  return { data, fetch };
};
