import { Box, Circle, Image, Skeleton } from '@chakra-ui/react';

const THUMBNAIL_IMAGE_RADIUS = 'md';

type Props = {
  thumbnailUrl: string | undefined;
  imageCount: number;
};

export const DemandItemDetailBlockThumbnail = ({ thumbnailUrl, imageCount }: Props) => {
  return (
    <Box
      position="relative"
      width="108px"
      height="108px"
      rounded={THUMBNAIL_IMAGE_RADIUS}
      bgColor="gray.50"
    >
      {thumbnailUrl ? (
        <>
          <Image
            src={thumbnailUrl}
            alt="thumbnail"
            width="100%"
            height="100%"
            objectFit="contain"
            rounded={THUMBNAIL_IMAGE_RADIUS}
          />

          {/* 枚数の表記は画像が2枚以上の場合のみ */}
          {imageCount > 1 && (
            <Circle
              position="absolute"
              bottom={2}
              right={2}
              size="24px"
              bgColor="gray.600"
              rounded="full"
              color="white"
              fontSize="sm"
              fontWeight="bold"
              alignItems="center"
              justifyContent="center"
            >
              {imageCount}
            </Circle>
          )}
        </>
      ) : (
        <Skeleton width="100%" height="100%" rounded={THUMBNAIL_IMAGE_RADIUS} />
      )}
    </Box>
  );
};
