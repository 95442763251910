import { HStack, VStack } from '@chakra-ui/react';
import { EstimationDetailType } from 'features/estimations/form';
import { SpecLabel } from './SpecLabel';
import { SpecValue } from './SpecValue';

export const EstimationConfirmCostPreview = ({ detail }: { detail: EstimationDetailType }) => {
  const { cost } = detail.spec;
  if (!cost || !Object.values(cost).some(Boolean)) {
    return null;
  }

  return (
    <VStack borderLeft="2px solid" borderColor="gray.200" pl={3} mt={2} ml={4} spacing={1}>
      <HStack spacing={1} alignItems="flex-start" w="100%">
        <SpecLabel>補足</SpecLabel>
        <SpecValue>{detail.spec.cost?.note}</SpecValue>
      </HStack>
    </VStack>
  );
};
