/**
 * @generated SignedSource<<6acf4cb44a7d404684adae1c95b45e1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EstimationResponseDetailType = "item" | "%future added value";
export type layout_EstimationCreateLayoutQuery$variables = {
  isSkip: boolean;
  responseId: string;
};
export type layout_EstimationCreateLayoutQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
  readonly response?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignee: {
          readonly estimationRequest: {
            readonly company: {
              readonly id: string;
              readonly name: string;
            };
            readonly id: string;
            readonly internalAssignees: ReadonlyArray<{
              readonly user: {
                readonly id: string;
                readonly profile: ReadonlyArray<{
                  readonly firstName: string;
                  readonly lastName: string;
                }> | null;
              };
            }> | null;
          };
          readonly supplier: {
            readonly id: string;
            readonly name: string;
          };
        };
        readonly details: ReadonlyArray<{
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly quantity: any;
            readonly specJSON: any;
            readonly unitPrice: number;
          } | null;
          readonly type: EstimationResponseDetailType;
        }> | null;
        readonly id: string;
        readonly importantNotes: string;
      } | null;
    } | null> | null;
  };
  readonly tax: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
};
export type layout_EstimationCreateLayoutQuery = {
  response: layout_EstimationCreateLayoutQuery$data;
  variables: layout_EstimationCreateLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSkip"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "responseId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "responseId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importantNotes",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "EstimationResponseItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specJSON",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v6/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v13 = {
  "alias": "tax",
  "args": null,
  "concreteType": "TaxCategoryConnection",
  "kind": "LinkedField",
  "name": "taxCategories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategory",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userID",
      "storageKey": null
    },
    (v12/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "layout_EstimationCreateLayoutQuery",
    "selections": [
      {
        "condition": "isSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "response",
            "args": (v2/*: any*/),
            "concreteType": "EstimationResponseConnection",
            "kind": "LinkedField",
            "name": "estimationResponses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationResponseDetail",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationRequestAssignee",
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationRequest",
                            "kind": "LinkedField",
                            "name": "estimationRequest",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationRequestInternalAssignee",
                                "kind": "LinkedField",
                                "name": "internalAssignees",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserProfile",
                                        "kind": "LinkedField",
                                        "name": "profile",
                                        "plural": true,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "layout_EstimationCreateLayoutQuery",
    "selections": [
      {
        "condition": "isSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "response",
            "args": (v2/*: any*/),
            "concreteType": "EstimationResponseConnection",
            "kind": "LinkedField",
            "name": "estimationResponses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EstimationResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EstimationResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationResponseDetail",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EstimationRequestAssignee",
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationRequest",
                            "kind": "LinkedField",
                            "name": "estimationRequest",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationRequestInternalAssignee",
                                "kind": "LinkedField",
                                "name": "internalAssignees",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserProfile",
                                        "kind": "LinkedField",
                                        "name": "profile",
                                        "plural": true,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v13/*: any*/),
      (v14/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d1e3c20f20ae5d9bfe9d4a375e067cf4",
    "id": null,
    "metadata": {},
    "name": "layout_EstimationCreateLayoutQuery",
    "operationKind": "query",
    "text": "query layout_EstimationCreateLayoutQuery(\n  $responseId: ID!\n  $isSkip: Boolean!\n) {\n  response: estimationResponses(where: {id: $responseId}) @skip(if: $isSkip) {\n    edges {\n      node {\n        id\n        importantNotes\n        details {\n          type\n          item {\n            id\n            name\n            quantity\n            unitPrice\n            specJSON\n          }\n          id\n        }\n        assignee {\n          supplier {\n            id\n            name\n          }\n          estimationRequest {\n            id\n            company {\n              id\n              name\n            }\n            internalAssignees {\n              user {\n                id\n                profile {\n                  lastName\n                  firstName\n                  id\n                }\n              }\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  tax: taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n  currentUser {\n    userID\n    firstName\n    lastName\n    phoneNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f74fb4be170152a305f15b378bad5f2";

export default node;
