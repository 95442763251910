/**
 * @generated SignedSource<<b4119b26ea520242c0eb94081ec75dc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
export type EstimationInputV2 = {
  companyID: string;
  detailMessage: string;
  details: ReadonlyArray<EstimationDetailInputV2>;
  estimationResponseID?: string | null;
  expirationPeriod: string;
  internalAssignees?: ReadonlyArray<EstimationInternalAssigneeInput> | null;
  showAmountSummary: boolean;
  supplierID: string;
  title: string;
};
export type EstimationDetailInputV2 = {
  cost?: EstimationDetailCostInput | null;
  item?: EstimationDetailItemInput | null;
};
export type EstimationDetailCostInput = {
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  note: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type EstimationDetailItemInput = {
  category?: ItemCategory | null;
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  orderNumber: number;
  quantity: number;
  spec?: EstimationDetailItemSpecInput | null;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type EstimationDetailItemSpecInput = {
  cardboard?: EstimationItemCardboardInput | null;
  flexiblePackage?: EstimationItemFlexiblePackageInput | null;
  giftBox?: EstimationItemGiftBoxInput | null;
  other?: EstimationItemOtherInput | null;
  paperBag?: EstimationItemPaperBagInput | null;
};
export type EstimationItemCardboardInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  thickness: string;
  type: string;
};
export type EstimationItemFlexiblePackageInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type EstimationItemGiftBoxInput = {
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type EstimationItemOtherInput = {
  specText: string;
};
export type EstimationItemPaperBagInput = {
  handle: string;
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
};
export type EstimationInternalAssigneeInput = {
  userID: string;
};
export type confirm_EstimationCreateConfirmPageMutation$variables = {
  input: EstimationInputV2;
};
export type confirm_EstimationCreateConfirmPageMutation$data = {
  readonly createEstimationV2: {
    readonly id: string;
  };
};
export type confirm_EstimationCreateConfirmPageMutation = {
  response: confirm_EstimationCreateConfirmPageMutation$data;
  variables: confirm_EstimationCreateConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Estimation",
    "kind": "LinkedField",
    "name": "createEstimationV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_EstimationCreateConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_EstimationCreateConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63b13a7f45b50c3013d52d8099461b64",
    "id": null,
    "metadata": {},
    "name": "confirm_EstimationCreateConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_EstimationCreateConfirmPageMutation(\n  $input: EstimationInputV2!\n) {\n  createEstimationV2(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8296a84ff1df5dfec5efe9d03054d525";

export default node;
