import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { EstimationFormV2 } from 'features/estimations/components/EstimationForm';
import { create_EstimationCreatePageQuery } from 'gql/__generated__/create_EstimationCreatePageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const EstimationCreatePageQuery = graphql`
  query create_EstimationCreatePageQuery {
    ...EstimationFormV2Fragment
  }
`;

export const EstimationCreatePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const responseId = searchParams.get('responseId');

  const query = useLazyLoadQuery<create_EstimationCreatePageQuery>(
    EstimationCreatePageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const handleFormConfirm = () =>
    navigate(paths.estimations.new.confirm.url({ responseId: responseId || undefined }));

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.estimations.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="見積書の作成" />
      <EstimationFormV2 queryRef={query} navigateToConfirm={handleFormConfirm} />
    </>
  );
};
