/**
 * @generated SignedSource<<f4a2ca2a5467be2ff8605b7e1cc1fb24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
export type DemandItemInput = {
  category: ItemCategory;
  demandID: string;
  endedAt?: any | null;
  images?: ReadonlyArray<DemandItemImageInput> | null;
  internalMemo?: string | null;
  name: string;
  prices: ReadonlyArray<DemandItemPriceInput>;
  spec: DemandItemSpecInput;
  startedAt?: any | null;
  supplierID: string;
  taxCategoryID: string;
};
export type DemandItemImageInput = {
  displayOrder: number;
  objectName: string;
};
export type DemandItemPriceInput = {
  quantity: number;
  unitPrice: number;
  unitSellingPrice: number;
};
export type DemandItemSpecInput = {
  cardboard?: DemandItemCardboardSpecInput | null;
  flexiblePackage?: DemandItemFlexiblePackageSpecInput | null;
  giftBox?: DemandItemGiftBoxSpecInput | null;
  other?: DemandItemOtherSpecInput | null;
  paperBag?: DemandItemPaperBagSpecInput | null;
};
export type DemandItemCardboardSpecInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  thickness: string;
  type: string;
};
export type DemandItemFlexiblePackageSpecInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type DemandItemGiftBoxSpecInput = {
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type DemandItemOtherSpecInput = {
  specText: string;
};
export type DemandItemPaperBagSpecInput = {
  handle: string;
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
};
export type confirm_DemandItemCreateConfirmPageMutation$variables = {
  input: DemandItemInput;
};
export type confirm_DemandItemCreateConfirmPageMutation$data = {
  readonly createDemandItem: {
    readonly id: string;
  };
};
export type confirm_DemandItemCreateConfirmPageMutation = {
  response: confirm_DemandItemCreateConfirmPageMutation$data;
  variables: confirm_DemandItemCreateConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DemandItem",
    "kind": "LinkedField",
    "name": "createDemandItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_DemandItemCreateConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_DemandItemCreateConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f80be8ee2dbf9c853712d0fec578a766",
    "id": null,
    "metadata": {},
    "name": "confirm_DemandItemCreateConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_DemandItemCreateConfirmPageMutation(\n  $input: DemandItemInput!\n) {\n  createDemandItem(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f63af4a6ee5fd052d6d2dba3cf4412e";

export default node;
