import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { MonthlyCheckingReportExporter } from 'features/salesOrders/components/SalesOrderMonthlyCheckingReportExporter';
import { SalesOrderTableRow } from 'features/salesOrders/components/SalesOrderTableRow';
import {
  getSalesOrderWhere,
  useSalesOrderFilter,
} from 'features/salesOrders/hooks/useSalesOrderFilter';
import { useQueryParams } from 'features/salesOrders/hooks/useSalesOrderQueryPrams';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserSelectBox } from 'features/user/components/UserSelectBox';
import { salesOrderFragment$key } from 'gql/__generated__/salesOrderFragment.graphql';
import { salesOrderPageQuery } from 'gql/__generated__/salesOrderPageQuery.graphql';
import { useEffect } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Link, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const SalesOrderPageQuery = graphql`
  query salesOrderPageQuery ($where: SalesOrderWhereInput, $demandID: ID!, $isDemandSkip: Boolean!, $supplierID: ID!, $isSupplierSkip: Boolean!) {
    ...salesOrderFragment @arguments(where: $where)
    companies(where: {id: $demandID}) @skip(if: $isDemandSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
    suppliers(where: {id: $supplierID}) @skip(if: $isSupplierSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SalesOrderFragment = graphql`
  fragment salesOrderFragment on Query
    @refetchable(queryName: "salesOrderFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "SalesOrderOrderField", defaultValue: CREATED_AT }
      where: {type: "SalesOrderWhereInput", defaultValue: null}
    )
  {
    salesOrders(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "salesOrderFragment_salesOrders") {
      edges {
        cursor
        node {
          ...SalesOrderTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export function SalesOrderPage() {
  const { updateQueryParams } = useQueryParams();
  const [searchParams] = useSearchParams();
  const demandId = searchParams.get('demand');
  const supplierId = searchParams.get('supplier');
  const assigneeId = searchParams.get('assignee');
  const transactionId = searchParams.get('transactionId');

  const query = useLazyLoadQuery<salesOrderPageQuery>(
    SalesOrderPageQuery,
    {
      ...getSalesOrderWhere({
        userId: assigneeId || '',
        demandId: demandId || '',
        transactionId: transactionId || '',
        supplierId: supplierId || '',
      }),
      demandID: demandId || '',
      isDemandSkip: !demandId,
      supplierID: supplierId || '',
      isSupplierSkip: !supplierId,
    },
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    SalesOrderFragment,
    query as salesOrderFragment$key,
  );
  const {
    onClearDemandInput,
    onClearSupplierInput,
    onChangeUser,
    onChangeDemand,
    onChangeSupplier,
    onChangeId,
    onKeyPressIdInput,
    isPending,
    transactionId: transactionIdState,
  } = useSalesOrderFilter(refetch, transactionId || '');

  const handleClickMore = () => loadNext(100);

  const demand = (query.companies?.edges || [])[0]?.node;
  const supplier = (query.suppliers?.edges || [])[0]?.node;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateQueryParams({
      demand: demandId || '',
      assignee: assigneeId || '',
      transactionId: transactionId || '',
      supplier: supplierId || '',
    });
  }, [demandId, assigneeId, transactionId, supplierId]);

  return (
    <>
      <HStack justifyContent="space-between">
        <Button colorScheme="blue" width="208px" as={Link} to={paths.salesOrder.new.url()}>
          発注請書作成
        </Button>
        <MonthlyCheckingReportExporter />
      </HStack>
      <HStack mt="1.5rem">
        <FormControl width="220px">
          <FormLabel mb={1}>ID</FormLabel>
          <ConfigurableNumberInput
            onChange={(e) => onChangeId(e.target.value)}
            value={transactionIdState}
            placeholder="ID"
            onKeyPress={onKeyPressIdInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>デマンド</FormLabel>
          <CompanyComboBox
            onChangeSelected={onChangeDemand}
            defaultSelectedItem={demand || { id: '', name: '' }}
            onClearInput={onClearDemandInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>サプライヤー</FormLabel>
          <SupplierComboBox
            onChangeSelected={onChangeSupplier}
            defaultSelectedItem={supplier || { id: '', name: '' }}
            onClearInput={onClearSupplierInput}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>担当者</FormLabel>
          <UserSelectBox
            value={assigneeId || ''}
            enableValue={true}
            onChange={(e) => onChangeUser(e.target.value)}
          />
        </FormControl>
      </HStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th minW="8rem">ID</Th>
                <Th minW="6.5rem">件名</Th>
                <Th minW="6.5rem">デマンド</Th>
                <Th minW="6.5rem">サプライヤー</Th>
                <Th minW="8.5rem">受注日</Th>
                <Th minW="8.5rem">確定納品日</Th>
                <Th minW="6rem">担当者</Th>
                <Th minW="3rem" textAlign="center">
                  見積依頼
                </Th>
                <Th minW="3rem" textAlign="center">
                  見積書
                </Th>
                <Th minW="3rem" textAlign="center">
                  発注書
                </Th>
                <Th minW="3.5rem" />
              </Tr>
            </Thead>
            <Tbody>
              {data.salesOrders.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <SalesOrderTableRow
                      key={`salesOrder-${edge?.cursor}-${i}`}
                      queryRef={edge?.node}
                    />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.salesOrders.totalCount}
              currentLength={data.salesOrders.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
