import { Box, HStack, IconButton, Image, Skeleton } from '@chakra-ui/react';
import { ZoomIn } from '@icon-park/react';
import { useDemandItemResizedImages } from 'features/demandItem/api/useDemandItemResizedImages';
import { DemandItemImageSlider } from 'features/demandItem/components/DemandItemImageSlider';
import { useDemandItemImageSlider } from 'features/demandItem/hooks/useDemandItemImageSlider';
import { DemandItemDetailInfoFragment$data } from 'gql/__generated__/DemandItemDetailInfoFragment.graphql';
import { DemandItemImageSize } from 'gql/graphql.types';
import { useEffect } from 'react';

const THUMBNAIL_IMAGE_RADIUS = '2xl';

type Props = {
  item: DemandItemDetailInfoFragment$data;
};

export const DemandItemDetailThumbnails = ({ item }: Props) => {
  const sortedImages = item.images?.toSorted((a, b) => a.displayOrder - b.displayOrder) || [];

  const { selectedItemId, imageCount, isOpenSlider, handleOpenSlider, handleCloseSlider } =
    useDemandItemImageSlider();

  // 最初にページに表示される画像
  const { data, fetch } = useDemandItemResizedImages({
    itemID: item.id || '',
    sizes: [DemandItemImageSize.Size896x896, DemandItemImageSize.Size440x440],
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    fetch();
  }, [item]);

  return (
    <>
      {isOpenSlider && (
        <DemandItemImageSlider
          itemId={selectedItemId}
          imageCount={imageCount}
          isOpen={isOpenSlider}
          onClose={handleCloseSlider}
        />
      )}

      <HStack spacing={2} wrap="wrap" width="448px">
        {sortedImages.map((image, index) =>
          index === 0 ? (
            <ImageThumbnailContainer
              key={image.id}
              url={
                data
                  ?.find((data) => data.imageID === image.id)
                  ?.images.find((image) => image.size === DemandItemImageSize.Size896x896)?.url ||
                ''
              }
              width="448px"
              height="448px"
              onClickImage={() => handleOpenSlider(item.id, item.images?.length ?? 0)}
            />
          ) : (
            <ImageThumbnailContainer
              key={image.id}
              url={
                data
                  ?.find((data) => data.imageID === image.id)
                  ?.images.find((image) => image.size === DemandItemImageSize.Size440x440)?.url ||
                ''
              }
              width="220px"
              height="220px"
              onClickImage={() => handleOpenSlider(item.id, item.images?.length ?? 0)}
            />
          ),
        )}
      </HStack>
    </>
  );
};

type ImageThumbnailContainerProps = {
  url: string;
  width: string;
  height: string;
  onClickImage: React.MouseEventHandler<HTMLImageElement>;
};

const ImageThumbnailContainer = ({
  url,
  width,
  height,
  onClickImage,
}: ImageThumbnailContainerProps) => {
  return (
    <Box
      position="relative"
      width={width}
      height={height}
      rounded={THUMBNAIL_IMAGE_RADIUS}
      bgColor="gray.50"
    >
      {url ? (
        <Image
          src={url}
          width={width}
          height={height}
          rounded={THUMBNAIL_IMAGE_RADIUS}
          cursor="pointer"
          objectFit="contain"
          onClick={onClickImage}
        />
      ) : (
        <Skeleton width="100%" height="100%" rounded={THUMBNAIL_IMAGE_RADIUS} />
      )}

      <IconButton
        aria-label="画像を拡大"
        icon={<ZoomIn size={16} />}
        rounded="full"
        size="sm"
        position="absolute"
        right={2}
        bottom={2}
        bgColor="gray.100"
        pointerEvents="none"
      />
    </Box>
  );
};
