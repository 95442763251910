import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';

import { z } from 'zod';

export type SalesOrdersReorderFormValuesType = z.infer<typeof salesOrdersReorderFormSchema>;

export const salesOrdersReorderFormDetailSchema = z.object({
  name: z.string().min(1, { message: errorMessages.REQUIRED }),
  unitPrice: unitPriceSchema(),
  unitSellingPrice: unitSellingPriceSchema,
  quantity: quantitySchema(),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  fixedDeliveryDate: z.string().optional(),
});

export const salesOrdersReorderFormSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  title: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(70, { message: errorMessages.MAX_LENGTH(70) }),
  receivedDate: z.string(),
  details: z.array(salesOrdersReorderFormDetailSchema),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
});

export const getSalesOrdersReorderInitialValues = (): SalesOrdersReorderFormValuesType => {
  return {
    demand: { id: '', name: '' },
    title: '',
    receivedDate: '',
    details: [],
    detailMessage: '',
    memo: '',
    internalAssignees: [],
  };
};
