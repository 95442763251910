import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useFilterParameter } from 'features/demandItem/parameter/useFilterParameter';
import { DemandRecurringCostForm } from 'features/demandRecurringCost/components/DemandRecurringCostForm';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { edit_RecurringCostEditPageQuery } from 'gql/__generated__/edit_RecurringCostEditPageQuery.graphql';
import { useFormContext } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const RecurringCostEditPageQuery = graphql`
  query edit_RecurringCostEditPageQuery {
    ...DemandRecurringCostFormFragment
  }
`;

export const DemandRecurringCostEditPage = () => {
  const navigate = useNavigate();
  const { demandCode, costNumber } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  if (!costNumber) {
    throw new MissingRequiredParamError('costNumber');
  }

  const { itemCategories, itemSuppliers } = useFilterParameter();

  const query = useLazyLoadQuery<edit_RecurringCostEditPageQuery>(RecurringCostEditPageQuery, {
    fetchPolicy: 'network-only',
  });

  const form = useFormContext<DemandRecurringCostFormType>();

  const handleFormConfirm = form.handleSubmit(() => {
    navigate(
      paths.demands
        ._demandCode(demandCode)
        .recurringCosts._costNumber(costNumber)
        .edit.confirm.url(),
    );
  });

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink
          to={paths.demands._demandCode(demandCode).url({
            itemCategories,
            itemSuppliers,
          })}
        >
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="継続費用の編集" />
      <DemandRecurringCostForm navigateToConfirm={handleFormConfirm} queryRef={query} />
    </>
  );
};
