/**
 * @generated SignedSource<<80e75c99a718023684030a7de8abe055>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
export type DemandItemDetailBlocksQuery$variables = {
  categories?: ReadonlyArray<ItemCategory> | null;
  demandId: string;
  supplierIds?: ReadonlyArray<string> | null;
};
export type DemandItemDetailBlocksQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlocksRefetchFragment">;
};
export type DemandItemDetailBlocksQuery = {
  response: DemandItemDetailBlocksQuery$data;
  variables: DemandItemDetailBlocksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "demandId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "supplierIds"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandItemDetailBlocksQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "categories",
            "variableName": "categories"
          },
          {
            "kind": "Variable",
            "name": "demandId",
            "variableName": "demandId"
          },
          {
            "kind": "Variable",
            "name": "supplierIds",
            "variableName": "supplierIds"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DemandItemDetailBlocksRefetchFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DemandItemDetailBlocksQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "direction": "DESC",
              "field": "UPDATED_AT"
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "categoryIn",
                "variableName": "categories"
              },
              {
                "kind": "Variable",
                "name": "demandID",
                "variableName": "demandId"
              },
              {
                "kind": "Literal",
                "name": "isCurrent",
                "value": true
              },
              {
                "kind": "Variable",
                "name": "supplierIDIn",
                "variableName": "supplierIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemPrice",
                    "kind": "LinkedField",
                    "name": "prices",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitSellingPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemImage",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayOrder",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "DESC",
                          "field": "CREATED_AT"
                        }
                      }
                    ],
                    "concreteType": "OrderItemConnection",
                    "kind": "LinkedField",
                    "name": "orderItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderDetail",
                                "kind": "LinkedField",
                                "name": "orderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Order",
                                    "kind": "LinkedField",
                                    "name": "order",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "orderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemCardboard",
                    "kind": "LinkedField",
                    "name": "cardboard",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thickness",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemFlexiblePackage",
                    "kind": "LinkedField",
                    "name": "flexiblePackage",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemGiftBox",
                    "kind": "LinkedField",
                    "name": "giftBox",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemPaperBag",
                    "kind": "LinkedField",
                    "name": "paperBag",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandItemOther",
                    "kind": "LinkedField",
                    "name": "other",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specText",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79c9632b1a1a29e3ca17256b9a3bbb35",
    "id": null,
    "metadata": {},
    "name": "DemandItemDetailBlocksQuery",
    "operationKind": "query",
    "text": "query DemandItemDetailBlocksQuery(\n  $demandId: ID!\n  $categories: [ItemCategory!]\n  $supplierIds: [ID!]\n) {\n  ...DemandItemDetailBlocksRefetchFragment_2sYkpP\n}\n\nfragment DemandItemDetailBlocksHeaderFragment on DemandItem {\n  name\n  category\n  cardboard {\n    __typename\n    size\n    type\n    material\n    thickness\n    printingColor\n    processing\n    other\n    id\n  }\n  flexiblePackage {\n    __typename\n    size\n    type\n    material\n    printingColor\n    processing\n    other\n    id\n  }\n  giftBox {\n    __typename\n    size\n    type\n    paperType\n    printingColor\n    processing\n    other\n    id\n  }\n  paperBag {\n    __typename\n    size\n    paperType\n    printingColor\n    processing\n    handle\n    other\n    id\n  }\n  other {\n    __typename\n    specText\n    id\n  }\n}\n\nfragment DemandItemDetailBlocksRefetchFragment_2sYkpP on Query {\n  demandItems(where: {isCurrent: true, demandID: $demandId, categoryIn: $categories, supplierIDIn: $supplierIds}, orderBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      node {\n        id\n        name\n        category\n        supplier {\n          name\n          id\n        }\n        demand {\n          id\n          code\n        }\n        prices {\n          id\n          quantity\n          unitPrice\n          unitSellingPrice\n        }\n        images {\n          id\n          displayOrder\n        }\n        itemNumber\n        version\n        orderItems(orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              orderDetail {\n                order {\n                  id\n                  title\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n        ...DemandItemDetailBlocksHeaderFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df07e2ef2b90f6e374a74e09defe36cc";

export default node;
